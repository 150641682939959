import { supervisorAPI } from "../../api's/index";
import axios from "axios";
import { getHeaders, commonApiCall } from "../../utils/index";
var qs = require("qs");

export const postSupervisorData = async (data) => {
  const response = await commonApiCall({
    method: "post",
    url: supervisorAPI,
    data: data,
  });
  return response;
};

export const getSupervisorData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: supervisorAPI,
    params: params,
  });
  return response;
};

export const editSupervisorData = async (data, id) => {
  const response = await commonApiCall({
    method: "put",
    url: supervisorAPI,
    data: data,
  });
  return response;
};

export const deleteSupervisorData = async (data) => {
  const response = await commonApiCall({
    method: "delete",
    url: supervisorAPI,
    data: data,
  });
  return response;
};
