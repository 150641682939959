import React, { useEffect, useState } from "react";
import AddFormModal from "./addFormModal";
import { Backdrop, CircularProgress } from "@mui/material";

const CopyFormModel = ({
    openCopyModal,
    data,
    languages,
    handleClose = () => { },
    setFormSnackBarType = () => { },
    setFormSnackBarMessage = () => { },
    setReload = () => { },
    setOpenSuccessBox = () => { },
    setSuccessMessage = () => { },
    setOpenSuccessBoxType = () => { }
}) => {
    const [copiedData, setCopiedData] = useState({ loading: true, data: {} })
    useEffect(() => {
        setCopiedData({ loading: true, data: {} })
        const dataToBeCopied = {
            form: { ...data?.form, name: "Copy of " + data?.form?.name, formHeading: "Add a copy of form " + data?.form?.name},
            project: data?.project,
            icon: data?.icon,
            questions: []
        };
        try {
            if (data?.questions && Array.isArray(data.questions)) {
                data.questions.forEach((question, index) => {
                    const freshQuestion = {
                        response_type: (question?.response_type?.charAt(0).toUpperCase() + question?.response_type?.slice(1)) || "",
                        questions: question?.english || "",
                        question_type: question?.model_metadata?.question_type || undefined,
                        Units: question?.model_metadata?.units?.english?.join(',') || "",
                        possible_responses: ['radio', 'dropdown'].includes(question?.response_type)
                            ? question?.responses?.english?.join(',')
                            : ['text', 'audio'].includes(question?.response_type) && question?.model_metadata?.question_type === "MCQ"
                            ? question?.model_metadata?.values?.english?.map(item => item?.master?.trim()).join(',')
                            : undefined,
                    }
                    if (['text', 'audio'].includes(question?.response_type) && question?.model_metadata?.question_type === "MCQ") {
                        freshQuestion['dict'] = question?.model_metadata?.values?.english?.reduce((acc, item) => {
                            acc[item.master] = item.slave;
                            return acc;
                        }, {})
                    }
                    dataToBeCopied.questions.push(freshQuestion)
                });
                setCopiedData({ loading: false, data: dataToBeCopied })
            } else {
                setCopiedData({ loading: false, data: {} })
            }
        } catch (error) {
            console.error("error while formating copied data")
            setCopiedData({ loading: false, data: {} })
        }
    }, [])
    return <>
        {copiedData.loading ? <>
                <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={copiedData.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
            </>
        :
        <AddFormModal 
            isCopy={true}
            dataToBeCopied={copiedData.data}
            openAddModal={openCopyModal}
            handleClose={handleClose}
            setFormSnackBarMessage={setFormSnackBarMessage}
            setFormSnackBarType={setFormSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            languages={languages}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
        />
        }
    </>
}

export default CopyFormModel;