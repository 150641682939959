import { BrowserRouter as Router, Route, Link, Routes,} from "react-router-dom";
import React from "react";
import Login from "../features/Authentication/NewLogin";
import PrivateRoute from "./PrivateRoute";
import Location from "../features/location/locationPage";
import SuperVisorAccess from "../features/supervisorAccess/supervisorAccessPage";
import Dashboard from "../features/dashboard/DashboardPage";
import AppUsers from "../features/appUsers/AppUsersPage";
import RolesAndPermissions from "../features/rolesAndPermissions/rolesAndPermissionsPage";
import ForgotPassword from "../features/Authentication/ForgotPassword";
import PageNotFound from "../components/reusableComponents/PageNotFound";
import ReviewImages from "../features/reviewImages/reviewImagesPage";
import Projects from "../features/projects/projectsPage";
import FormManagement from "../features/formManagement/fromManagementPage";
import Partner from "../features/partner/partnerPage";
import SubmittedForms from "../features/submittedForm/SubmittedFormPage";
const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute path = "/"><Login /></PrivateRoute>} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/page_not_found" element={<PageNotFound />} />
          <Route
            path="/location"
            element={
              <PrivateRoute>
                <Location />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/supervisor_access/:type"
            element={
              <PrivateRoute>
                <SuperVisorAccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/review_images"
            element={
              <PrivateRoute>
                <ReviewImages />
              </PrivateRoute>
            }
          />
          <Route
            path="/partner"
            element={
              <PrivateRoute>
                <Partner />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects"
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route
            path="/form_management"
            element={
              <PrivateRoute>
                <FormManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/app_users/:type"
            element={
              <PrivateRoute>
                <AppUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/roles_permission"
            element={
              <PrivateRoute>
                <RolesAndPermissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/submitted_forms"
            element={
              <PrivateRoute>
                <SubmittedForms />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
  );
};
export default App;
