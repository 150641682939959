import React, { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { Checkbox,Button, ListItemIcon } from '@mui/material';
import { borderRadius } from '@mui/system';

const MenuProps = {
  PaperProps: {
    style: {
      width: "120px",
      height:"325px",
      border: "1.5px solid #74C69D",
      boxShadow: "8px 8px 24px rgba(16, 65, 48, 0.12)",
      borderRadius:"16px",
      marginTop:"5px"

    },
  },
};

const ROLES = {
  partner_head: "Partner Head",
  state_head: "State Head",
  district_head: "District Head",
  taluka_head: "Taluka Head",
  government: "Government"
}

const options = [
  'create',
  'view',
  'edit',
  'delete'
];

export default function MultipleSelectCheckmarks(
  permission, 
  handleEditPermission, 
  index, 
  role, 
  module, 
  setOpenRolesConfirmationBox, 
  setPermissionViaAlert, 
  discardAlert, 
  setDiscardAlert) {
  const [openMultiSelect, setOpenMultiSelect] = React.useState(false);
  const [givenPermission, setGivenPermission] = React.useState(permission[index][role] ? permission[index][role] : []);
  const isAllSelected =
    options.length > 0 && givenPermission.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setGivenPermission(givenPermission.length === options.length ? [] : options);
      return;
    }
    setGivenPermission(value);
  };
  const handleSavdPermission = () => {
    setOpenMultiSelect(false)
    handleEditPermission({
      module: module,
      role: role,
      permission: givenPermission
    })
  }
  const handleCancelPermission = () => {
    if (givenPermission === permission[index][role]) {
      setOpenMultiSelect(false)
      return
    }
    setOpenMultiSelect(false)
    setPermissionViaAlert({
      module: module,
      role: role,
      permission: givenPermission
    })
    setOpenRolesConfirmationBox(true)
  }
  useEffect(() => {
    if (discardAlert) {
      setGivenPermission(permission[index][role] ? permission[index][role] : []);
    }
    setDiscardAlert(false)
  }, [discardAlert])

  return (
    <FormControl sx={{ m: 1, width: '90%', borderRadius: '30px', borderColor: "#CAEEDC", textAlign: "center" }}>
      <Select
        open={openMultiSelect}
        onOpen={() => setOpenMultiSelect(true)}
        onClose={() => handleCancelPermission()}
        multiple
        displayEmpty={true}
        value={givenPermission}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        disabled={module==="Roles And Permission Module" && (role === "partner_head" || role === "state_head" || role==="district_head" || role==="taluka_head")}
        // renderValue={(selected) => selected.length === 0 ? "None" : selected.join(' & ')}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "None";
          } else if (selected.length === 4) {
            return "All" 
          }
            else if (selected.length <= 2) {
            return selected.join(' , ');
          } else {
            const visibleOptions = selected.slice(0, 2).join(', ');
            const hiddenOptionsCount = selected.length - 2;
            return `${visibleOptions} ...`;
          }
        }}
        MenuProps={MenuProps}
        sx={{ borderRadius: '30px'}}
      >
        
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox color="success" checked={givenPermission.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
        <MenuItem
          value="all"
          sx = {{ borderColor: "#CAEEDC"}}
        >
          <ListItemIcon>
            <Checkbox
              color="success"
              checked={isAllSelected}
              indeterminate={
                givenPermission.length > 0 && givenPermission.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="all"
          />
        </MenuItem>
          <Button onClick={() => handleSavdPermission()} fullWidth variant="outlined" sx={{ color: '#FFFFFF', backgroundColor: '#F0F8F1', border : "1px solid #2D6A4F", borderRadius : "20.5px", background: "#2D6A4F", width:"100%"}} style={{ backgroundColor: '#2D6A4F' }}>Save</Button>
      </Select>   
    </FormControl>
  );
}
