import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
// import { getPartnerNames } from "../../features/partner/partnerSlice";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
import { basicTheme } from "../../themes/customTheme";
import { updateArray } from "../../utils";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getPartnerData } from "../../features/partner/partnerAPIs";
import { getStatesData,getDistrictData,getTalukaData, } from "../../features/location/locationAPIs";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 800,
  borderRadius: 100,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: 10,
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderRadius: 10,
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "1.5",
    alignItems: "right",
    "&:hover": {
      color: "#081C15",
    },
    cursor: "pointer",
  },
}));

export default function AppUserFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  clearFilter = false,
  setClearFilter = () => {},
  selectedPartnersOptions = [],
  setSelectedPartnersOptions = () => {},
  selectedStatesOptions = [],
  setSelectedStatesOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => {},
  filters = [],
  setFilters = () => {},
  userType = "",
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getStatesResponse, setGetStateResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [partnerNamesData, setPartnerNamesData] = useState([{
    id: "",
    name: "",
  }]);
  const [getDistrictResponse, setGetDistrictResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [getTalukaResponse, setGetTalukaResponse] = useState([{
    id: "",
    name: "",
  },]);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        // Set selected partners if user is logged in and has a partner
        if (loggedInUser.details.partner !== null) {
          setSelectedPartnersOptions([loggedInUser.details.partner]);
        }
  
        // Fetch states data if not already loaded
        if (getStatesResponse[0].id === "") {
          const response = await getStatesData({});
          if (response?.data?.data) {
            setGetStateResponse(response.data.data);
          }
        }
  
        // Fetch partner data if not already loaded and user is admin
        if (partnerNamesData[0].id === "" && loggedInUser.role === "Admin") {
          const response = await getPartnerData({ limit: 100000, offset: 0 });
          const partnerNamesList = response?.data?.data?.map((item) => ({
            id: item.id,
            name: item.name,
          })) || [];
          setPartnerNamesData(partnerNamesList);
        }
      } catch (error) {
        // Handle errors
      } finally {
        // Set loading to false after data fetching is done
        setLoading(false);
      }
    };
  
    // Fetch data
    fetchData();
  }, []);
  

  useEffect(() => {
    if (clearFilter) {
      setSelectedStatesOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setDistrictList([]);
      setTalukaList([]);
      setPartnerNamesData([{
        id: "",
        name: "",
      },])
      var tempFilter = [{type:"state",value:[]},{type:"district",value:[]},{type:"taluka",value:[]},{type:"user_type",value:[userType]}]
      if(loggedInUser.details.partner===null)
      {
        tempFilter.push({type:"program_id",value:[]})
        setSelectedPartnersOptions([]);
      }
      else{
        tempFilter.push({type:"program_id",value:[loggedInUser.details.partner.id]})
      }
      setFilters(tempFilter)
    }
  }, [clearFilter]);

  useEffect(() => {
    if (selectedStatesOptions.length !== 0) {
      var location_ids = [];
      selectedStatesOptions.forEach((stateObj) => {
        location_ids.push(stateObj.id);
      });
      const fetchData = async () => {
        try {
          const response = await getDistrictData({
            type: "state",
            location_ids: location_ids,
          })
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetDistrictResponse(response.data.data);
          } else {
            // setShowSnackBar(true);
            // setSnackBarType("error");
            // setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          // setShowSnackBar(true);
          // setSnackBarType("error");
          // setSnackBarMessage("Not able to fetch districts, Please check server");
        }
      };
      fetchData();
    } else {
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
    }
  }, [selectedStatesOptions]);

  useEffect(async () => {
    if (selectedDistrictOptions.length !== 0) {
      var location_ids = [];
      selectedDistrictOptions.forEach((districtObj) => {
        location_ids.push(districtObj.id);
      });
      try {
        const response = await getTalukaData({
          type: "district",
          location_ids: location_ids,
        })
        if (response != null && response != undefined &&
          response.data !== null && response.data !== undefined &&
          response.data.data !== null && response.data.data !== undefined
        ) {
          setGetTalukaResponse(response.data.data);
        } else {
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setSelectedTalukaOptions([]);
    }
  }, [selectedDistrictOptions]);

  useEffect(() => {
    if (selectedDistrictOptions.length > 0 && getDistrictResponse[0].id !== "") {
      var newSelectedDistrict = [...selectedDistrictOptions];
      newSelectedDistrict.forEach((newDistrictObj) => {
        var districtPresent = getDistrictResponse.find(
          (obj) => obj.id === newDistrictObj.id
        );
        if (!districtPresent) {
          setSelectedDistrictOptions((districtsObj) =>
            districtsObj.filter((district) => district.id !== newDistrictObj.id)
          );
        }
      });
    }
    setDistrictList(getDistrictResponse);
  }, [getDistrictResponse]);

  useEffect(() => {
    if (selectedTalukaOptions.length > 0 && getTalukaResponse[0].id !== "") {
      var newSelectedTaluka = [...selectedTalukaOptions];
      newSelectedTaluka.forEach((newTalukaObj) => {
        var talukaPresent = getTalukaResponse.find(
          (obj) => obj.id === newTalukaObj.id
        );
        if (!talukaPresent) {
          setSelectedTalukaOptions((talukaObj) =>
            talukaObj.filter((taluka) => taluka.id !== newTalukaObj.id)
          );
        }
      });
    }
    setTalukaList(getTalukaResponse);
  }, [getTalukaResponse]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "20px",
        },
      }}
    >
      <ThemeProvider theme={basicTheme}>
        <StyledGrid container>
          <Grid item xs={11}>
            <Typography sx={{ fontSixe: "20px", color: "#999DA4" }}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ClearIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              State
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={getStatesResponse}
              selectedOptions={selectedStatesOptions}
              setSelectedOptions={setSelectedStatesOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color:selectedStatesOptions.length===0 ? "#ACADAD": "#444444", margin: 1 }}>
              District
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={districtList}
              selectedOptions={selectedDistrictOptions}
              setSelectedOptions={setSelectedDistrictOptions}
              disable={selectedStatesOptions.length===0}
            />
          </Grid>
          {userType !== "ew" && (
            <React.Fragment>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ fontSixe: "18px", color:selectedDistrictOptions.length===0 ? "#ACADAD": "#444444", margin: 1 }}
                >
                  Taluka
                </Typography>
                <AutoCompleteMultiDropdown
                  width={230}
                  menuList={talukaList}
                  selectedOptions={selectedTalukaOptions}
                  setSelectedOptions={setSelectedTalukaOptions}
                  disable={selectedDistrictOptions.length===0}
                />
              </Grid>
            </React.Fragment>
          )}

          {loggedInUser.role !=="Admin" && <Grid item xs={12} md={4}></Grid>}
          {loggedInUser.role==="Admin" && 
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Partner
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={partnerNamesData}
              selectedOptions={partnerNamesData[0].id!==""?selectedPartnersOptions:[]}
              setSelectedOptions={setSelectedPartnersOptions}
              disable = {loggedInUser.details.partner!==null}
            />
          </Grid>
}
          {userType !== "ew" && <Grid item md={8}></Grid>}
          <Grid item xs={3}>
            <ClearBtn
              variant="contained"
              name="Clear Filter"
              onClick={() => setClearFilter(true)}
            >
              Clear All
            </ClearBtn>
          </Grid>

          <Grid item xs={3}>
            <ApplyBtn
              variant="contained"
              name="Apply Filter"
              onClick={() => setApplyFilter(true)}
            >
              Apply
            </ApplyBtn>
          </Grid>
          {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: 'absolute',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        </StyledGrid>
      </ThemeProvider>
    </Popover>
  );
}
