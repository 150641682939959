import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import _without from "lodash/without";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";
export default function LocationDropDown({
  role = "",
  menuList = [],
  selectedStateOptions = [],
  setSelectedStateOptions = () => {},
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => {},
}) {

  useEffect(() => {
    const handleOptionsUpdate = (options, setSelectedOptions) => {
      const containsAllInMenuList = menuList.some((obj) => obj.id === "all");
      const hasAllValues = menuList
        .filter((item) => item.id !== "all")
        .every((item) => options.some((obj) => obj.id === item.id));
  
      if (containsAllInMenuList && hasAllValues) {
        setSelectedOptions((prevOptions) => [{ id: "all", name: "All" }, ...prevOptions]);
      }
    };
  
    if (role === "District Head" && menuList.length > 0) {
      handleOptionsUpdate(selectedDistrictOptions, setSelectedDistrictOptions);
    } else if (role === "Taluka Head" && menuList.length > 0) {
      handleOptionsUpdate(selectedTalukaOptions, setSelectedTalukaOptions);
    }
  }, [role, menuList]);

  const handleChangeLocation = (newValue, index, type, menuList) => {
    const setSelectedOptions = (selectedOptions, setSelectedFunction) => {
      const containsAllInOld = selectedOptions.some((obj) => obj.name === "All");
      const containsAll = newValue.some((obj) => obj.name === "All");
  
      if (containsAll) {
        setSelectedFunction(menuList);
      } else if (!containsAll && containsAllInOld) {
        setSelectedFunction([]);
      } else {
        setSelectedFunction(newValue);
      }
    };
  
    if (role === "District Head") {
      setSelectedOptions(selectedDistrictOptions, setSelectedDistrictOptions);
    } else if (role === "Taluka Head") {
      setSelectedOptions(selectedTalukaOptions, setSelectedTalukaOptions);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: role !== "State Head" && "#ACADAD",
            }}
          >
            State
          </Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedStateOptions}
            setSelectedOptions={setSelectedStateOptions}
            disable={role !== "State Head"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: role !== "District Head" && "#ACADAD",
            }}
          >
            District
          </Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedDistrictOptions}
            // setSelectedOptions={setSelectedDistrictOptions}
            disable={role !== "District Head"}
            onHighlightChange={handleChangeLocation}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: role !== "Taluka Head" && "#ACADAD",
            }}
          >
            Taluka
          </Typography>
          <AutoCompleteMultiDropdown
            width="auto"
            menuList={menuList}
            selectedOptions={selectedTalukaOptions}
            // setSelectedOptions={setSelectedTalukaOptions}
            disable={role !== "Taluka Head"}
            onHighlightChange={handleChangeLocation}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
