import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  FormHelperText
} from "@mui/material";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom", // Open the menu from the bottom
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getcontentanchorel: null, // Ensure the menu doesn't cover the input part
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "1rem"
    },
  },
};

const CustomSelect = styled(Select)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});


export default function BasicDropDown({
  text = "",
  value = "",
  handleChange = () => { },
  menuList = [],
  error = false,
  required = false,
  disable = false,
  type = null,
  errorText="",
}) {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      sx={{
        marginBottom: 1,
        borderRadius: "1rem",
        width: "90%",
        backgroundColor: "#FFFFFF",
      }}
      error={error}
    >
      <InputLabel>{text}</InputLabel>
      <CustomSelect
        value={value}
        label={text}
        onChange={handleChange}
        sx={{
          borderRadius: "1rem", // Change the border radius here
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem", // Change the border radius of the outline
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem", // Change the border radius on hover
          },
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        placeholder={text}
        disabled={
          (menuList[0] !== undefined &&
            menuList[0].id !== undefined &&
            menuList[0].id === "") ||
          menuList.length === 0 ||
          disable
        }
      >
        {menuList.map((menu) => {
          return menu.id ? (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          ) : (
            <MenuItem key={menu} value={menu}>
              {menu}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {errorText!=="" &&
      <FormHelperText>
      {errorText}
      </FormHelperText>}
    </FormControl>
  );
}
