import { submittedFormsAPI,getCropsAPI,exportSubmittedFarmsAPI } from "../../api's/index";
import axios from "axios";
import {getHeaders,commonApiCall} from "../../utils/index"
var qs = require("qs");

export const getSubmittedFormsData = async (params) => {
    const response = await commonApiCall({
        method: 'get',
        url: submittedFormsAPI,
        params: params
    });
    return response
}

export const getCropsData = async (params) => {
    const response = await commonApiCall({
        method: 'get',
        url: getCropsAPI,
        params: params
    });
    return response
}

export const getExportSubmittedFormsData = async (params) => {
    const response = await commonApiCall({
        method: 'get',
        url: exportSubmittedFarmsAPI,
        params: params
    });
    return response
}