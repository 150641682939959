import React, { useEffect, useState } from "react";
import { Checkbox, TextField, Box, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import _without from "lodash/without";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { capitaliseFirstLetter } from "../../utils";

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#FFFFFF",
    color:"#FF0000",
  },
});

export default function AutoCompleteMultiDropdown({
  width = 250,
  menuList = [],
  selectedOptions = [],
  setSelectedOptions = () => {},
  disable = false,
  label = "",
  onHighlightChange = () => {},
  required = false,
  onOpen = () => {},
  alreadySelectedItems = [],
  error = false,
  errorText="",
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="success" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const isOptionDisabled = (option) => {
    return alreadySelectedItems.some(
      (selectedItem) => selectedItem.id === option.id
    );
  };
  const handleChange = (event, value) => {
    setSelectedOptions(value);
    onHighlightChange(value);
    var newSelected = [];
    value.forEach((option) => {
      newSelected.push(option.id);
    });
  };

  return (
    <CustomAutocomplete
      onChange={(e, value) => {
        handleChange(e, value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedOptions}
      onOpen={onOpen}
      multiple
      options={menuList}
      disabled={disable}
      disableCloseOnSelect
      disableClearable={alreadySelectedItems.length!==0}
      getOptionLabel={(option) => capitaliseFirstLetter(option.name)}
      getOptionDisabled={(option) => isOptionDisabled(option)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={capitaliseFirstLetter(option.name)}
            {...getTagProps({ index })}
            disabled={alreadySelectedItems.indexOf(option) !== -1}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <React.Fragment key={option.id}>
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {capitaliseFirstLetter(option.name)}
          </li>
          <Divider />
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} helperText={errorText}
        sx={{
          "& label": {
            color: (theme) => (error ? theme.palette.error.main : undefined),
            "&.Mui-focused": {
              color: (theme) => (error ? theme.palette.error.main : undefined),
            },},
          "& .MuiFormHelperText-root": {
            color: (theme) => (error ? theme.palette.error.main : undefined),
          },
        }} />
      )}
      sx={{
        ".MuiOutlinedInput-root": {
          marginBottom: 1,
          borderRadius: "1rem",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem", // Change the border radius of the outline
            borderColor: (theme) => (error ? theme.palette.error.main : ""),
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem", // Change the border radius on hover
          },
          backgroundColor: "#FFFFFF",
        },
        width: "90%",
      }}
    ></CustomAutocomplete>
  );
}
