import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getPartnerData } from "../../features/partner/partnerAPIs";
import {
  updateArray,
  getAllDistrictList,
  getAllTalukaList,
  getAllStateList,
} from "../../utils";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 700,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});
const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "1.8",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

export default function SupervisorFilterPopOver({
  handleClose = () => {},
  setApplyFilter = () => {},
  openFilterPopOver,
  filters = [],
  setFilters = () => {},
  selectedPartners = [],
  setSelectedPartners = () => {},
  clearFilter = false,
  setClearFilter = () => {},
  role = "",
  selectedLocationOptions = [],
  setSelectedLocationOptions = () => {},
  setSupervisorSnackBarType= () => {},
  setSupervisorSnackBarMessage= () => {},
  setSupervisorShowSnackBar= () => {},
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerDataResponse, setPartnerDataResponse] = useState([]);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  useEffect(async() => {
    if(loggedInUser.details.partner!==null)
    setSelectedPartners([loggedInUser.details.partner])
    try {
      if(loggedInUser.role==="Admin") {
      const response = await getPartnerData({fields: ["name", "id"]})
      setPartnerDataResponse(response.data["data"]);
      setLoading(false);
      }
    } catch {
      setSupervisorSnackBarType("error");
      setSupervisorShowSnackBar(true);
      setSupervisorSnackBarMessage("Error while getting partner list"); // Use error.response.data here
      setLoading(false);
    }
    if (role === "State Head") {
      // getAllStateList().then((states) => setMenuList(states.data));
      
      getAllStateList().then((states) => {
        // Filter states based on location_ids
        if(loggedInUser.role === "State Head") {
        const filteredStates = states.data.filter((state) =>
          loggedInUser.details.partner.location_ids.includes(state.id)
        );
        setMenuList(filteredStates);}
        else {
          setMenuList(states.data);}
      });  
    } else if (role === "District Head") {
      getAllDistrictList().then((districts) => {
        // Filter districts based on location_ids
        if(loggedInUser.role === "District Head") {
        const filteredDistricts = districts.data.filter((district) =>
          loggedInUser.details.partner.location_ids.includes(district.id)
        );
        setMenuList(filteredDistricts);}
        else {
          setMenuList(districts.data);}
      });
    } else if (role === "Taluka Head") {
      // getAllTalukaList().then((taluka) => {
      //   setMenuList(taluka.data);
      // });
      getAllTalukaList().then((taluka) => {
        // Filter talukas based on location_ids
        if(loggedInUser.role === "Taluka Head") {
        const filteredTalukas = taluka.data.filter((taluka) =>
          loggedInUser.details.partner.location_ids.includes(taluka.id)
        );
        setMenuList(filteredTalukas);}
        else {
          setMenuList(taluka.data);}
      });
    }
  }, []);

  const handleChangeApplyFilter = () => {
    setApplyFilter(true);
  };

  useEffect(() => {
    if (clearFilter) {
      setSelectedLocationOptions([]);
      //setSelectedPartners([]);
      // role ni hatana h filters s , only clear other filters
      var tempFilter = [{type:"state",value:[]},{type:"district",value:[]},{type:"taluka",value:[]},{type:"role",value:[role]}]
      if(loggedInUser.details.partner===null)
      {
        tempFilter.push({type:"partner",value:[]})
        setSelectedPartners([]);
      }
      else{
        tempFilter.push({type:"partner",value:[loggedInUser.details.partner.id]})
      }
      setFilters(tempFilter)
     
    }
  }, [clearFilter]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "1rem",
        },
      }}
      onClose={handleClose}
    >
      <StyledGrid container>
        <Grid item xs={11}>
          <Typography xs={11} sx={{ fontSize: "20px", color: "#999DA4" }}>
            Filter By
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            {role === "State Head"
              ? "State"
              : role === "District Head"
              ? "District"
              : role === "Taluka Head"
              ? "Taluka"
              : null}
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={menuList}
            selectedOptions={selectedLocationOptions}
            setSelectedOptions={setSelectedLocationOptions}
          />
        </Grid>
        {loggedInUser.role==="Admin" &&
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Partner
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={partnerDataResponse}
            selectedOptions={partnerDataResponse.length>0?selectedPartners:[]}
            setSelectedOptions={setSelectedPartners}
            disable = {loggedInUser.details.partner!==null}
          />
        </Grid>}        
        <Grid item xs={12}></Grid>
        <Grid item xs={3}>
          <ClearBtn
            variant="contained"
            name="Clear Filter"
            onClick={() => setClearFilter(true)}
          >
            Clear All
          </ClearBtn>
        </Grid>

        <Grid item xs={3}>
          <ApplyBtn
            variant="contained"
            name="Apply Filter"
            onClick={handleChangeApplyFilter}
          >
            Apply
          </ApplyBtn>
        </Grid>
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: 'absolute',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </StyledGrid>
    </Popover>
  );
}
