import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as InfoButton } from "../../styles/info-tooltip.svg"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#E2E8E3',
        fontSize: theme.typography.pxToRem(12),
        border: '1.5px solid #E2E8E3',
        borderRadius: '16px',
        boxShadow: '6px 6px 16px 0px rgba(94, 96, 96, 0.16)',
        backdropFilter: 'blur(12px)',
        overflowWrap: 'anywhere', // Handle long text by wrapping it within the tooltip box
    },
    }));

const CustomTooltip = ({ title, onClick }) => {
    return (
    <HtmlTooltip
        title={
            <Typography
                sx={{
                display: 'inline-flex',
                padding: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                color:"#636363",
                maxWidth: '100%', // Ensure the text does not overflow the tooltip box
                }}
            >
            {title}
            </Typography>
        }
    arrow
    >
    <IconButton
    aria-label="info"
    size="small"
    style={{ marginLeft: '5px' }}
    onClick={onClick}
    >
        <InfoButton />
    </IconButton>
    </HtmlTooltip>
    );
};

export default CustomTooltip;
