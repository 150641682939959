import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  TextField,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import { useDispatch, useSelector } from "react-redux";
import SnackBars from "../reusableComponents/SnackBar";
import { editLocation } from "../../features/location/locationAPIs";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const mainGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
  paddingTop: 2,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditLocationModal({
  openModal,
  handleClose = () => {},
  data = {},
  setSnackBarMessage = () => { },
  setReload = () => { },
  setOpenSuccessBox = () => {},setSuccessMessage=()=>{}
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newVillageName, setNewVillageName] = useState(data.village.name);
  const [villageId, setVillageId] = useState(data.village.id);
  const [activeStatus, setActiveStatus] = useState(data.is_active);
  const [editVillageSnackBar, setEditVillageSnackBar] = useState(false);
  const [editVillageSnackBarMessage, setEditVillageSnackBarMessage] = useState(
    ""
  );
  const [editVillageSnackBarType, setEditVillageSnackBarType] = useState(
    "success"
  );
  const [loading, setLoading] = useState(false);

  const handleChangeVillage = (value) => {
    value = value.replace(/^\s+/g, '');
    setNewVillageName(value);
  };

  const handleActiveStatusChange = (e) => {
    setActiveStatus(e.target.checked);
  };

  const saveData = async () => {
    const data = {
      id: villageId,
      name: newVillageName,
      is_active: activeStatus,
    }
    setLoading(true);
    try {
      const response = await editLocation(data)
      if (response != null && response != undefined &&
        response.data !== null && response.data !== undefined &&
        response.data.message !== null && response.data.message !== undefined
      ) {
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"])
        setLoading(false);
        setReload(true);
        handleClose();
      } else {
        // setSnackBarType("error");
        setSnackBarMessage("Something went wrong on sever!");
        setLoading(false);
      }
    } catch (error) {
      setEditVillageSnackBar(true);
      setEditVillageSnackBarType("error");
      setEditVillageSnackBarMessage(error.response.data["message"]);
      setLoading(false);
    }
  };

  const handleCloseByClearIcon = () => {
    handleClose();
    setActiveStatus("");
    setSnackBarMessage("");
    setReload(false);
    setEditVillageSnackBar(false);
    setEditVillageSnackBarMessage("");
  };

  const displayTextField = (
    handleChange,
    is_disabled,
    index,
    value,
    allowOnChange
  ) => {
    return (
      <TextField
        key={index}
        defaultValue={value}
        disabled={is_disabled}
        variant="outlined"
        sx={{
          width: "100%", backgroundColor: "#FFFFFF", borderRadius: "1rem", "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem",
          }
        }}
        inputProps={{
          maxLength: 40,
          style: {
            height: "40%",
            borderRadius: "1rem",
          },
        }}
        onChange={(e) => allowOnChange && handleChange(e.target.value, index)}
      />
    );
  };

  return (
    <Modal
      open={openModal}
    >
      <Grid container sx={mainGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid item xs={11}>
            <Typography variant="h4" sx={{ color: "#444444", fontWeight: "500" }}>
              Edit Location
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleCloseByClearIcon}
            />
          </Grid>
          <Grid
            container
            item
            xs={11.8}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "#F5F5F5", borderRadius: '1rem', marginTop: 3 }}
            style={{ padding: '16px 0 20px 20px' }}
          >
            {/* <Grid container spacing={2}> */}
            <Grid item xs={12} sm={2.5}>
              <Typography sx={{ marginBottom: 1 }}>State</Typography>
              {displayTextField(() => { }, true, 0, data.state.name, false)}
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography sx={{ marginBottom: 1 }}>District</Typography>
              {displayTextField(() => { }, true, 1, data.district.name, false)}
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
              {displayTextField(() => { }, true, 2, data.taluka.name, false)}
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography sx={{ marginBottom: 1 }}>Village</Typography>
              {displayTextField(
                handleChangeVillage,
                false,
                3,
                data.village.name,
                true
              )}
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={1}
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Checkbox
                defaultChecked={data.is_active}
                onChange={(e) => handleActiveStatusChange(e)}
                sx={{
                  color: "#AAC1B0",
                  "&.Mui-checked": { color: "#2D6A4F" },
                }}
              />
            </Grid> */}
            {/* </Grid> */}
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              item
              sm={12}
              spacing={0}
              sx={{
                direction: "row",
                justifyContent: "center",
                marginTop: 5,
                marginBottom: 3,
              }}
            >
              <Grid
                item
                xs={12}
                sm={2.5}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <CancelButtonStyle
                  variant="contained"
                  name="cancel"
                  onClick={handleClose}
                >
                  Cancel
                </CancelButtonStyle>
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <ButtonStyle
                  variant="contained"
                  name="add"
                  startIcon={<CheckIcon size="large" />}
                  onClick={saveData}
                  disabled={newVillageName === ""}
                >
                  Save
                </ButtonStyle>
              </Grid>
            </Grid>
            <div style={{ willChange: "transform" }}>
              {editVillageSnackBar && (
                <SnackBars
                  open={editVillageSnackBar}
                  handleClose={() => setEditVillageSnackBar(false)}
                  severity={editVillageSnackBarType}
                  message={editVillageSnackBarMessage}
                  autoHideDuration={6000}
                  vertical="bottom"
                  horizontal="center"
                />
              )}
            </div>
            {loading && (
              <Backdrop
                sx={{
                  color: "#ffffff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
