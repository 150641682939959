import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Modal, ThemeProvider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import LocationDropDown from "../LocationDropDown";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { removeDuplicates } from "../../utils/index";
import {
  addPartner,
  resetAddPartner,
} from "../../features/partner/partnerSlice";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import { getStatesData } from "../../features/location/locationAPIs";
import AutoCompleteMultiDropdown from "../projects/AutoCompleteMultiDropdown";
import { getProjectData } from "../../features/projects/projectsAPIs";
import { postPartnerData } from "../../features/partner/partnerAPIs";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  px: 6,
  boxShadow: 24,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflowY: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function AddPartnerModal({
  openAddModal,
  handleClose,
  setPartnerSnackBarMessage = () => {},
  setPartnerSnackBarType = () => {},
  setReload = () => {},
  setOpenSuccessBox=()=>{},
  setSuccessMessage=()=>{},
  setOpenSuccessBoxType = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [partnerName, setPartnerName] = useState("");
  const [spocName, setSpocName] = useState("");
  const [spocNumber, setSpocNumber] = useState("");
  const [spocEmail, setSpocEmail] = useState("");
  const [projects, setProjects] = useState([]);
  const [partnerStatus, setPartnerStatus] = useState("");
  const [addPartnerSnackBar, setAddPartnerSnackBar] = useState(false);
  const [addPartnerSnackBarMessage, setAddPartnerSnackBarMessage] =
    useState("");
  const [getStatesResponse, setGetStateResponse] = useState([
    {
      id: "",
      name: "",
    },
  ]);
  //const [projectList, setProjectList] = useState([{ id: "", name: "" }]);
  const basicError = {
    partnerName: "",
    spocEmail: "",
    spocNumber: "",
    spocName: "",
    partnerStatus: "",
    projects: "",
  };
  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);

  const partnerStatusList = ["Active", "Inactive"];
  const protocolList = [
    { id: 1, name: "Weekly Monitoring Protocol" },
    { id: 2, name: "CICR" },
  ];
  const [loading, setLoading] = useState(false);

  const [addAnotherLocationDropDown, setAddAnotherLocationDropDown] =
    useState(1);
  const [location, setLocation] = useState([
    {
      state: null,
      district: null,
      taluka: null,
      villages: [],
    },
  ]);
  const [collapseHeader, setCollapseHeader] = useState(false);

  const validateFormData = () => {
    let partnerNameError="";
    //let partnerStatusError="";
    let partnerEmailError="";
    let partnerMobileError="";
    let partnerSPOCNameError="";
    //let partnerProjectsError="";
    if (partnerName.trim() === "") {
      partnerNameError = "Partner Name can't be empty"
    } 
    // if (partnerStatus === "") {
    //   partnerStatusError = "Partner Status can't be empty"
    // }
    if (spocEmail === "") {
      partnerEmailError = "Email can't be empty"
    }
    if (spocNumber === "") {
      partnerMobileError = "Phone Number can't be empty"
    }
    // if (projects.length === 0) {
    //   partnerProjectsError = "Projects can't be empty"
    // }
    if (spocName === "") {
      partnerSPOCNameError = "SPOC Name can't be empty"
    }
    setErrors({...errors, partnerName: partnerNameError, spocEmail: partnerEmailError, spocNumber: partnerMobileError, spocName: partnerSPOCNameError});
  }

  const handleChangePartnerName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
      if (value.trim() === "") {
        setErrors({ ...errors, partnerName: "Partner Name can't be empty" });
        value = "";
      } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
        setErrors({ ...errors, partnerName: "Partner characters are not allowed in SPOC Name" });
        value = "";
      } else {
        setErrors({ ...errors, partnerName: "" });
      }
    setPartnerName(value);
  };

  const handleChangeSpocName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
      if (value.trim() === "") {
        setErrors({ ...errors, spocName: "SPOC Name can't be empty" });
        value = "";
      } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
        setErrors({ ...errors, partnerName: "Special characters are not allowed in Partner's Name" });
        value = "";
      } else {
        setErrors({ ...errors, spocName: "" });
      }
    setSpocName(value);
  };

  const handleChangeSpocEmail = (value) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (value.match(mailformat)) {
      setErrors({ ...errors, spocEmail: "" });
    }else if(value ==="") {
      setErrors({ ...errors, spocEmail: "Email can't be empty" });
    }else {
      setErrors({ ...errors, spocEmail: "Invalid Email " });
    }
    setSpocEmail(value);
  };

  const handleChangePartnerStatus = (event) => {
    setPartnerStatus(event.target.value);
    setErrors({ ...errors, partnerStatus: "" });
  };

  const handleChangeProjects = (value) => {
    setProjects(value);
    setErrors({ ...errors, projects: "" });
  }
  
  const handleChangeSpocNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, spocNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        spocNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, spocNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, spocNumber: "" });
    }
    setSpocNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setPartnerName("");
    setSpocName("");
    setSpocNumber("");
    setSpocEmail("");
    //setProjects("");
//     setPartnerStatus("");
    setAddPartnerSnackBar(false);
    setSnackBarType("success");
    setAddPartnerSnackBarMessage("");
    setPartnerSnackBarMessage("");
    setLocation([
      {
        state: null,
        district: null,
        taluka: null,
        villages: [],
      },
    ]);
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
      taluka_ids: [],
      village_ids: [],
    };
    if (location[0].villages.length > 0) {
      location.forEach((item) => {
        if(item.villages.length>0){
          locations["state_ids"].push(item.state.id);
          locations["district_ids"].push(item.district.id);
          locations["taluka_ids"].push(item.taluka.id);
          item.villages.forEach((villageObj) => {
            if (villageObj.id !== "all")
              locations["village_ids"].push(villageObj.id);
          });
        }
      });
      locations["state_ids"] = removeDuplicates(locations["state_ids"]);
      locations["district_ids"] = removeDuplicates(locations["district_ids"]);
      locations["taluka_ids"] = removeDuplicates(locations["taluka_ids"]);
      locations["village_ids"] = removeDuplicates(locations["village_ids"]);
    }
    return locations;
  };

  const handleSaveData = async () => {
    var locations = setLocationForApi();
    if (
      errors.partnerName === "" &&
      errors.spocEmail === "" &&
      errors.spocName === "" &&
      errors.spocNumber === "" &&
      partnerName !== "" &&
      spocName !== "" &&
      spocNumber !== "" &&
      spocEmail !== "" &&
      // projects.length !== 0 &&
//       partnerStatus !== "" &&
      locations.village_ids.length > 0 &&
      location[location.length - 1].villages.length > 0
    ) {
      setAddPartnerSnackBar(false);
      setSnackBarType("success");
      setAddPartnerSnackBarMessage("");
      var isActive = partnerStatus === "Active" ? true : false;
      setLoading(true);
      // var project_ids = projects.map((item, index) => {
      //   return item.id;
      // });
      const data = {
        name: partnerName,
        spoc_name: spocName,
        spoc_email: spocEmail,
        spoc_phone_number: spocNumber,
        is_active: true,
        //project_ids: project_ids,
        group: "partner_head",
        location_ids: locations.village_ids,
        location_type: "village",
      };
      try {
        const response = await postPartnerData(data);
        // setPartnerSnackBarType("success");
        // setPartnerSnackBarMessage(response.data["message"]);
        setReload(true);
        setLoading(false);
        handleClose();
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success');
        setOpenSuccessBox(true);
      } catch (error) {
        console.log(error);
        setOpenSuccessBox(true);
        setSuccessMessage(error.response.data["message"]);
        setOpenSuccessBoxType('error');
        setLoading(false);
      }
    } else {
      validateFormData();
      setOpenSuccessBox(true);
      setSuccessMessage("Please fill all the required fields");
      setOpenSuccessBoxType('error');

    }
  };
  useEffect(() => {
    if (getStatesResponse[0].id === "") {
      const params = {};
      const fetchData = async () => {
        try {
          const response = await getStatesData(params);
          if (
            response != null &&
            response != undefined &&
            response.data !== null &&
            response.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined
          ) {
            setGetStateResponse(response.data.data);
          } else {
            setAddPartnerSnackBar(true);
            setSnackBarType("error");
            setAddPartnerSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setAddPartnerSnackBar(true);
          setSnackBarType("error");
          setAddPartnerSnackBarMessage(
            "Not able to fetch state, Please check server!"
          );
        }
      };
      fetchData();
    }
    // if (projectList[0].id === "") {
    //   const params = { fields: ["id", "name"] };
    //   const fetchData = async () => {
    //     try {
    //       const response = await getProjectData(params);
    //       if (
    //         response != null &&
    //         response != undefined &&
    //         response.data !== null &&
    //         response.data !== undefined &&
    //         response.data.data !== null &&
    //         response.data.data !== undefined
    //       ) {
    //         setProjectList(response.data.data);
    //       } else {
    //         setAddPartnerSnackBar(true);
    //         setSnackBarType("error");
    //         setAddPartnerSnackBarMessage("Something went wrong on sever!");
    //       }
    //     } catch (error) {
    //       setAddPartnerSnackBar(true);
    //       setSnackBarType("error");
    //       setAddPartnerSnackBarMessage(
    //         "Not able to fetch projects, Please check server!"
    //       );
    //     }
    //   };
    //   fetchData();
    // }
  }, []);

  const handleChangeLocation = (newValue, index, type, menuList) => {
    var newLocation = [...location];
    if (type === "state") {
      newLocation[index].state = newValue;
      newLocation[index].district = null;
      newLocation[index].taluka = null;
      newLocation[index].villages = [];
      setLocation(newLocation);
    } else if (type === "district") {
      newLocation[index].district = newValue;
      setLocation(newLocation);
      newLocation[index].taluka = null;
      newLocation[index].villages = [];
    } else if (type === "taluka") {
      newLocation[index].taluka = newValue;
      setLocation(newLocation);
      newLocation[index].villages = [];
    } else if (type === "village") {
      
      var oldSelected = newLocation[index].villages;
      var containsAllInOld = oldSelected.some((obj) => obj.name === "All");
      var containsAll = newValue.some((obj) => obj.name === "All");
      
      if (containsAll) {
        newLocation[index].villages = menuList;
        console.log("manulisy",menuList)
      } else if (!containsAll && containsAllInOld) {
        newLocation[index].villages = [];
      } else {
        newLocation[index].villages = newValue;
      }
      setLocation(newLocation);
    }
  };

  const handleChangeAddAnotherLocationBox = (event) => {
    if (location[addAnotherLocationDropDown - 1].villages.length !== 0) {
      const newLocation = [...location];
      newLocation.push({
        state: null,
        district: null,
        taluka: null,
        villages: [],
      });
      setLocation(newLocation);
      setAddAnotherLocationDropDown((prevIndex) => prevIndex + 1);
    }
  };

  const handleChangeDeleteLocation = (index) => {
    setAddAnotherLocationDropDown((prevIndex) => prevIndex - 1);
    setLocation((names) => names.filter((_, ind) => ind !== index));
  };

  const handleAddAnotherLocationBox = (index) => {
    return (
      <React.Fragment key={index}>
        <LocationDropDown
          location={location}
          handleChangeLocation={handleChangeLocation}
          handleChangeDeleteLocation={handleChangeDeleteLocation}
          index={index}
          getStatesResponse={getStatesResponse}
          addAllVillage={true}
          setSnackBar={setAddPartnerSnackBar}
          setSnackBarType={setSnackBarType}
          setSnackBarMessage={setAddPartnerSnackBarMessage}
        />
      </React.Fragment>
    );
  };

  return (
    <Modal open={openAddModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 4,
            }}
          >
            <Grid item xs={7}>
              <Typography
                variant="h4"
                sx={{ color: "#444444", fontWeight: "500" }}
              >
                Add Partner
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end"}}>
              <Box sx={{ display: "flex", alignItems: "start", cursor: "pointer" }} onClick={() => setCollapseHeader(prev => !prev)}>
                <Typography variant="h6" >
                  {collapseHeader ? "Partner Details" : "Collapse"}
                </Typography>
                {collapseHeader ? <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }}/> : <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }}/>}
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              sx={{
                width: "90%",
                paddingLeft: 1,
                paddingY: 1
              }}
            >
              {!collapseHeader && <><Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Partner Name</Typography>
                <BasicTextfield
                  label="Enter Partner Name"
                  is_phone={false}
                  handleChange={handleChangePartnerName}
                  is_disabled={false}
                  index={1}
                  error={errors.partnerName !== ""}
                  helperText={errors.partnerName}
                  required={true}
                  value={partnerName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Partner Status</Typography>
                <BasicDropDown
                  text="Select Partner Status"
                  value={partnerStatus}
                  handleChange={handleChangePartnerStatus}
                  menuList={partnerStatusList}
                  required={true}
                  error={errors.partnerStatus !== ""}
                  errorText={errors.partnerStatus}
                />
              </Grid> */}
  
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>SPOC Name</Typography>
                <BasicTextfield
                  label="Enter Spoc Name"
                  is_phone={false}
                  handleChange={handleChangeSpocName}
                  is_disabled={false}
                  index={3}
                  error={errors.spocName !== ""}
                  helperText={errors.spocName}
                  required={true}
                  value={spocName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  SPOC Mobile Number
                </Typography>
                <BasicTextfield
                  label="Enter Spoc Mobile Number"
                  is_phone={true}
                  handleChange={handleChangeSpocNumber}
                  is_disabled={false}
                  index={4}
                  error={errors.spocNumber !== ""}
                  helperText={errors.spocNumber}
                  required={true}
                  value={spocNumber}
                  number={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>SPOC Email ID</Typography>
                <BasicTextfield
                  label="Enter Spoc Email Id"
                  is_phone={false}
                  handleChange={handleChangeSpocEmail}
                  is_disabled={false}
                  index={5}
                  error={errors.spocEmail !== ""}
                  helperText={errors.spocEmail}
                  required={true}
                  value={spocEmail}
                />
              </Grid></>}
              {/* <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Projects</Typography>
                <AutoCompleteMultiDropdown
                  width={230}
                  menuList={projectList}
                  // value={projects}
                  selectedOptions={projects}
                  onHighlightChange={(value) => {
                    handleChangeProjects(value)
                  }}
                  label="Select Projects"
                  // error={false}
                  required={true}
                  error={errors.projects !== ""}
                  errorText={errors.projects}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Typography sx={{ marginBottom: 1 }}>Location </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: "90%",
            }}
          >
            <Grid container spacing={2}>
              {Array.from({
                length: addAnotherLocationDropDown,
              }).map((_, index) => handleAddAnotherLocationBox(index))}
            </Grid>
            <Grid item xs={12}>
            <Typography
              sx={{
                marginBottom: 1,
                color: "#2D6A4F",
                marginTop: 2,
                cursor: "pointer",
                display: "inline-block", // Add this style
              }}
              onClick={(e) => handleChangeAddAnotherLocationBox(e)}
            >
                + Add in another State/District/Taluka
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "white",
              justifyContent: "center",
            }}
          >
            <Grid item sx={{ margin: "30px" }}>
              <CancelButtonStyle
                variant="contained"
                name="cancel"
                onClick={handleClose}
              >
                Cancel
              </CancelButtonStyle>
            </Grid>
            <Grid item sx={{ margin: "30px" }}>
              <ButtonStyle
                variant="contained"
                name="add"
                startIcon={<CheckIcon size="large" />}
                onClick={handleSaveData}
              >
                Save
              </ButtonStyle>
                {addPartnerSnackBar && (
                  <SnackBars
                    open={addPartnerSnackBar}
                    handleClose={() => setAddPartnerSnackBar(false)}
                    severity={snackBarType}
                    message={addPartnerSnackBarMessage}
                    autoHideDuration={6000}
                    vertical="bottom"
                    horizontal="center"
                    position="absolute"
                  />
                )}
            </Grid>
          </Grid>
          <BackDropLoader loading={loading} />
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
