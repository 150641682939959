import { projectAPI } from "../../api's/index";
import axios from "axios";
import {getHeaders,commonApiCall} from "../../utils/index"
var qs = require("qs");

export const getProjectData = async (params) => {
    const response = await commonApiCall({
        method: 'get',
        url: projectAPI,
        params: params
    });
    return response
}

export const postProjectData = async (data) => {
    const response = await commonApiCall({
        method: 'post',
        url: projectAPI,
        data: data,
    });
    return response
}

export const editProjectData = async (data, id) => {
    try {
        const headers = getHeaders();
        const response = await axios({
            method: "put",
            url: projectAPI + id,
            data: data,
            headers: headers,
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteProjectData = async (id) => {
    try {
        const headers = getHeaders();
        const response = await axios({
            method: "delete",
            url: projectAPI + id,
            headers: headers,
        });
        return response;
    } catch (error) {
        throw error;
    }
}