
import React from "react";
import {Box,Grid} from '@mui/material';
import { styled } from '@mui/material/styles';

  const MainGrid = styled(Grid)({
    flex: "1",
    marginTop: "200px",
    marginLeft: "200px",
    backgroundColor: "white",
  });
export default function Dashboard ({}) {

    return(

    <MainGrid>
         <Grid item xs={12}>
        <h1>Hello choose from menu</h1>
        </Grid>
    </MainGrid>
    )
}