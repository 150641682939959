import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  Box,
  Chip,
  Snackbar,
  Collapse,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ButtonWithCheckBox from "./ResponseMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import CustomTooltip from "../helpers/CustomTooltip";
import { containsOnlyEnglishAlphabets } from "../../utils";
export default function AddFormCard({
  index,
  serialNumber = index,
  view = false,
  edit = false,
  question = {},
  setQuestions = () => {},
  selectedLanguage = "english",
  setSelectedLanguage = () => {},
  autofocus=false,
  setOpenSuccessBox = () => { },
  setSuccessMessage = () => { },
  setOpenSuccessBoxType = () => { }
}) {
  const chipStyle = {
    margin: "4px",
    color: "#5E6060",
    backgroundColor: "#F1F1F1",
    fontSize: "16px",
    borderRadius: "1.5px",
  };

  const [responseTypeList, setResponseTypeList] = useState([
    "Audio",
    "Text",
    "Image",
    "Dropdown",
    "Radio",
  ]);
  const [questionTypeList, setQuestionTypeList] = useState(["Quantity", "MCQ","Date"]);

  const [collapsed, setCollapsed] = useState(true);
  const [questionText, setQuestionText] = useState(question["questions"]);
  const [questionResponseType, setQuestionResponseType] = useState(
    question["response_type"]
  );
  const [questionType, setQuestionType] = useState(question["question_type"]);
  const [responses, setResponses] = useState(
    question["possible_responses"] !== undefined
      ? question["possible_responses"].split(",")
      : ""
  );
  const [units, setUnits] = useState(
    question["Units"] !== undefined ? Array.from(new Set(question["Units"].split(","))) : ""
  );
  const [isAddingResponse, setIsAddingResponse] = useState(false);
  const [newResponse, setNewResponse] = useState("");
  const [isAddingUnit, setIsAddingUnit] = useState(false);
  const [newUnit, setNewUnit] = useState("");
  const [responseDictionary, setResponseDictionary] = useState(() => {
    // Check if responses is not an empty string
    if (typeof responses !== "string") {
      // Create an object with keys from the responses array and empty lists as values
      const initialDictionary = responses.reduce((acc, response) => {
        acc[response] = [];
        return acc;
      }, {});

      return initialDictionary;
    } else {
      // If responses is an empty string, return an empty dictionary
      return {};
    }
  });
  const [newSynonym, setNewSynonym] = useState("");
  const [isAddingSynonym, setIsAddingSynonym] = useState(false);
  const [addingSynonymFor, setAddingSynonymFor] = useState("");

  const handleToggleAddingSynonym = (response) => {
    setIsAddingSynonym((prevIsAdding) => !prevIsAdding);
    setAddingSynonymFor(response);
    if (!isAddingSynonym) {
      setNewSynonym("");
    }
  };

  const handleRemoveSynonym = (response, synonymIndex) => {
    const updatedDictionary = { ...responseDictionary };
    updatedDictionary[response].splice(synonymIndex, 1);
    setResponseDictionary(updatedDictionary);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["dict"] = updatedDictionary;
      return updatedQuestions;
    });
  };

  const handleAddSynonym = (response) => {
    if (!containsOnlyEnglishAlphabets(newSynonym)) {
      setOpenSuccessBox(true);
      setSuccessMessage(`Please enter Synonym in english`);
      setOpenSuccessBoxType('error');
      setNewSynonym("")
      return;
    }
    const updatedDictionary = { ...responseDictionary }; // Create a copy of the dictionary
    const synonyms = updatedDictionary[response] || [];

    const checkRepeatation = synonyms.map(resp => resp.toLowerCase()).indexOf(newSynonym.trim().toLowerCase())

    if (checkRepeatation === -1) {
      if (!updatedDictionary[response]) {
        updatedDictionary[response] = [];
      }
      if (newSynonym.trim() !== "") {
        updatedDictionary[response].push(newSynonym.trim());
        setNewSynonym("");
        setResponseDictionary(updatedDictionary);
        // Update the question with the new possible_responses string
        setQuestions((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[index]["dict"] = updatedDictionary;
          return updatedQuestions;
        });
      }
    } else {
      setOpenSuccessBox(true);
      setSuccessMessage(`Duplicate Value! Please enter a new value.`);
      setOpenSuccessBoxType('error');
    }
  };

  const handleTextChange = (event) => {
    if (!containsOnlyEnglishAlphabets(event.target.value)) {
      setOpenSuccessBox(true);
      setSuccessMessage(`Please enter Question in english`);
      setOpenSuccessBoxType('error');
      setNewSynonym("")
      return;
    }
    setQuestionText(event.target.value);
  };

  const handleBlur = () => {
    setQuestionText(questionText);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["questions"] = questionText;
      return updatedQuestions;
    });
  };

  const handleChangeResponseType = (event) => {
    setQuestionResponseType(event.target.value);
    setQuestionType("");
    setResponses([]);
    setUnits([]);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["possible_responses"] = "";
      return updatedQuestions;
    });
  };

  useEffect(() => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["response_type"] = questionResponseType;
      return updatedQuestions;
    });
  }, [questionResponseType]);
  useEffect(() => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["question_type"] = questionType;
      return updatedQuestions;
    });
  }, [questionType]);

  const handleChangeQuestionType = (event) => {
    setQuestionType(event.target.value);
    setUnits([]);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["Units"] = undefined;
      return updatedQuestions;
    });
    setResponses([]);
    setResponseDictionary({});
  };

  const updateResponseDictionary = (newResponses) => {
    const updatedDictionary = newResponses.reduce((acc, response) => {
      acc[response] = responseDictionary[response] || [];
      return acc;
    }, {});

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["dict"] = updatedDictionary;
      return updatedQuestions;
    });
    setResponseDictionary(updatedDictionary);
  };
  const handleChangeResponse = (event) => {
    setNewResponse(event.target.value);
  };

  const handleKeyPressResponse = (event) => {
    if (event.key === "Enter") {
      if (!containsOnlyEnglishAlphabets(newResponse)) {
        setOpenSuccessBox(true);
        setSuccessMessage(`Please enter Response in English`);
        setOpenSuccessBoxType('error');
        setNewResponse("");
        setIsAddingResponse(false);
        return
      }
      setResponses((prevResponses) => {
        const checkRepeatation = prevResponses.map(resp => resp.toLowerCase()).indexOf(newResponse.trim().toLowerCase())
        if (checkRepeatation === -1) {
          const newResponses = Array.from(new Set([...prevResponses, newResponse]));
  
          // Join the responses array into a comma-separated string
          const newPossibleResponses = newResponses.join(",");
  
          // Update the question with the new possible_responses string
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index]["possible_responses"] = newPossibleResponses;
            return updatedQuestions;
          });
  
          if ((questionResponseType === "Audio" && questionType === "MCQ") || (questionResponseType === "Text" && questionType === "MCQ")) {
            updateResponseDictionary(newResponses);
          }
  
          setNewResponse("");
          setIsAddingResponse(false);
          return newResponses;
        } else {
          setOpenSuccessBox(true);
          setSuccessMessage(`Duplicate Value! Please enter a new value.`);
          setOpenSuccessBoxType('error');
          setNewResponse("");
          setIsAddingResponse(false);
          return prevResponses;
        }
      });
    }
  };

  const handleRemoveResponses = (indexToRemove) => {
    setResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses.splice(indexToRemove, 1);

      // Join the updated responses array into a comma-separated string
      const newPossibleResponses = updatedResponses.join(",");

      // Update the question with the new possible_responses string
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index]["possible_responses"] = newPossibleResponses;
        return updatedQuestions;
      });

      if ((questionResponseType === "Audio" && questionType === "MCQ") || (questionResponseType === "Text" && questionType === "MCQ")) {
        updateResponseDictionary(updatedResponses);
      }

      return updatedResponses;
    });
  };

  const handleToggleAddingResponse = () => {
    setIsAddingResponse((prevIsAdding) => !prevIsAdding);
  };

  const handleChangeUnit = (event) => {
    setNewUnit(event.target.value);
  };

  const handleKeyPressUnit = (event) => {
    if (event.key === "Enter") {
      if (!containsOnlyEnglishAlphabets(newUnit)) {
        setOpenSuccessBox(true);
        setSuccessMessage(`Please enter Unit in English`);
        setOpenSuccessBoxType('error');
        setNewUnit("");
        setIsAddingUnit(false);
        return
      }
      setUnits((prevUnits) => {
        const checkRepeatation = prevUnits.map(unit => unit.toLowerCase()).indexOf(newUnit.trim().toLowerCase())
        if (checkRepeatation === -1) {
          const newUnits = Array.from(new Set([...prevUnits, newUnit]));
          // Join the responses array into a comma-separated string
          const newPossibleUnits = newUnits.join(",");
          // Update the question with the new possible_responses string
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index]["Units"] = newPossibleUnits;
            return updatedQuestions;
          });
  
          setNewUnit("");
          setIsAddingUnit(false);
          return newUnits;
        } else {
          setOpenSuccessBox(true);
          setSuccessMessage(`Duplicate Value! Please enter a unique Unit.`);
          setOpenSuccessBoxType('error');
          setNewUnit("");
          setIsAddingUnit(false);
          return prevUnits;
        }
      });
    }
  };

  const handleRemoveUnits = (indexToRemove) => {
    setUnits((prevUnits) => {
      const updatedUnits = [...prevUnits];
      updatedUnits.splice(indexToRemove, 1);

      // Join the updated responses array into a comma-separated string
      const newPossibleUnits = updatedUnits.join(",");

      // Update the question with the new possible_responses string
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index]["Units"] = newPossibleUnits;
        return updatedQuestions;
      });

      return updatedUnits;
    });
  };

  const handleToggleAddingUnit = () => {
    setIsAddingUnit((prevIsAdding) => !prevIsAdding);
  };

  const handleDelete = () => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index, 1);
      return updatedQuestions;
    });
  };

  useEffect(() => {
    setQuestionResponseType(question["response_type"]);
    setQuestionType(question["question_type"]);
    // sheet has param for questions
    setQuestionText(question["questions"]);
    setResponses(
      question["possible_responses"] !== undefined
        ? question["possible_responses"].split(",")
        : []
    );
    setUnits(
      question["Units"] !== undefined ? question["Units"].split(",") : []
    );
    setResponseDictionary(
      question["dict"] !== undefined ? question["dict"] : {}
    );
  }, [question]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      sx={{
        padding: 2,
        marginLeft: 1,
        marginTop: 3,
        borderRadius: "1rem",
        width: "80%",
        backgroundColor: "#F5F5F5",
        overflowX: "auto",
      }}
    >
      <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
        <Box sx={{ marginRight: 1 }}>
          <strong>{serialNumber + 1}.&nbsp;</strong>
        </Box>
        <TextField
          variant="standard"
          size="small"
          value={questionText}
          onChange={handleTextChange}
          onBlur={handleBlur}
          multiline
          fullWidth
          sx={{
            color: "#444444",
          }}
          InputProps={{
            disableUnderline: true,
            style: { fontSize: "16px" },
          }}
          disabled={view}
          autoFocus={autofocus}
          error={questionText !== undefined && questionText.trim() === ""}
          helperText={
            questionText !== undefined && questionText.trim() === ""
              ? "This field is empty"
              : ""
          }
        />
      </Grid>

      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <Grid item xs={3} sm={6} md={12} sx={{ display: "flex" }}>
          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
              marginLeft: 2,
              marginTop: 2,
              borderRadius: "0.5rem",
              backgroundColor: "",
              minWidth: "300px",
            }}
          >
            <Box
              sx={{
                color: "#78828C",
                marginRight: 3,
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Align items at the start and end
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#7B7B7B",
                }}
              >
                Response Type
              </Typography>
              <CustomTooltip title="The 'response_type' refers to the type of response you're seeking from users in your mobile application. Options for response types include: Audio, Image, Text, Radio, and Dropdown." />
            </Box>
            <BasicDropDown
              value={questionResponseType}
              handleChange={handleChangeResponseType}
              menuList={responseTypeList}
            />
          </Box>
          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
              marginLeft: 2,
              marginTop: 2,
              borderRadius: "0.5rem",
              minWidth: "300px",
              alignItems: "center",
            }}
          >
            {/* <Box
              sx={{
                color: "#78828C",
                marginBottom: 2,
                marginRight: 3,
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Align items at the start and end
              }}
            > */}
            <Box sx={{ color: "#78828C", marginRight: 2, marginBottom: 2 }}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#7B7B7B",
                  marginBottom: "10px",
                }}
              >
                Question Type
              </Typography>
              {/* <CustomTooltip title="The 'response_type' refers to the type of response you're seeking from users in your mobile application. Options for response types include: Audio, Image, Text, Radio, and Dropdown." /> */}
            </Box>

            <BasicDropDown
              text={(questionResponseType === "Audio" || questionResponseType=="Text") ? "Select question type" : questionResponseType}
              value={questionType}
              handleChange={handleChangeQuestionType}
              menuList={questionTypeList}
              disable={!(questionResponseType === "Audio" || questionResponseType=="Text")}
            />
          </Box>
        </Grid>

        {(questionResponseType === "Dropdown" ||
          questionResponseType === "Radio" ||
          (questionResponseType === "Audio" && questionType === "MCQ") || questionResponseType === "Text" && questionType === "MCQ") && (
          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingY: 1,
                marginLeft: 2,
                marginTop: 1,
              }}
            >
              <Box
                sx={{
                  color: "#78828C",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Align items at the start and end
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#7B7B7B",
                  }}
                >
                  Responses
                </Typography>
                <CustomTooltip title="Under the 'Responses' column, include all the potential responses listed in the 'possible_responses' column for each question. Each response serves as a unique 'Response' entry" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  p: 1,
                  border: 1,
                  borderRadius: 3,
                  borderColor: "#E6E9EA",
                  backgroundColor: "#ffffff",
                  maxHeight: 100,
                  overflow: "auto",
                }}
              >
                {!isAddingResponse && responses && responses.length === 0 ? (
                  <Chip label="Add a response" style={chipStyle} />
                ) : (
                  responses.map((response, index) => (
                    <Chip
                      key={index}
                      label={response}
                      onDelete={() => handleRemoveResponses(index)}
                      deleteIcon={<ClearIcon />}
                      style={chipStyle}
                    />
                  ))
                )}

                {isAddingResponse && (
                  <TextField
                    value={newResponse}
                    onChange={handleChangeResponse}
                    onKeyPress={handleKeyPressResponse}
                    placeholder="Add a response"
                    style={{ width: "150px", fontSize: "12px" }}
                    inputProps={{
                      style: { height: "30px", padding: "6px 12px" }, // Adjust height and padding
                    }}
                  />
                )}
                <IconButton onClick={handleToggleAddingResponse}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        )}
        {((questionType === "Quantity" && questionResponseType === "Audio") || (questionType === "Quantity" && questionResponseType === "Text")) && (
          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingY: 1,
                marginLeft: 2,
                marginTop: 1,
              }}
            >
              <Box
                sx={{
                  color: "#78828C",
                  marginBottom: "10px",
                  marginX: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Align items at the start and end
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#7B7B7B",
                  }}
                >
                  Units
                </Typography>
                <CustomTooltip title="The 'Units' refers to the possible list of units for each question in english. For Ex: If the question is 'What is the total area of your land?' then the 'units' can be a list of units such as {Acres, Bigha, etc.}" />
              </Box>

              <Box
                display="flex"
                flexWrap="wrap"
                p={1}
                border={1}
                borderRadius={3}
                borderColor="grey.300"
                maxHeight={100} // Adjust the maxHeight as needed
                overflow="auto"
                backgroundColor="#ffffff"
              >
                {!isAddingUnit && units && units.length === 0 ? (
                  <Chip label="Add a unit" style={chipStyle} />
                ) : (
                  units.map((unit, index) => (
                    <Chip
                      key={index}
                      label={unit}
                      onDelete={() => handleRemoveUnits(index)}
                      deleteIcon={<ClearIcon />}
                      style={chipStyle}
                    />
                  ))
                )}
                {isAddingUnit && (
                  <TextField
                    value={newUnit}
                    onChange={handleChangeUnit}
                    onKeyPress={handleKeyPressUnit}
                    placeholder="Add new unit"
                    style={{
                      margin: "2px",
                      width: "150px",
                      fontSize: "12px",
                    }}
                    inputProps={{
                      style: { height: "30px", padding: "6px 12px" }, // Adjust height and padding
                    }}
                  />
                )}
                <IconButton onClick={handleToggleAddingUnit}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        )}

        {((questionResponseType === "Audio" &&
          questionType === "MCQ") ||  (questionResponseType === "Text" &&
          questionType === "MCQ") )&&
          responses &&
          responses.length !== 0 && (
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingX: 2,
                  paddingY: 1,
                  marginLeft: 2,
                  marginTop: 1,
                }}
              >
                <Box
                  sx={{
                    color: "#78828C",
                    marginRight: 3,
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Align items at the start and end
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#7B7B7B",
                    }}
                  >
                    Values
                  </Typography>
                  <CustomTooltip title="Each entry in the 'Values' column corresponds to a synonym for the respective 'Responses'." />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column" // Change flex direction to column
                  p={1}
                  border={1}
                  borderRadius={3}
                  borderColor="grey.300"
                  maxHeight={300} // Adjust the maxHeight as needed
                  overflow="auto"
                  style={{ marginTop: 10, paddingLeft: 8 }}
                  backgroundColor="#ffffff"
                  marginRight={2}
                >
                  {Object.keys(responseDictionary).map((response, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      borderBottom="1px solid #ccc"
                      padding="8px"
                    >
                      <Typography
                        style={{
                          width: "100px",
                          fontSize: "16px",
                          marginRight: "10px",
                          color: "#5E6060",
                        }}
                      >
                        {response}
                      </Typography>

                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {responseDictionary[response]?.length > 0
                          ? responseDictionary[response].map(
                              (synonym, synonymIndex) => (
                                <Chip
                                  key={synonymIndex}
                                  label={synonym}
                                  onDelete={() =>
                                    handleRemoveSynonym(response, synonymIndex)
                                  }
                                  deleteIcon={<ClearIcon />}
                                  style={{
                                    margin: "4px",
                                    color: "#5E6060",
                                    backgroundColor: "#F1F1F1",
                                    fontSize: "16px",
                                    borderRadius: "1.5px",
                                  }}
                                />
                              )
                            )
                          : !isAddingSynonym && (
                              <Chip
                                label="Add synonym"
                                onClick={() =>
                                  handleToggleAddingSynonym(response)
                                }
                                style={{
                                  margin: "4px",
                                  cursor: "pointer",
                                  color: "#5E6060",
                                  backgroundColor: "#F1F1F1",
                                  fontSize: "14px",
                                  borderRadius: "1.5px",
                                }}
                              />
                            )}
                        {isAddingSynonym && addingSynonymFor === response && (
                          <>
                            <TextField
                              value={newSynonym}
                              onChange={(e) => setNewSynonym(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddSynonym(response);
                                  handleToggleAddingSynonym(response); // Hide the TextField after adding a synonym
                                }
                              }}
                              placeholder="Enter synonym"
                              style={{
                                width: "150px",
                                fontSize: "12px",
                                margin: "4px",
                              }}
                              inputProps={{
                                style: { height: "30px", padding: "6px 12px" },
                              }}
                            />
                          </>
                        )}
                        <IconButton
                          onClick={() => handleToggleAddingSynonym(response)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          )}
      </Collapse>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
            backgroundColor: "#EEEDED",
          }}
        >
          <IconButton
            onClick={() => setCollapsed(!collapsed)}
            sx={{
              "&:hover": { backgroundColor: "transparent" }, // Remove hover opacity
            }}
          >
            {collapsed ? (
              <>
                <Typography sx={{ color: "#2D6A4F", paddingX: 1 }}>
                  View Properties
                </Typography>
                <ArrowDropDownIcon />
              </>
            ) : (
              <>
                <Typography sx={{ paddingX: 1, color: "#2D6A4F" }}>
                  Close
                </Typography>
                <ArrowDropUpIcon />
              </>
            )}
          </IconButton>
        </Box>
        <IconButton
          onClick={handleDelete}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Typography sx={{ color: "#2D6A4F", paddingX: 2 }}>Delete</Typography>
        </IconButton>
      </Box>
    </Grid>
  );
}
