import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Modal, ThemeProvider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import LocationDropDown from "./supervisorLocation";
import { useDispatch, useSelector } from "react-redux";
import { getDistrictData, getStatesData } from "../../features/location/locationSlice";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import {
  getPartnerData,
  getPartnerLocation,
} from "../../features/partner/partnerAPIs";
import SnackBars from "../reusableComponents/SnackBar";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import { postSupervisorData } from "../../features/supervisorAccess/supervisorApi";
import { getDistrictList, getTalukaList } from "../../utils";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
  paddingTop: 2,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function AddSupervisor({
  openAddModal,
  handleClose,
  setSupervisorSnackBarMessage = () => {},
  setReload = () => {},
  setOpenSuccessBox = () => {},
  setSuccessMessage = () => {},
  setOpenSuccessBoxType = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [supervisorName, setSupervisorName] = useState("");
  const [supervisorNumber, setSupervisorNumber] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [supervisorRole, setSupervisorRole] = useState("");
  const [supervisorPartner, setSupervisorPartner] = useState(null);
  const [supervisorStatus, setSupervisorStatus] = useState("");
  const [addSupervisorSnackBar, setAddSupervisorSnackBar] = useState(false);
  const [addSupervisorSnackBarMessage, setAddSupervisorSnackBarMessage] =
    useState("");
  const [addSupervisorSnackBarType, setAddSupervisorSnackBarType] =
    useState("success");
  const basicError = {
    supervisorName: "",
    supervisorEmail: "",
    supervisorNumber: "",
    supervisorRole: "",
    supervisorPartner: "",
    supervisorStatus: "",
  };

  const [errors, setErrors] = useState(basicError);

  const supervisorStatusList = ["Active", "Inactive"];
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerStates, setPartnerStates] = useState([]);
  const [partnerDistricts, setPartnerDistricts] = useState([]);
  const [partnerTalukas, setPartnerTalukas] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [partnerDataResponse, setPartnerDataResponse] = useState([]);
  const [collapseHeader, setCollapseHeader] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  useEffect(() => {
    dispatch(getStatesData());
  }, []);

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await getPartnerData({ fields: ["name", "id"] });
      if (loggedInUser.details.partner !== null) {
        const partnerId = loggedInUser.details.partner.id;

        // Filter the partner data from response.data["data"]
        const filteredData = response.data["data"].filter(
          (item) => item.id === partnerId
        );

        // Now filteredData contains the partner data if it exists
        setPartnerDataResponse(filteredData);
      } else setPartnerDataResponse(response.data["data"]);
      setLoading(false);
    } catch {
      setAddSupervisorSnackBarType("error");
      setAddSupervisorSnackBar(true);
      setAddSupervisorSnackBarMessage("Error while getting partner list"); // Use error.response.data here
      setLoading(false);
    }
  }, []);

  const getRoleList = (userRole) => {
    const roleList = [];
  
    if (userRole === "State Head" || userRole === "Admin" || userRole === "Partner Head") {
      roleList.push("State Head", "District Head", "Taluka Head");
    } else if (userRole === "District Head") {
      roleList.push("District Head", "Taluka Head");
    } else if (userRole === "Taluka Head") {
      roleList.push("Taluka Head");
    }
  
    return roleList;
  };

  const fillPartnerLocation = async ({ id, type }) => {
    setLoading(true);
    try {
      const response = await getPartnerLocation({
        id: id,
        location_type: type,
      });
      setLoading(false);
      return response.data["data"];
    } catch {
      setAddSupervisorSnackBarType("error");
      setAddSupervisorSnackBar(true);
      setAddSupervisorSnackBarMessage("Error while getting partner location"); // Use error.response.data here
      setLoading(false);
      return null;
    }
  };

  const validateFormData = () => {
    let supervisorNameError="";
    //let supervisorStatusError="";
    let supervisorEmailError="";
    let supervisorMobileError="";
    let supervisorRoleError="";
    let supervisorPartnerError="";
    if (supervisorName.trim() === "") {
      supervisorNameError = "Form Name can't be empty"
    } 
    // if (supervisorStatus === "") {
    //   supervisorStatusError = "Form Status can't be empty"
    // }
    if (supervisorEmail === "") {
      supervisorEmailError = "Email can't be empty"
    }
    if (supervisorNumber === "") {
      supervisorMobileError = "Phone Number can't be empty"
    }
    if (supervisorRole === "") {
      supervisorRoleError = "Role can't be empty"
    }
    if (supervisorPartner === null) {
      supervisorPartnerError = "Partner can't be empty"
    }
    setErrors({...errors, supervisorName: supervisorNameError, supervisorEmail: supervisorEmailError, supervisorNumber: supervisorMobileError, supervisorRole: supervisorRoleError, supervisorPartner: supervisorPartnerError});
  }

  const handleChangeSupervisorName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
      if (value.trim() === "") {
        setErrors({ ...errors, supervisorName: "Supervisor Name can't be empty" });
        value = "";
      } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
        setErrors({ ...errors, supervisorName: "Special characters are not allowed in Supervisor Name" });
        value = "";
      } else {
        setErrors({ ...errors, supervisorName: "" });
      }
    setSupervisorName(value);
  };

  const handleChangeSupervisorEmail = (value) => {
    // var mailformat = /(.+)@(.+){2,}\.(.+){2,}/;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (value.match(mailformat)) {
      setErrors({ ...errors, supervisorEmail: "" });
    }else if(value ==="") {
      setErrors({ ...errors, supervisorEmail: "Email can't be empty" });
    }else {
      setErrors({ ...errors, supervisorEmail: "Invalid Email " });
    }
    setSupervisorEmail(value);
  };
  const handleChangeSupervisorStatus = (event) => {
    setSupervisorStatus(event.target.value);
    setErrors({ ...errors, supervisorStatus: "" });
  };
  const handleChangeSupervisorRole = (event) => {
    if (event.target.value !== (null || undefined)) {
      setSelectedStateOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setSupervisorRole(event.target.value);
      setSupervisorPartner(null);
    }
    setErrors({ ...errors, supervisorRole: "" });
  };

  const setPartnerDistrictsForStateHead = (partnerData) => {
    async function fetchData() {
      try {
        const partnerLocations = await fillPartnerLocation({
          id: partnerData.id,
          type: "state"
        });
    
        let filteredStates = [];
    
        if (partnerLocations !== null) {
          filteredStates = partnerLocations.filter((state) =>
            loggedInUser.details.partner.location_ids.includes(state.id)
          );
        }
    
        const districtPromises = filteredStates.map((state) =>
          getDistrictList(state.id)
        );
    
        const partnerDistricts = await fillPartnerLocation({
          id: partnerData.id,
          type: "district"
        });
    
        const districtLists = await Promise.all(districtPromises);
    
        const allDistricts = [].concat(...districtLists[0].data);
    
        const commonDistricts = allDistricts.filter((district) => {
          return partnerDistricts.some(
            (partnerDistrict) => partnerDistrict.id === district.id
          );
        });
    
        commonDistricts.unshift({ id: "all", name: "All" });
        setPartnerDistricts(commonDistricts);
    
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    fetchData();
  }

  const setPartnerDistrictsForDistrictHead = (partnerData) => {
    async function fetchData() {
      try {
        const partnerDistricts = await fillPartnerLocation({
          id: partnerData.id,
          type: "district"
        });
    
        let filteredDistricts = [];
    
        if (partnerDistricts !== null) {
          filteredDistricts = partnerDistricts.filter((district) =>
            loggedInUser.details.partner.location_ids.includes(district.id)
          );
        }
    
        filteredDistricts.unshift({ id: "all", name: "All" });
        setPartnerDistricts(filteredDistricts);
    
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    fetchData();
  }

  const setPartnerTalukasForStateHead = (partnerData) => {
    async function fetchData() {
      try {
        // Get states
        const partnerLocationsState = await fillPartnerLocation({
          id: partnerData.id,
          type: "state"
        });
    
        let filteredStates = [];
    
        if (partnerLocationsState !== null) {
          filteredStates = partnerLocationsState.filter((state) =>
            loggedInUser.details.partner.location_ids.includes(state.id)
          );
        }
    
        // Get districts for the selected states
        const districtPromises = filteredStates.map((state) =>
          getDistrictList(state.id)
        );
    
        const partnerDistricts = await fillPartnerLocation({
          id: partnerData.id,
          type: "district"
        });
    
        const districtLists = await Promise.all(districtPromises);
    
        const allDistricts = [].concat(...districtLists[0].data);
    
        const commonDistricts = allDistricts.filter((district) => {
          return partnerDistricts.some(
            (partnerDistrict) => partnerDistrict.id === district.id
          );
        });
    
        // Filter talukas based on the districts
        const talukaPromises = commonDistricts.map((district) =>
          getTalukaList(district.id)
        );
    
        const talukaLists = await Promise.all(talukaPromises);
    
        const allTalukas = [].concat(...talukaLists[0].data);

        const partnerTalukas = await fillPartnerLocation({
          id: partnerData.id,
          type: "taluka"
        });
    
        // Now you have all talukas, filter based on partner taluka ids
        const commonTalukas = allTalukas.filter((taluka) => {
          return partnerTalukas.some(
            (partnerTaluka) => partnerTaluka.id === taluka.id
          );
        });
    
        // setTalukaList(partnerTalukas);
       // partnerTalukas.unshift({ id: "all", name: "All" });
        setPartnerTalukas(commonTalukas);
    
       // console.log(talukaLists);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    fetchData();
  }

  const setPartnerTalukasForDistrictHead = (partnerData) => {
    async function fetchData() {
      try {
        const partnerLocations = await fillPartnerLocation({
          id: partnerData.id,
          type: "district"
        });
    
        let filteredDistricts = [];
    
        if (partnerLocations !== null) {
          filteredDistricts = partnerLocations.filter((district) =>
            loggedInUser.details.partner.location_ids.includes(district.id)
          );
        }
    
        const talukaPromises = filteredDistricts.map((district) =>
          getTalukaList(district.id)
        );
    
        const partnerTalukas = await fillPartnerLocation({
          id: partnerData.id,
          type: "taluka"
        });
    
        const talukaLists = await Promise.all(talukaPromises);
    
        const allTalukas = [].concat(...talukaLists[0].data);
    
        const commonTalukas = allTalukas.filter((taluka) => {
          return partnerTalukas.some(
            (partnerTaluka) => partnerTaluka.id === taluka.id
          );
        });
    
        //commonTalukas.unshift({ id: "all", name: "All" });
        setPartnerTalukas(commonTalukas);
    
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    fetchData();
  }

  const setPartnerTalukasForTalukaHead = (partnerData) => {
    async function fetchData() {
      try {
        const partnerTalukas = await fillPartnerLocation({
          id: partnerData.id,
          type: "taluka"
        });
    
        let filteredTalukas = [];
    
        if (partnerTalukas !== null) {
          filteredTalukas = partnerTalukas.filter((taluka) =>
            loggedInUser.details.partner.location_ids.includes(taluka.id)
          );
        }
    
        //filteredTalukas.unshift({ id: "all", name: "All" });
        setPartnerTalukas(filteredTalukas);
    
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
    fetchData();
  }

  const handleChangeSupervisorPartner = (value) => {
    setSelectedStateOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    if(value!==null){
    if (supervisorRole === "State Head") {
      fillPartnerLocation({ id: value.id, type: "state" }).then(
        (partnerLocations) => {
          if (partnerLocations !== null) {
            if(loggedInUser.details.partner !== null && (loggedInUser.role!=="Admin" && loggedInUser.role!=="Partner Head")){
              const filteredStates = partnerLocations.filter((state) =>
              loggedInUser.details.partner.location_ids.includes(state.id));
                setPartnerStates(filteredStates);
            }
            else{
              setPartnerStates(partnerLocations);
            }
            
          }
        }
      );
    } else if (supervisorRole === "District Head") {

      if (loggedInUser.role == "State Head") {
        setPartnerDistrictsForStateHead(value);
      }
      else if (loggedInUser.role == "District Head") {
        setPartnerDistrictsForDistrictHead(value);
      }
      else{
        fillPartnerLocation({ id: value.id, type: "district" }).then(
          (partnerLocations) => {
            if (partnerLocations !== null) {
              partnerLocations.unshift({ id: "all", name: "All" });
              setPartnerDistricts(partnerLocations)
            }
          })
      }

    } else if (supervisorRole === "Taluka Head") {
      if (loggedInUser.role == "State Head") {
        setPartnerTalukasForStateHead(value);
      }
      else if (loggedInUser.role == "District Head") {
        setPartnerTalukasForDistrictHead(value);
      }
      else if (loggedInUser.role == "Taluka Head") {
        setPartnerTalukasForTalukaHead(value);
      }
      else{
        fillPartnerLocation({ id: value.id, type: "taluka" }).then(
          (partnerLocations) => {
            if (partnerLocations !== null) {
              partnerLocations.unshift({ id: "all", name: "All" });
              setPartnerTalukas(partnerLocations)
            }
          })
      }
  
    }
  }
    setErrors({ ...errors, supervisorPartner: "" });
  };

  const handleChangeSupervisorNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, supervisorNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        supervisorNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, supervisorNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, supervisorNumber: "" });
    }
    setSupervisorNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setSupervisorName("");
    setSupervisorNumber("");
    setSupervisorEmail("");
    setSupervisorRole("");
    //setSupervisorStatus("");
    setAddSupervisorSnackBar(false);
    setAddSupervisorSnackBarType("success");
    setAddSupervisorSnackBarMessage("");
    setSupervisorSnackBarMessage("");
    setSelectedStateOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = [];
    if (supervisorRole === "State Head") {
      selectedStateOptions.forEach((state) => locations.push(state.id));
    }
    if (supervisorRole === "District Head") {
      selectedDistrictOptions.forEach((district) =>{
        if (district.id !== "all") {
        locations.push(district.id);
      }}
      );
    }
    if (supervisorRole === "Taluka Head") {
      selectedTalukaOptions.forEach((taluka) =>  {
        if (taluka.id !== "all") {
        locations.push(taluka.id);
      }});
    }
    return locations;
  };

  const handleSaveData = async () => {
    var locations = setLocationForApi();
    if (
      errors.supervisorName === "" &&
      errors.supervisorEmail === "" &&
      errors.supervisorNumber === "" &&
      supervisorName !== "" &&
      supervisorNumber !== "" &&
      supervisorEmail !== "" &&
      //supervisorStatus !== "" &&
      supervisorPartner !== "" &&
      supervisorRole !== "" &&
      locations.length > 0
    ) {
      setAddSupervisorSnackBar(false);
      setAddSupervisorSnackBarType("success");
      setAddSupervisorSnackBarMessage("");
      //var isActive = supervisorStatus === "Active" ? true : false;
      var params = {
        name: supervisorName,
        email: supervisorEmail,
        phone_number: supervisorNumber,
        location_ids: locations,
        //is_active: isActive,
        group: supervisorRole.toLowerCase().replace(/\s+/g, "_"),
        location_type: supervisorRole.split(" ")[0].toLowerCase(),
        partner_id: supervisorPartner.id,
      };
      try {
        setLoading(true);
        const response = await postSupervisorData(params);
        // setSupervisorSnackBarMessage(response.data["message"])
        setReload(true);
        setLoading(false);
        handleClose();
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success');
      } catch (error) {
        setOpenSuccessBox(true);
        setSuccessMessage(error.response.data["message"]);
        setOpenSuccessBoxType('error')
        setLoading(false);
        console.error("API Error:", error.response.data["message"]); // Log the error message
      }
    } else {
      validateFormData();
      setOpenSuccessBox(true);
      setSuccessMessage("Please fill all the required fields");
      setOpenSuccessBoxType('error');
    }
  };

  return (
    <Modal open={openAddModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 4,
            }}
          >
            <Grid item xs={7}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Add Supervisor
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end"}}>
              <Box sx={{ display: "flex", alignItems: "start", cursor: "pointer" }} onClick={() => setCollapseHeader(prev => !prev)}>
                <Typography variant="h6" >
                  {collapseHeader ? "Supervisor Details" : "Collapse"}
                </Typography>
                {collapseHeader ? <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }}/> : <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }}/>}
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              sx={{
                width: "90%",
                p: 1
                // margin: "auto"
              }}
            >
              {!collapseHeader && <><Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  Supervisor Name
                </Typography>
                <BasicTextfield
                  label="Enter Supervisor Name"
                  is_phone={false}
                  handleChange={handleChangeSupervisorName}
                  is_disabled={false}
                  index={1}
                  error={errors.supervisorName !== ""}
                  helperText={errors.supervisorName}
                  required={true}
                  value={supervisorName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  Supervisor Mobile Number
                </Typography>
                <BasicTextfield
                  label="Enter Supervisor Mobile Number"
                  is_phone={true}
                  handleChange={handleChangeSupervisorNumber}
                  is_disabled={false}
                  index={3}
                  error={errors.supervisorNumber !== ""}
                  helperText={errors.supervisorNumber}
                  required={true}
                  value={supervisorNumber}
                  number={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  Supervisor Email Id
                </Typography>
                <BasicTextfield
                  label="Enter Supervisor Email Id"
                  is_phone={false}
                  handleChange={handleChangeSupervisorEmail}
                  is_disabled={false}
                  index={4}
                  error={errors.supervisorEmail !== ""}
                  helperText={errors.supervisorEmail}
                  required={true}
                  value={supervisorEmail}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  Supervisor Status
                </Typography>
                <BasicDropDown
                  text="Select Supervisor Status"
                  value={supervisorStatus}
                  handleChange={handleChangeSupervisorStatus}
                  menuList={supervisorStatusList}
                  required={true}
                  error={errors.supervisorStatus !== ""}
                  errorText={errors.supervisorStatus}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Role</Typography>
                <BasicDropDown
                  text="Select Role"
                  value={supervisorRole}
                  handleChange={handleChangeSupervisorRole}
                  menuList={getRoleList(loggedInUser.role)}
                  required={true}
                  error={errors.supervisorRole !== ""}
                  errorText={errors.supervisorRole}
                />
              </Grid></>}
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    marginBottom: 1,
                    color:
                      (supervisorRole === "Government" ||
                        supervisorRole === "") &&
                      "#ACADAD",
                  }}
                >
                  Partner
                </Typography>
                <AutoCompleteDropdown
                  width="auto"
                  menuList={partnerDataResponse}
                  selectedOptions={supervisorPartner}
                  setSelectedOptions={setSupervisorPartner}
                  disable={
                    partnerDataResponse.length === 0 ||
                    supervisorRole === "" ||
                    supervisorRole === "Government"
                  }
                  label="Select Partner"
                  onHighlightChange={handleChangeSupervisorPartner}
                  required={true}
                  error={errors.supervisorPartner !== ""}
                  errorText={errors.supervisorPartner}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {supervisorRole !== "" &&
                supervisorPartner !== null &&
                supervisorRole !== "Government" && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Location{" "}
                      </Typography>
                    </Grid>
                    <LocationDropDown
                      role={supervisorRole}
                      menuList={
                        supervisorRole === "State Head"
                          ? partnerStates
                          : supervisorRole === "District Head"
                          ? partnerDistricts
                          : supervisorRole === "Taluka Head"
                          ? partnerTalukas
                          : []
                      }
                      selectedStateOptions={selectedStateOptions}
                      setSelectedStateOptions={setSelectedStateOptions}
                      selectedDistrictOptions={selectedDistrictOptions}
                      setSelectedDistrictOptions={setSelectedDistrictOptions}
                      selectedTalukaOptions={selectedTalukaOptions}
                      setSelectedTalukaOptions={setSelectedTalukaOptions}
                    />
                  </React.Fragment>
                )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            spacing={0}
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              direction: "row",
              justifyContent: "center",
              backgroundColor: "white",
              paddingBottom: 4,
              paddingBottom: 2
            }}
          >
            <Grid
              item
              xs={12}
              sm={2.5}
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <CancelButtonStyle
                variant="contained"
                name="cancel"
                onClick={handleClose}
              >
                Cancel
              </CancelButtonStyle>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <ButtonStyle
                variant="contained"
                name="add"
                startIcon={<CheckIcon size="large" />}
                onClick={handleSaveData}
              >
                Save
              </ButtonStyle>
            </Grid>
          </Grid>
        </ThemeProvider>
        <BackDropLoader loading={loading} />
        {addSupervisorSnackBar && (
          <SnackBars
            open={addSupervisorSnackBar}
            handleClose={() => setAddSupervisorSnackBar(false)}
            severity={addSupervisorSnackBarType}
            message={addSupervisorSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
      </Grid>
    </Modal>
  );
}
