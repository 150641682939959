import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { getUsersData, resetGetUsers, getExportedData } from "./appUserSlice";
import AppUserFilterPopOver from "../../components/appUsers/appUserFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";
import useMediaQuery from "@mui/material/useMediaQuery";
import { updateArray, searchType } from "../../utils";
import { basicTheme } from "../../themes/customTheme";
import AddUser from "../../components/appUsers/AddUserModal";
import EditFarmer from "../../components/appUsers/EditFarmerModal";
import EditEw from "../../components/appUsers/EditEwModal";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import DeleteAppUser from "../../components/appUsers/deleteAppUserModal";
import DeleteEw from "../../components/appUsers/deleteEwModal";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BulkUploadUsers from "../../components/appUsers/bulkUploadUsers";
import { getFarmerData, getEwData } from "./appUsersApi";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky: true,
  },
  {
    key: "name",
    label: "Farmer",
    type: "name_number_location",
    sort: true,
  },
  // {
  //   key: "is_active",
  //   label: "Status",
  //   type: "boolean",
  // },
  {
    key: "gender",
    label: "Gender",
    type: "text",
  },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true
  },
  {
    key: "project",
    label: "Project",
    type: "text",
    capital:true
  },
  {
    key: "age",
    label: "Age",
    type: "text",
  },
  {
    key: "extension_worker",
    label: "Extension Worker",
    type: "name_number",
  },
];

const initialEwHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sticky: true,
  },
  {
    key: "name",
    label: "Extension Worker",
    type: "name_number_location",
    sort: true,
    capital: true
  },
  {
    key: "state_wise_location",
    label: "Location",
    type: "location",
  },
  // {
  //   key: "is_active",
  //   label: "Status",
  //   type: "boolean",
  // },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true
  },
];

const modifiedLocationHeadCell = [
  {
    key: "district",
    label: "District",
    type: "text",
  },
];

const listButtonMenu = [
  { name: "Partner Farmer", id: 1, user_type: "paf" },
  { name: "WIAI FARMER", id: 3, user_type: "puf" },
  { name: "Extension Worker", id: 2, user_type: "ew" },
];

export default function AppUsers() {
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const dispatch = useDispatch();
  const { type } = useParams();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [isUpload, setIsUpload] = useState(null);
  const [userSnackBarType, setUserSnackBarType] = useState("success");
  const [userSnackBarMessage, setUserSnackBarMessage] = useState("");
  const [showUserSnackBar, setUserShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([
    { type: "user_type", value: ["paf"] },
    { type: "program_id", value: [] },
  ]);
  const [apiCall, setApiCall] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [userType, setUserType] = useState(type);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [selectedPartnersOptions, setSelectedPartnersOptions] = useState(loggedInUser.details.partner!==null ? [loggedInUser.details.partner]:[]);
  const [loading, setLoading] = useState(true);
  
  const [totalUsersData, setTotalUsersData] = useState(-1);
  const [usersDataResponse, setUsersDataResponse] = useState([]);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessBoxType,setOpenSuccessBoxType] = useState("success");
  const [deleteMultipleUserData, setDeleteMultipleUserData] = useState({ isOpen: false, users: {} })
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const exportedData = useSelector(
    (state) => state.usersSliceReducer.exportedData
  );

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  useEffect(() => {
    setUsersDataResponse([]);
    var roleArray = [];
    roleArray.push(type);
    setUserType(type);
    var newRoleArray = updateArray(filters, "user_type", roleArray);
    setFilters(newRoleArray);
    updateArray(filters, "state", [], true, setFilters);
    updateArray(filters, "district", [], true, setFilters);
    updateArray(filters, "taluka", [], true, setFilters);
    setSelectedStatesOptions([]);
    setSelectedDistrictOptions([]);
    setSelectedTalukaOptions([]);
    setSelectedPartnersOptions(loggedInUser.details.partner!==null ? [loggedInUser.details.partner]:[]);
    setPage(0);
    setOffset(0);
    setSearchBarData("");
    setSeachBarText("");
  }, [type]);

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  const handleChangeFilter = (selectedOptionsList) => {
    var tempFilter = [...filters];
    selectedOptionsList.forEach((options) => {
      var type = options.type;
      var selectedOptions = options.value;
      if (selectedOptions.length > 0) {
        var selectedIds = [];
        selectedOptions.forEach((options) => selectedIds.push(options.id));
        if (!searchType(type, filters)) {
          tempFilter.push({ type: type, value: selectedIds });
        } else {
          const objWithIdIndex = tempFilter.findIndex(
            (obj) => obj.type === type
          );
          tempFilter[objWithIdIndex].value = selectedIds;
        }
      } else if (selectedOptions.length === 0 && searchType(type, filters)) {
        const objWithIdIndex = tempFilter.findIndex((obj) => obj.type === type);
        tempFilter.splice(objWithIdIndex, 1);
      }
    });
    setFilters(tempFilter);
  };

  useEffect(() => {
    if (exportData) {
      setLoading(true);
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          search: searchBarData,
          filters: JSON.stringify(filters),
          offset: 0,
          limit: totalUsersData,
        })
      ).then((res) => {
        setLoading(false);
        setExportData(false);
        if (res.error !== undefined) {
          setUserSnackBarMessage("Something went wrong!");
          setUserSnackBarType("error");
          setUserShowSnackBar(true);
        } else {
          setExportedFileName("users_data" + searchBarData + ".csv");
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
      setFilters((fil) =>
        fil.filter((value) => Object.keys(value).length !== 0)
      );
      var tempSelectedOptions = [
        { type: "program_id", value: selectedPartnersOptions },
        { type: "state", value: selectedStatesOptions },
        { type: "district", value: selectedDistrictOptions },
        { type: "taluka", value: selectedTalukaOptions },
      ];
      handleChangeFilter(tempSelectedOptions);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [searchBarData, offset, sortingType, sortBy, userType, filters]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const params = {
            search: searchBarData,
            offset: offset,
            limit: rowsPerPage,
            sort_key: sortBy,
            sort_by: sortingType,
          };
          if (selectedStatesOptions !== [])
            params["state_ids"] = selectedStatesOptions.map((item) => {
              return item.id;
            });
          if (selectedDistrictOptions !== [])
            params["district_ids"] = selectedDistrictOptions.map((item) => {
              return item.id;
            });
          if (selectedTalukaOptions !== [])
            params["taluka_ids"] = selectedTalukaOptions.map((item) => {
              return item.id;
            });
          if (selectedPartnersOptions !== [])
            params["partner_ids"] = selectedPartnersOptions.map((item) => {
              return item.id;
            });

          setLoading(true);
          let response = [];
          if (userType === "paf") response = await getFarmerData(params);
          else if (userType === "ew") response = await getEwData(params);

          if (
            response != null &&
            response != undefined &&
            response.data !== null &&
            response.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined
          ) {
            setTotalUsersData(response.data.count);
            setUsersDataResponse(response.data.data);
            const userDataToDelete = response?.data?.data?.reduce((acc, user) => {
              acc[user.id] = {
                name: user?.name,
                id: user.id,
                isSelected: false
              };
              return acc;
            }, {});
            setDeleteMultipleUserData({ isOpen: false, users: userDataToDelete});
          } else {
            setUserSnackBarMessage("Something went wrong on sever!");
            setUserSnackBarType("error");
            setUserShowSnackBar(true);
          }
        } catch (error) {
          setUserSnackBarType("error");
          setUserSnackBarMessage(error.message);
          setUserShowSnackBar(true);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };

      fetchData();
    }
  }, [reload, apiCall]);

  useEffect(() => {
    if (userSnackBarMessage !== "" && userSnackBarMessage !== undefined)
      setUserShowSnackBar(true);
  }, [userSnackBarMessage]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Farmer"
            deleteButton={deleteMultipleUserData.isOpen}
            setAddOpen={setopenAddModal}
            deleteFunc={setOpenDeleteModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Name and Number"
            addButton={userType === "paf" ? true : false}
            isImport={userType === "paf" ? true : false}
            isExport={false}
            setIsUpload={setIsUpload}
            loading={loading}
            showFilter={true}
            filterSelected={
              // selectedPartnersOptions.length !== 0 ||
              selectedStatesOptions.length !== 0
            }
          />
          {openFilterPopOver && (
            <AppUserFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              selectedPartnersOptions={selectedPartnersOptions}
              setSelectedPartnersOptions={setSelectedPartnersOptions}
              selectedStatesOptions={selectedStatesOptions}
              setSelectedStatesOptions={setSelectedStatesOptions}
              selectedDistrictOptions={selectedDistrictOptions}
              setSelectedDistrictOptions={setSelectedDistrictOptions}
              selectedTalukaOptions={selectedTalukaOptions}
              setSelectedTalukaOptions={setSelectedTalukaOptions}
              filters={filters}
              setFilters={setFilters}
              userType={userType}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {!loading && (
            <DesktopTable
              headCells={
                userType === "ew" ? initialEwHeadCells : initialHeadCells
              }
              tableResponse={usersDataResponse}
              key={usersDataResponse}
              totalCount={totalUsersData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              actionOptions={['edit', 'multiDelete']}
              sortBy={sortBy}
              setOpenEditModal={setOpenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              setOpenLocationSubModal={setOpenLocationSubModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              editCopyMultiDelete={true}
              deleteMultipleUserData={deleteMultipleUserData}
              setDeleteMultipleUserData={setDeleteMultipleUserData}
            />
          )}
        </Grid>
        {showUserSnackBar && (
          <SnackBars
            open={showUserSnackBar}
            handleClose={() => setUserShowSnackBar(false)}
            severity={userSnackBarType}
            message={userSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {openAddModal && (
          <AddUser
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openSuccessBox && (
          <SuccessErrorDialogBox
            open={openSuccessBox}
            handleClose={() => setOpenSuccessBox(false)}
            text={successMessage}
            type={openSuccessBoxType}
          />
        )}
        {isUpload && (
          <BulkUploadUsers
            isUpload={isUpload}
            handleClose={() => setIsUpload(null)}
            setSnackBarMessage={setUserSnackBarMessage}
            setSnackBarType={setUserSnackBarType}
          />
        )}
        {openEditModal && userType !== "ew" && (
          <EditFarmer
            openModal={openEditModal}
            handleClose={() => setOpenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}

        {openEditModal && userType === "ew" && (
          <EditEw
            openModal={openEditModal}
            handleClose={() => setOpenEditModal(false)}
            data={editData}
            setEditData={setEditData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
          />
        )}
        {openLocationSubModal && (
          <StateWiseLocationModal
            openModal={openLocationSubModal}
            handleClose={() => setOpenLocationSubModal(false)}
            data={editData.state_wise_location}
            modifiedLocationHeadCell={modifiedLocationHeadCell}
            modifiedHeadCell={true}
          />
        )}
        {openDeleteModal && userType !== "ew" && (
          <DeleteAppUser
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteMultipleUserData.isOpen 
              ? deleteMultipleUserData.users 
              : { 
                  [deleteData.id]: {
                    name: deleteData?.name,
                    id: deleteData.id,
                    isSelected: true
                  }
                }
            }
            // deleteData={deleteData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setSnackBarType={setUserSnackBarType}
            setReload={setReload}
          />
        )}
        {openDeleteModal && userType === "ew" && (
          <DeleteEw
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteMultipleUserData.isOpen 
              ? deleteMultipleUserData.users 
              : { 
                  [deleteData.id]: {
                    name: deleteData?.name,
                    id: deleteData.id,
                    isSelected: true
                  }
                }
            }
            // deleteData={deleteData}
            setUserSnackBarMessage={setUserSnackBarMessage}
            setSnackBarType={setUserSnackBarType}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this Extension Worker"
            text="Farmers are mapped with this extension Worker.Please unmap them and then
            try deleting again!"
          />
        )}
        <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        />
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
