import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginPage from "../../styles/images/LoginPage.jpg";
import AiCollectLogo from "../../styles/images/AgriAI_Logo.jpg";
import logo from "../../styles/images/icons/logo.png";
import { makeStyles } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SnackBars from "../../components/reusableComponents/SnackBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { login, getLoggedInUser,resetLogin } from "./AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import { getUsersPermission } from "../../features/appbar/AppBarSlice";
import firebaseConfig from "../../components/reusableComponents/firebase";
import { logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAnalytics,setUserProperties } from "firebase/analytics";

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.only("xl")]: {
      zoom: "9%",
      marginTop: "30px",
    },
    [theme.breakpoints.only("lg")]: {
      zoom: "8%",
      marginTop: "24px",
    },
    [theme.breakpoints.only("md")]: {
      zoom: "7%",
      marginTop: "20px",
    },
    [theme.breakpoints.down("md")]: {
      zoom: "5%",
      marginTop: "16px",
    },
  },
  textfield: {
    [`& fieldset`]: {
      borderRadius: 15,
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiInputBase-input ": {
      background: "#FFFFFF",
    },
  },
  input: {
    color: "#ACADAD",
    background: "#FFFFFF",
  },
  resetPasswordMessage: {
    height: "26px",
    width: "409px",
    backgroundColor: "#b80713",
    textAlign: "center",
  },
  resetPasswordMessageText: {
    fontSize: "14px",
    lineHeight: "1.33",
    color: "white",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
});

const theme = createTheme();

export default function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const resetPasswordSuccess = false;
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const ROLES = {
    "Partner Head":"partner_head" ,
    "State Head":"state_head",
    "District Head":"district_head" ,
    "Taluka Head":"taluka_head" ,
    "Admin": "admin"
  }

  const modulePermission = {
    "Form Module": "/form_management",
    "Project Module": "/projects",
    "Partner Onboarding Module": "/partners",
    "Supervisor Onboarding Module": "/supervisor_access/state_head",
    "User Onboarding Module": "/app_users/paf",
    "Location Module": "/location",
    "Roles And Permission Module": "/roles_permission",
  };
  

  const basicError = {
    email: "",
    password: "",
  };
  const [errors, setErrors] = useState(basicError);

  const dispatch = useDispatch();

  const loginDataResponse = useSelector(
    (state) => state.authenticationSliceReducer.loginData
  );

  const loginDataErrorMessage = useSelector(
    (state) => state.authenticationSliceReducer.loginDataErrorMessage
  );

  const loginDataIsSuccess = useSelector(
    (state) => state.authenticationSliceReducer.loginDataIsSuccess
  );

  const loginDataLoading = useSelector(
    (state) => state.authenticationSliceReducer.loginDataLoading
  );

  const userPermission = useSelector(
    (state) => state.appBarSliceReducer.userPermissionData.data
  );

  useEffect(()=>{
    setShowSnackBar(false)
    dispatch(resetLogin())
  },[])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLogin()
    }
  }

  const handleLogin = () => {
    setSubmitted(true);
    setApiCall(false);
    if (username && password && errors.email === "" && errors.password === "") {
      dispatch(
        login({
          username: username.toLowerCase(),
          password: password,
        })
      ).then(() => setApiCall(true));
    }
  };

  useEffect(() => {
    if (loginDataErrorMessage === undefined) {
      setShowSnackBar(true);
      setSnackBarMessage("Something went wrong on server side ");
      setSnackBarType("error");
    }
  }, [apiCall]);

  useEffect(() => {
    if (
      loginDataIsSuccess &&
      loginDataResponse != null &&
      loginDataResponse.token != null &&
      loginDataResponse.token !== undefined &&
      loginDataResponse.token !== ""
    ) {
      window.localStorage.setItem(
        "loggedInUser",
        JSON.stringify(loginDataResponse)
      );
      dispatch(getLoggedInUser(loginDataResponse));
      dispatch(
        getUsersPermission({
          filters: JSON.stringify([
            { type: "role", value: [ROLES[loginDataResponse.role]] },
            { type: "permission", value: ["view"] },
          ]),
        })
      )
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      logEvent(analytics,'login')
      setUserProperties(analytics, { WEB_ENV: process.env.REACT_APP_ENVIRONMENT });
      // navigate("/form_management");
    } else {
      navigate("/", true);
    }
  }, [loginDataIsSuccess, loginDataResponse]);

  useEffect(()=>{
    if (Object.keys(userPermission).length !== 0) {
      for (const module in modulePermission) {
        if (userPermission[module]!==undefined) {
          navigate(modulePermission[module]);
          return;
        }
      }
      navigate('/form_management');
    }
  },[userPermission])


  useEffect(() => {
    
    if (
      loginDataErrorMessage !== undefined &&
      loginDataErrorMessage.message !== undefined && loginDataErrorMessage.message!=="")
    {
      setShowSnackBar(true);
      setSnackBarMessage(loginDataErrorMessage.message);
      setSnackBarType("error");
    } else if (!loginDataIsSuccess && loginDataErrorMessage === undefined) {
      setShowSnackBar(true);
      setSnackBarMessage("Something went wrong on server side ");
      setSnackBarType("error");
    }
  }, [loginDataErrorMessage, loginDataIsSuccess]);

  const handleChangeUsername = (value) => {
    setUserName(value);
  };

  const handleChangePassword = (value) => {
    value = value.replace(/^\s+/g, "");
    setPassword(value);
  };

  return (
    <ThemeProvider theme={basicTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", overflow: "hidden" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${AiCollectLogo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"
            // backgroundPosition: "center",
            // gridArea: "image",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: 20,
            }}
          >
            <Grid item xs={12} md={3}>
              <img src={logo} alt="logo" className={classes.logo} />
            </Grid>
            {resetPasswordSuccess ? (
              <div className={classes.resetPasswordMessage}>
                <Typography className={classes.resetPasswordMessageText}>
                  Password reset link sent to your registered email address
                </Typography>
              </div>
            ) : (
              ""
            )}
            <Typography
              sx={{
                fontSize: { xl: "36px", lg: "30px", md: "24px" },
                color: "#2D6A4F",
                marginTop: { xl: "50px", lg: "40px", md: "30px" },
              }}
            >
              Welcome back!
            </Typography>
            <Typography
              sx={{
                fontSize: { xl: "20px", lg: "16px", md: "12px" },
                color: "#9B9D9D",
                marginTop: { xl: "20px", lg: "18px", md: "16px" },
                fontStyle: "italic",
              }}
            >
              To Wadhwani AI AgriAICollect admin portal.
            </Typography>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              {/* <form className={classes.form} noValidate> */}
              <Typography
                sx={{
                  marginBottom: 1,
                  color: "#444444",
                  fontSize: { xl: "24px", lg: "20px", md: "16px" },
                  marginTop: { xl: "26px", lg: "20px", md: "16px" },
                  marginLeft: 1,
                }}
              >
                Username
              </Typography>
              <TextField
                variant="outlined"
                name="username"
                type="username"
                error={(submitted && !username) || errors.email !== ""}
                helperText={
                  submitted && !username
                    ? "Username is required"
                    : errors.email !== ""
                    ? errors.email
                    : ""
                }
                onKeyDown={handleKeyDown}
                onChange={(e) => handleChangeUsername(e.target.value)}
                className={classes.textfield}
                required
                fullWidth
                id="email"
                placeholder="Enter username here"
                autoComplete="email"
                autoFocus
                sx={{
                  width: { xl: "440px", lg: "340px", md: "280px", sm: "200px" },
                  height: { xl: "60px", lg: "50px", md: "40px", sm: "20px" },
                  backgroundColor: "#FFFFFF",
                  marginTop: { xl: "10px", lg: "8px", md: "6px" },
                }}
                inputProps={{
                  maxLength: 40,
                  style: {
                    borderRadius: 15,
                    background: "#FFFFFF",
                    marginLeft: 2,
                    fontSize: { xl: "20px", lg: "16px", md: "12px" },
                    WebkitBoxShadow: "0 0 0 1000px white inset",
                  },
                  "aria-label": "Without label",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                sx={{
                  marginBottom: 1,
                  color: "#444444",
                  fontSize: { xl: "24px", lg: "20px", md: "16px" },
                  marginTop: { xl: "26px", lg: "20px", md: "16px" },
                  marginLeft: 1,
                  // marginBottom: 2,
                }}
              >
                Password
              </Typography>
              <TextField
                error={(submitted && !password) || errors.password !== ""}
                helperText={
                  submitted && !password
                    ? "Password is required"
                    : errors.password !== ""
                    ? errors.password
                    : ""
                }
                onKeyDown={handleKeyDown}
                onChange={(e) => handleChangePassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                className={classes.textfield}
                required
                fullWidth
                id="password"
                placeholder="Enter password here"
                name="password"
                autoComplete="password"
                autoFocus
                sx={{
                  width: { xl: "440px", lg: "340px", md: "280px", sm: "200px" },
                  height: { xl: "60px", lg: "50px", md: "40px", sm: "20px" },
                  backgroundColor: "#FFFFFF",
                  marginTop: { xl: "10px", lg: "8px", md: "6px" },
                }}
                inputProps={{
                  maxLength: 40,
                  style: {
                    borderRadius: 15,
                    background: "#FFFFFF",
                    fontSize: { xl: "20px", lg: "16px", md: "12px" },
                    WebkitBoxShadow: "0 0 0 1000px white inset",
                  },
                  "aria-label": "Without label",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{ marginTop: { xl: "40px", lg: "32px", md: "26px" } }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ButtonStyle
                  variant="contained"
                  endIcon={<ArrowForwardIcon size="large" />}
                  onClick={handleLogin}
                  sx={{fontSize:{xl:"18px",lg:"16px",md:"14px"},
                    width:{xl:"180px",lg:"150px",md:"130px"},
                    height:{xl:"56px",lg:"50px",md:"44px"},
                  }}
                >
                  Sign in
                </ButtonStyle>
              </Box>
              <Box
                sx={{ marginTop: { xl: "30px", lg: "26px", md: "20px" } }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  sx={{
                    fontSize: { xl: "18px", lg: "16px", md: "12px" },
                    color: "#5E6060",
                    marginTop: { xl: "20px", lg: "16px", md: "12px" },
                    fontStyle: "italic",
                  }}
                >
                  Forgot password?
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xl: "18px", lg: "16px", md: "12px" },
                    color: "#2D6A4F",
                    marginTop: { xl: "16px", lg: "12px", md: "8px" },
                    // marginTop: 2,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  display="inline"
                  onClick={() => navigate("/forgot_password")}
                >
                  Click here to change your password
                </Typography>
              </Box>
              {/* </form> */}
            </Box>
          </Box>
          {loginDataLoading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loginDataLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {showSnackBar && (
            <SnackBars
              open={showSnackBar}
              handleClose={() => setShowSnackBar(false)}
              severity={snackBarType}
              message={snackBarMessage}
              autoHideDuration={6000}
              vertical="bottom"
              horizontal="center"
            />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
