import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#78828C",
  fontSize: "18px",
};

const SuccessButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "150px",
  maxWidth: "180px",
  marginLeft: 20,
  padding: "10px 12px",
  "&:hover": {
    backgroundColor: "#2D6A4F",
    color: "white",
  },
});

export default function SuccessErrorDialogBox({
  handleClose = () => {},
  open,
  text = "Deleted Sucessfully",
  type="success"
}) {

  return (
    <Dialog
    open={open}
    PaperProps={{
      sx: {
        borderRadius: "16px",
        width: { xl: "750px", lg: "650px", md: "450px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    }}
  >
    <DialogTitle
      sx={{
        marginTop: 2,
        color: "var(--Royal-Black, #4F5555)",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      }}
    >
      <span
        style={{ fontSize: "36px", marginBottom: "12px", display: "block" }}
      >
        {type==="success" ? "🥳" : "❗"}
      </span>
      {text}
    </DialogTitle>
    <DialogActions sx={{ marginTop: "auto",marginBottom:"20px" }}>
      <SuccessButtonStyle
        variant="contained"
        onClick={handleClose}
      >
        {/* {type==="success" ? "Ok" : "Fill Pending"} */}
        Ok
      </SuccessButtonStyle>
    </DialogActions>
  </Dialog>
  
  );
}
