import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AutoCompleteMultiDropdown from "../projects/AutoCompleteMultiDropdown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { basicTheme } from "../../themes/customTheme";
import { getStatesData } from "../../features/location/locationAPIs";
import { getProjectData } from "../../features/projects/projectsAPIs";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 700,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "1.8",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

export default function PartnerFilterPopOver({
  handleClose = () => { },
  setApplyFilter = () => { },
  openFilterPopOver,
  selectedStates = [],
  setSelectedStates = () => { },
  selectedPartners = [],
  setSelectedPartners = () => { },
  selectedProjects = [],
  setSelectedProjects = () => { },
  clearFilter = false,
  setClearFilter = () => { },
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [loading, setLoading] = useState(false);
  const [getStatesResponse, setGetStateResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [projectsData, setProjectsData] = useState([{
    id: "",
    name: "",
  },])
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  function searchType(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === nameKey) {
        return true;
      }
    }
  }

  const updateArray = (array, type, newValue, remove = false, setArray) => {
    const newArray = [...array];
    var objIndex = newArray.findIndex((obj) => obj.type == type);
    if (remove) {
      setArray((rem) => rem.filter((_, ind) => ind !== objIndex));
      return;
    }

    newArray[objIndex].value = newValue;
    return newArray;
  };

  useEffect(() => {
    if (getStatesResponse[0].id === ""){
      const params = {}
      const fetchData = async () => {
        try {
          const response = await getStatesData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetStateResponse(response.data.data);
          } else {
            // setAddPartnerSnackBar(true);
            // setSnackBarType("error");
            // setAddPartnerSnackBarMessage("Something went wrong on sever!")
          }
        } catch (error) {
          // setAddPartnerSnackBar(true);
          // setSnackBarType("error");
          // setAddPartnerSnackBarMessage("Not able to fetch state, Please check server!")
        }
      };
      fetchData();
    };
    if(projectsData[0].id === ""){
      const params = { fields: ["id", "name"] }
      const fetchData = async () => {
        try {
          const response = await getProjectData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setProjectsData(response.data.data);
          } else {
            // dsetAddPartnerSnackBar(true);
            // setSnackBarType("error");
            // setAdPartnerSnackBarMessage("Something went wrong on sever!")
          }
        } catch (error) {
          // setAddPartnerSnackBar(true);
          // setSnackBarType("error");
          // setAddPartnerSnackBarMessage("Not able to fetch projects, Please check server!")
        }
      };
      fetchData();
    }
  }, []);

  const handleChangeApplyFilter = () => {
    // var tempLocationIds = [];
    // selectedStates.forEach((location) => {
    //   tempLocationIds.push(location.id);
    // });
    // var newProjects = [];
    // selectedProjects.forEach((project) => {
    //   newProjects.push(project.id)
    // })
    // setFilters([
    //   { type: "project", value: newProjects },
    //   { type: "location", value: tempLocationIds },
    // ]);
    setApplyFilter(true);
  };

  // useEffect(() => {
  //   if (clearFilter) {
  //     setProjectIds([]);
  //     // var tempFilter = [
  //     //   { type: "project", value: [] },
  //     //   { type: "location", value: [] },
  //     // ];
  //     // if (loggedInUser.details.partner === null) {
  //     //   tempFilter.push({ type: "name", value: [] });
  //     //   setSelectedPartners([]);
  //     // } else {
  //     //   tempFilter.push({
  //     //     type: "name",
  //     //     value: [loggedInUser.details.partner.name],
  //     //   });
  //     // }
  //     // setFilters(tempFilter);
  //     setSelectedProjects([]);
  //     setSelectedStates([]);
  //   }
  // }, [clearFilter]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "1rem",
        },
      }}
      onClose={handleClose}
    >
      <ThemeProvider theme={basicTheme}>
        <StyledGrid container>
          <Grid item xs={11} sx={{ color: "#78828C" }}>
            <Typography sx={{ fontSize: "20px", color: "#999DA4" }}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ color: "#444444", margin: 1 }}>
              States
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={getStatesResponse}
              selectedOptions={selectedStates}
              setSelectedOptions={setSelectedStates}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
          <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
            Partner
          </Typography>
          <AutoCompleteMultiDropdown
            width={230}
            menuList={partnerNamesData}
            selectedOptions={
              partnerNamesData[0].id !== "" ? selectedPartners : []
            }
            setSelectedOptions={setSelectedPartners}
            disable={loggedInUser.details.partner !== null}
          />
        </Grid> */}
        <Grid item xs={12} md={6}></Grid>
          {/* <Grid item xs={12} md={6}>
            <Typography sx={{ color: "#444444", margin: 1 }}>
              Projects
            </Typography>
            <AutoCompleteMultiDropdown
              width={230}
              menuList={projectsData}
              selectedOptions={selectedProjects}
              setSelectedOptions={setSelectedProjects}
              required={true}
            />
          </Grid> */}
          <Grid item xs={3}>
            <ClearBtn
              variant="contained"
              name="Clear Filter"
              onClick={() => setClearFilter(true)}
            >
              Clear All
            </ClearBtn>
          </Grid>

          <Grid item xs={3}>
            <ApplyBtn
              variant="contained"
              name="Apply Filter"
              onClick={() => setApplyFilter(true)}
            >
              Apply
            </ApplyBtn>
          </Grid>
        </StyledGrid>
      </ThemeProvider>
      {/* {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
    </Popover>
  );
}
