import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEwData,
} from "../../features/appUsers/appUsersApi";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#78828C",
  fontSize:"18px"
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};
const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#EB5451",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: '150px',
  maxWidth: '180px',
  marginLeft: 20,
  padding: "10px 12px",
  '&:hover': {
    backgroundColor: '#EB5451',
    color: 'white', 
  },
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: 'white',
  color: '#2D6A4F',
  textTransform: 'none',
  borderRadius: '14px',
  fontSize: '16px',
  fontWeight: '600',
  minWidth: '150px',
  maxWidth: '180px',
  marginRight: 20,
  border: '1px solid var(--Primary-Green, #2D6A4F)',
  padding: "10px 12px",
  '&:hover': {
    backgroundColor: 'white',
    color: '#2D6A4F',
  },
});

export default function DeleteEw({
  handleClose = () => {},
  open,
  deleteData = {},
  setUserSnackBarMessage = () => {},
  setReload = () => {},
  setOpenAlertModal = ()=>{},
}) {
  const dispatch = useDispatch();

  const handleDelete =  async () => {

    try {
      const ids = Object.values(deleteData)
        .filter(user => user.isSelected)
        .map(usr => usr.id);
      const response = await deleteEwData({ ids });
      if (
        response != null &&
        response != undefined &&
        response.data !== null &&
        response.data !== undefined &&
        response.data.message !== null &&
        response.data.message !== undefined
      ) {
        setUserSnackBarMessage(response.data.message);
        setReload(true);
        handleClose();
      } else {
        setUserSnackBarMessage("Something went wrong on sever!");
      }
    } catch (error) {
      setOpenAlertModal(true)
      handleClose();
    }
  };

  const getSelectedUsers = () => {
    return Object.values(deleteData)
      .filter(user => user.isSelected)
      .map(usr => usr.name);
  }

  return (
    <Dialog open={open} PaperProps={{ sx: { borderRadius: "16px" } }}>
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleClose} />
      <DialogTitle
        sx={{
          marginTop: 2,
          color: "var(--Royal-Black, #4F5555)",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        Are you sure you want to delete {getSelectedUsers().length > 1 ? "these Extension Worker" : "this Extension Worker"}?
        
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={Centerstyle}>
          {getSelectedUsers().join(', ')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ ...Centerstyle, marginBottom: 2 }}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleClose}
          sx={{ marginRight: 5 }}
        >
          No
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{  marginLeft: 5 }}
          onClick={handleDelete}
          autoFocus
          // disabled={deleteEwErrorMessage !== ""}
        >
          Yes, Delete
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
}
