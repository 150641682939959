import React from "react";
import { TextField, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { capitaliseFirstLetter } from "../../utils";

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#FFFFFF",
    color:"#FF0000",
  },
});

export default function AutoCompleteDropdown({
  menuList = [],
  selectedOptions = null,
  setSelectedOptions = () => {},
  disable = false,
  nameNumber = false,
  label = "",
  onHighlightChange = () => {},
  optionDisabledFunc = () => {},
  index = 0,
  type = "",
  required = false,
  onOpen = () => {},
  loading = false,
  error = false,
  errorText="",
}) {
  const MenuProps = {
    style: {
      display:
        menuList.length > 0 &&
        menuList !== undefined &&
        menuList !== null &&
        ((menuList[0].name !== undefined && menuList[0].name === "") ||
          (menuList[0].id !== undefined && menuList[0].id === "")) &&
        "none",
    },
  };

  const handleChange = (event, value) => {
    setSelectedOptions(value);
    onHighlightChange(value, index, type);
  };

  return (
    <CustomAutocomplete
      onChange={(e, value) => {
        handleChange(e, value);
      }}
      onOpen={onOpen}
      isOptionEqualToValue={(option, value) =>
        option && value && option.id === value.id
      }
      value={selectedOptions}
      options={menuList}
      disabled={disable}
      ListboxProps={MenuProps}
      getOptionLabel={(option) => (option.name ? capitaliseFirstLetter(option.name) : "")}
      getOptionDisabled={(option) => (optionDisabledFunc ? optionDisabledFunc(option) : null)}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} helperText={errorText}
        sx={{
          "& label": {
            color: (theme) => (error ? theme.palette.error.main : undefined),
            "&.Mui-focused": {
              color: (theme) => (error ? theme.palette.error.main : undefined),
            },},
          "& .MuiFormHelperText-root": {
            color: (theme) => (error ? theme.palette.error.main : undefined),
          },
        }} />
      )}
      renderOption={(props, option) =>
        type === "icon" ? (
          <li {...props} key={option.id}>
            <img
              src={`data:image/png;base64,${option.image}`}
              alt={option.name}
              style={{ width: "24px", height: "24px" }}
            />
            &nbsp;&nbsp;
            {capitaliseFirstLetter(option.name)}
          </li>
        ) : (
          <li {...props} key={option.id}>
            {capitaliseFirstLetter(option.name)}
          </li>
        )
      }
      noOptionsText={loading ? "Loading..." : "No options found"}
      sx={{
        ".MuiOutlinedInput-root": {
          marginBottom: 1,
          borderRadius: "1rem",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem",
            borderColor: (theme) => (error ? theme.palette.error.main : ""),
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "1rem",
          },
          backgroundColor: "#FFFFFF",
        },
        width: "90%",
      }}
    ></CustomAutocomplete>
  );
}
