import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import { basicTheme } from "../../themes/customTheme";
import LocationDropDown from "./locationDropdown";
import { addLocation, resetAddlocation } from "../../features/location/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ReactComponent as CrossButton } from "../../styles/close-button.svg"
import SnackBars from "../reusableComponents/SnackBar";
import { addLocationData } from "../../features/location/locationAPIs";

const mainGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
  paddingTop: 2,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});


export default function AddVillageModal({ openAddModal, handleClose, setSnackBarMessage = () => { }, setReload = () => { }, setOpenSuccessBox = () => {},setSuccessMessage=()=>{}, }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    showAnotherLocationDropDown,
    setShowAnotherLocationDropDown,
  ] = useState(false);

  const [villageNames, setVillageNames] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);
  const [parentId, setParentId] = useState('');
  const [addVillageSnackBar, setAddVillageSnackBar] = useState(false);
  const [addVillageSnackBarMessage, setAddVillageSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const [currentIndex, setCurrentIndex] = useState(1);

  const saveData = async () => {
    const data = {
      names: villageNames,
      parent_location_id: parentId,
      type: "village",
      is_active: activeStatus,
    }
    try {
      const response = await addLocationData(data)
      if (response != null && response != undefined &&
        response.data !== null && response.data !== undefined &&
        response.data.message !== null && response.data.message !== undefined
      ) {
        // setSnackBarMessage(response.data.message);
        setReload(true);
        setActiveStatus([]);
        setParentId('');
        setVillageNames([]);
        handleClose();
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"])
      } else {
        // setSnackBarType("error");
        setSnackBarMessage("Something went wrong on sever!");
      }
    } catch (error) {
      setAddVillageSnackBar(true);
      setSnackBarType("error");
      setAddVillageSnackBarMessage(error.response.data["message"]);
    }
  }

  const handleCloseByClearIcon = () => {
    handleClose();
    setShowAnotherLocationDropDown(false);
    setActiveStatus([]);
    setParentId('');
    setVillageNames([]);
    setSnackBarMessage("");
    setReload(false);
    setAddVillageSnackBar(false);
    setAddVillageSnackBarMessage("");
  }

  return (
    <Modal
      open={openAddModal}
    >
      <Grid container sx={mainGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid item xs={11}>
            <Typography variant="h4"
              sx={{ color: "#444444", fontWeight: "500"
            }}>
              Add Villages
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleCloseByClearIcon}
            />
          </Grid>
          <Grid
            item
            xs={11.9}
            style={{ backgroundColor: "#F5F5F5", borderRadius: "1rem", padding: '16px 0 20px 20px' }}
          >
            <LocationDropDown addVillage={true} setVillageNames={setVillageNames} villageNames={villageNames} setParentId={setParentId} setActiveStatus={setActiveStatus} activeStatus={activeStatus}
              setSnackBarType={setSnackBarType} setShowSnackBar={setAddVillageSnackBar} setSnackBarMessage={setAddVillageSnackBarMessage} setCurrentIndex={setCurrentIndex} />
          </Grid>
          <Grid item xs={12}>
            <ButtonStyle
              variant="contained"
              name="add"
              startIcon={<CheckIcon size="large" left="15.95%" right="14.24%" top="25.03%" bottom="22.57%" />}
              onClick={saveData}
              disabled={villageNames.length === 0 || villageNames.length !== currentIndex || (villageNames.includes(''))}
            >
              Save
            </ButtonStyle>
          </Grid>
        </ThemeProvider>
        {addVillageSnackBar &&
          <SnackBars
            open={addVillageSnackBar}
            handleClose={() => setAddVillageSnackBar(false)}
            severity={snackBarType}
            message={addVillageSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />}
      </Grid>
    </Modal>
  );
}
