import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

export default function SnackBars ({ open, autoHideDuration = 4000, handleClose, severity, message,vertical,horizontal ,style,position="fixed"}) {

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={{vertical, horizontal} }  sx={{ position: position }}// Use Slide for the transition
               >
            <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%', borderRadius: "14px" }}>
              {message}
            </MuiAlert>
        </Snackbar>
    )
}
