import { Grid, Paper, Box, TextField, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// import { styled, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import LoginAppbar from "../../components/appMenuBar/LoginAppbar";
// import { forgotPassword } from "../actions/loginActions"
import { forgotPassword, resetForgotPassword } from "./AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import { basicTheme } from "../../themes/customTheme";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SnackBars from "../../components/reusableComponents/SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  logincontainer: {
    width: "450px",
    height: "450px",
    direction: "column",
    alignItems: "center",
  },
  paper: {
    alignItems: "center",
    verticalAlign: "center",
    padding: "30px",
    marginTop: "-100px",
    textAlign: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

const ForgotPassword = () => {
  const [username, setUserName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  //   const errorText = useSelector((state) => state.loginActionsReducer.errorText);

  const basicError = {
    email: "",
  };
  const [errors, setErrors] = useState(basicError);

  const forgotPasswordApiResponse = useSelector(
    (state) => state.authenticationSliceReducer.forgotPasswordApiResponse
  );

  const forgotPasswordErrorMessage = useSelector(
    (state) => state.authenticationSliceReducer.forgotPasswordErrorMessage
  );

  const forgotPasswordIsSuccess = useSelector(
    (state) => state.authenticationSliceReducer.forgotPasswordIsSuccess
  );

  const forgotPasswordLoading = useSelector(
    (state) => state.authenticationSliceReducer.forgotPasswordLoading
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleForgotPassword()
    }
  }

  useEffect(() => {
    dispatch(resetForgotPassword());
  }, []);

  useEffect(() => {
    if (
      !forgotPasswordIsSuccess &&
      forgotPasswordErrorMessage !== undefined &&
      forgotPasswordErrorMessage.message !== undefined &&
      forgotPasswordErrorMessage.message !== ""
    ) {
      setShowSnackBar(true);
      setSnackBarMessage(forgotPasswordErrorMessage.message);
      setSnackBarType("error");
    } else {
      setShowSnackBar(false);
    }
  }, [forgotPasswordErrorMessage]);

  useEffect(() => {
    if (
      forgotPasswordIsSuccess &&
      forgotPasswordApiResponse !== undefined &&
      forgotPasswordApiResponse.message !== null &&
      forgotPasswordApiResponse.message !== undefined &&
      forgotPasswordApiResponse.message !== ""
    ) {
      setShowSnackBar(true);
      setSnackBarMessage(forgotPasswordApiResponse.message);
      setSnackBarType("success");
      // let timeoutID = setTimeout(function() {
      //   navigate("/");
      // }, 7000);
    } else {
      setShowSnackBar(false);
    }
  }, [forgotPasswordApiResponse, forgotPasswordIsSuccess]);


  // function handleForgotPassword() {
  //   setSubmitted(true);
  //   if (username && errors.email === "") {
  //     forgotPassword(username)
  //       .then((data) => {
  //         var success = data.success !== undefined ? data.success : false;
  //         if (success && data.data.msg !== undefined) {
  //           setShowSnackBar(true);
  //           setSnackBarMessage(data.data.msg);
  //           setSnackBarType("success");
  //           setLoading(false);
  //         } else {
  //           setLoading(false);
  //           if (
  //             data.response !== undefined &&
  //             data.response.data.detail !== undefined
  //           ) {
  //             setShowSnackBar(true);
  //             setSnackBarMessage(data.response.data.detail);
  //             setSnackBarType("error");
  //           } else {
  //             setShowSnackBar(true);
  //             setSnackBarMessage("Something went wrong on server side ");
  //             setSnackBarType("error");
  //             navigate("/", true);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error("Error:", error);
  //         setShowSnackBar(true);
  //         setSnackBarMessage("Something went wrong on server side ");
  //         setSnackBarType("error");
  //       });
  //   }
  // }


  function handleForgotPassword() {
    setSubmitted(true);
    if (username && errors.email === "") {
      dispatch(
        forgotPassword({
          email: username,
        })
      );
    }
  }

  const handleChangeUsername = (value) => {
    value = value.replace(/^\s+/g, "");
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (value.match(mailformat)) {
      setErrors({ ...errors, email: "" });
    } else {
      setErrors({ ...errors, email: "Invalid Email " });
    }
    setUserName(value);
  };

  return (
    <div>
      <LoginAppbar />
      <Grid
        container
        className={classes.root}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#F5F5F5" }}
      >
        <Grid item md={4} xs={1} sm={3} />
        <Grid item md={6} xs={10} sm={6} className={classes.logincontainer}>
          {forgotPasswordLoading && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={forgotPasswordLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Paper>
            <div className={classes.paper}>
              <Typography
                component="h1"
                variant="h5"
                style={{ fontSize: "24px", lineHeight: "1.33" }}
              >
                <Box fontWeight={600} m={1}>
                  Forgot password
                </Box>
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                align="center"
                style={{
                  color: "gray",
                  fontSize: "14px",
                  letterSpacing: "normal",
                }}
              >
                Enter your registered Email Id. We will email you a password
                reset link.
              </Typography>
              <form className={classes.form} noValidate>
                <ThemeProvider theme={basicTheme}>
                  <TextField
                    variant="outlined"
                    required
                    // fullWidth
                    margin="normal"
                    id="username"
                    label="Email"
                    name="username"
                    type="username"
                    autoFocus
                    onKeyDown={handleKeyDown}
                    error={(submitted && !username) || errors.email !== ""}
                    sx={{ width: "300px", backgroundColor: "#FFFFFF" }}
                    //   (submitted && !username) ||
                    //   errors.email!=="" ? errors.email :""
                    // }
                    helperText={
                      <Typography
                        component={"span"}
                        style={{ marginLeft: "-14px", fontSize: "10px" }}
                      >
                        {submitted && !username
                          ? "Email is required"
                          : errors.email !== ""
                          ? errors.email
                          : ""}
                      </Typography>
                    }
                    onChange={(e) => handleChangeUsername(e.target.value)}
                    inputProps={{ style: { borderColor: "#0000FF" } }}
                  />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "20px" }}
                  >
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#2D6A4F", color: "white",textTransform: "none" }}
                      onClick={handleForgotPassword}
                    >
                      Send password reset link
                    </Button>
                    <Button
                      disableFocusRipple
                      disableRipple
                      style={{
                        textTransform: "none",
                        color: "#929dad",
                        marginTop: "20px",
                      }}
                      variant="text"
                      color="secondary"
                      onClick={() => navigate("/")}
                    >
                      SIGN IN
                    </Button>
                  </Grid>
                  {showSnackBar && (
                    <SnackBars
                      open={showSnackBar}
                      handleClose={() => setShowSnackBar(false)}
                      severity={snackBarType}
                      message={snackBarMessage}
                      autoHideDuration={6000}
                      vertical="bottom"
                      horizontal="center"
                    />
                  )}
                </ThemeProvider>
              </form>
            </div>
          </Paper>
        </Grid>
        <Grid item md={2} xs={1} sm={3} />
      </Grid>
    </div>
  );
};

export default ForgotPassword;
