import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  TextField,
  Checkbox,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import ReactLoader from "react-fullpage-custom-loader";
import SnackBars from "../reusableComponents/SnackBar";
import CollectionsIcon from "@mui/icons-material/Collections";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AdjustableTextfield from "../reusableComponents/AdjustableTextfield";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {
  getAndSendAdvisory,
  resetgetAndSendAdvisory,
} from "../../features/reviewImages/reviewImagesSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../helpers/Loader";

const subGridStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  //   p: 4,
  borderRadius: 2,
  width: "70%",
  maxHeight: "92%",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  //   paddingLeft: 2,
};

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
});

const PreviousButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontWeight: "600",
});

const StyledTitled = styled(Typography)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  color: "#202124",
  lineHeight: "40px",
  letterSpacing: "normal",
  color: "#5E6060",
});

const StyledSubTitled = styled(Grid)({
  fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "Inter",
  lineHeight: "60px",
  letterSpacing: "normal",
  color: "#78828C",
  minWidth: "250px",
});

const useStyles = makeStyles(() => ({
  close: {
    display: "flex",
    justifyContent: "flex-end",
    zoom: "2.2",
    alignItems: "right",
  },
  iconColor: {
    color: "grey",
    cursor: "pointer",
  },
  carausel: {
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subContCarausel: {
    backgroundColor: "white",
    // padding: "10px",
  },
  imgNumber: {
    backgroundColor: "grey",
    borderRadius: "16px",
    margin: "10px",
  },
}));

const SendButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "130px",
  height: "48px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "600",
  minWidth: "120px",
  maxWidth: "130px",
  height: "48px",
});

export default function ReviewImageDialog({
  openModal,
  handleClose = () => {},
  data = {},
  setReload = () => {},
  setPendingShowSnackBar,
  setPendingSnackBarMessage = () => {},
  setPendingSnackBarType,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [imageData, setImageData] = useState(data);
  const [backBtnDisable, setBackBtnDisable] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [abwCount, setAbwCount] = useState(
    data && data.images && data.images[index] && data.images[index].abw_count
      ? data.images[index].abw_count
      : 0
  );
  const [pbwCount, setPbwCount] = useState(
    data && data.images && data.images[index] && data.images[index].pbw_count
      ? data.images[index].pbw_count
      : 0
  );

  const [comment, setComment] = useState("");
  const [totalNumberOfImages, setTotalNumberOfImages] = useState(
    data && data.images && data.images.length ? data.images.length : 0
  );

  const [imageLoader, setImageLoader] = useState(true);
  const [trapValue, setTrapValue] = useState(1);
  const [smsContent, setSmsContent] = useState("");
  const [infestationColor, setInfestationColor] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const getAndSendAdvisoryResponse = useSelector(
    (state) => state.reviewImagesSliceReducer.getAndSendAdvisoryResponse
  );

  const getAndSendAdvisoryErrorMessage = useSelector(
    (state) => state.reviewImagesSliceReducer.getAndSendAdvisoryErrorMessage
  );

  const getAndSendAdvisoryIsSuccess = useSelector(
    (state) => state.reviewImagesSliceReducer.getAndSendAdvisoryIsSuccess
  );

  const getAndSendAdvisoryLoading = useSelector(
    (state) => state.reviewImagesSliceReducer.getAndSendAdvisoryLoading
  );

  const imageLoaded = () => {
    setImageLoader(false);
  };

  const handleCloseDialog = () => {
    setOpenConfirmModal(false);
    setSmsContent("");
    setInfestationColor("");
  };

  const getPreviousImage = () => {
    setIndex((prevIndex) => prevIndex - 1);
    setImageLoader(true);
  };

  const submitData = () => {
    // removing presigned url from images as requested from BE
    var tempImageData = [...imageData.images];
    tempImageData = tempImageData.map((item) => {
      const { pre_signed_url, ...rest } = item;
      return rest;
    });
    var params = {
      session_id: imageData.session_id,
      images: tempImageData,
      is_confirmed: true,
    };
    setImageLoader(true);
    dispatch(getAndSendAdvisory(params)).then(() => {
      setImageLoader(false);
    });
  };

  const getNextImage = () => {
    setIndex((prevIndex) => prevIndex + 1);
    if (index !== totalNumberOfImages - 1) {
      setImageLoader(true);
    }

    if (comment == "") {
      updateImageData("comment", "");
    }
    if (trapValue == 1) {
      updateImageData("is_trap_image", 1);
    }
    if (index === totalNumberOfImages - 1) {
      // removing presigned url from images as requested from BE
      var tempImageData = [...imageData.images];
      tempImageData = tempImageData.map((item) => {
        const { pre_signed_url, ...rest } = item;
        return rest;
      });
      var params = {
        session_id: imageData.session_id,
        images: tempImageData,
        is_confirmed: false,
      };

      setImageLoader(true);
      setIndex((prevIndex) => prevIndex - 1);
      dispatch(getAndSendAdvisory(params)).then(() => {
        setImageLoader(false);
      });
    }
  };

  useEffect(() => {
    if (
      getAndSendAdvisoryResponse !== undefined &&
      getAndSendAdvisoryResponse.message !== undefined &&
      getAndSendAdvisoryResponse.message === "ok"
    ) {
      setPendingShowSnackBar(true);
      setPendingSnackBarMessage("Review Submitted Successfully");
      setPendingSnackBarType("success");
      setOpenConfirmModal(false);
      handleClose(false);
      setReload(true);
    } else if (
      getAndSendAdvisoryResponse !== undefined &&
      getAndSendAdvisoryResponse.message !== undefined &&
      getAndSendAdvisoryResponse.message != ""
    ) {
      setOpenConfirmModal(true);
      const smsText = getAndSendAdvisoryResponse.message;
      setSmsContent(
        smsText == "red" ? "High" : smsText == "yellow" ? "Medium" : "Low"
      );
      setInfestationColor(
        smsText == "red" ? "red" : smsText == "yellow" ? "#FFCC00" : "green"
      );
    }
    dispatch(resetgetAndSendAdvisory());
  }, [getAndSendAdvisoryResponse]);

  useEffect(() => {
    if (
      !getAndSendAdvisoryIsSuccess &&
      (getAndSendAdvisoryErrorMessage === undefined ||
        (getAndSendAdvisoryErrorMessage.message === undefined &&
          getAndSendAdvisoryErrorMessage.message !== ""))
    ) {
      setShowSnackBar(true);
      setShowSnackBarMessage("Something went wrong on server side ");
      setSnackBarType("error");
    } else if (
      !getAndSendAdvisoryLoading &&
      !getAndSendAdvisoryIsSuccess &&
      getAndSendAdvisoryErrorMessage !== undefined &&
      getAndSendAdvisoryErrorMessage.message !== undefined &&
      getAndSendAdvisoryErrorMessage.message !== ""
    ) {
      setShowSnackBar(true);
      setShowSnackBarMessage(getAndSendAdvisoryErrorMessage.message);
      setSnackBarType("error");
    }
  }, [getAndSendAdvisoryErrorMessage, getAndSendAdvisoryLoading]);

  useEffect(() => {
    setTrapValue(
      (imageData &&
        imageData.images &&
        imageData.images[index] &&
        imageData.images[index].is_trap_image !== undefined &&
        imageData.images[index].is_trap_image === 1) ||
        imageData.images[index].is_trap_image === 0
        ? imageData.images[index].is_trap_image
        : 1
    );
    setAbwCount(
      imageData &&
        imageData.images &&
        imageData.images[index] &&
        imageData.images[index].abw_count
    );
    setPbwCount(
      imageData &&
        imageData.images &&
        imageData.images[index] &&
        imageData.images[index].pbw_count
    );
    setComment(
      imageData &&
        imageData.images &&
        imageData.images[index] &&
        (imageData.images[index].comment === undefined ||
          imageData.images[index].comment === "")
        ? ""
        : imageData.images[index].comment
    );
    if (index == 0) {
      setBackBtnDisable(true);
    } else {
      setBackBtnDisable(false);
    }
  }, [index]);

  const handleChangeTrapRadio = (e) => {
    setTrapValue(parseInt(e.target.value));
  };

  const handdleChangePbw = (e) => {
    imageData.images[index].pbw_count = Number(e);
    setImageData({ ...imageData });
    setPbwCount(e);
  };
  const handdleChangeAbw = (e) => {
    imageData.images[index].abw_count = Number(e);
    setImageData({ ...imageData });
    setAbwCount(e);
  };

  const handleChangeComment = (e) => {
    imageData.images[index].comment = e.trim();
    setImageData({ ...imageData });
    setComment(e.trim() !== "" ? e : "");
  };

  const updateImageData = (key, newTrapValue) => {
    const updatedImages = imageData.images.map((image, idx) => {
      if (idx === index) {
        return {
          ...image,
          [key]: newTrapValue,
        };
      }
      return image;
    });
    setImageData({ ...imageData, images: updatedImages });
  };

  useEffect(() => {
    if (imageData.images[index]) {
      if (
        imageData.images[index].is_trap_image != undefined &&
        imageData.images[index].is_trap_image != trapValue
      ) {
        setComment("");
      }
      updateImageData("is_trap_image", trapValue);
    }
    if (trapValue === 0) {
      setAbwCount(0);
      setPbwCount(0);
    }
  }, [trapValue]);

  return (
    <Modal
      open={openModal}
      BackdropProps={{
        sx: {
          backgroundColor: "transparent",
          "&.MuiBackdrop-root": {
            boxShadow: "none",
          },
        },
      }}
    >
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Box>
            <IconButton
              // style = {{cursor:'pointer', float:'right', marginTop: '5px', width: '20px'}}
              style={{ position: "absolute", top: 0, right: "0" }}
              onClick={() => handleClose(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} className={classes.carausel}>
            <Grid container className={classes.subContCarausel}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ImageList
                  sx={{
                    transform: "translateZ(0)",
                    aspectRatio: 1,
                    height: {
                      xl: "730px",
                      lg: "600px",
                      md: "500px",
                      sm: "350px",
                    },
                    width: {
                      xl: "566px",
                      lg: "460px",
                      md: "320px",
                      sm: "200px",
                    },
                    objectFit: "contain",
                    borderRadius: "24px",
                  }}
                >
                  <ImageListItem
                    sx={{
                      aspectRatio: 1,
                      height: {
                        xl: "730px",
                        lg: "600px",
                        md: "500px",
                        sm: "350px",
                      },
                      width: {
                        xl: "566px",
                        lg: "460px",
                        md: "320px",
                        sm: "200px",
                      },
                      objectFit: "contain",
                      borderRadius: "24px",
                    }}
                  >
                    <img
                      onLoad={imageLoaded}
                      loading="lazy"
                      src={
                        imageData.images[index] &&
                        imageData.images[index].pre_signed_url
                      }
                    />
                    <ImageListItemBar
                      sx={{
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                      }}
                      position="top"
                      actionIcon={
                        <Box
                          className={classes.imgNumber}
                          sx={{
                            flex: "1",
                            justifyContent: "center",
                            justify: "center",
                            alignItems: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: { xl: "65px", lg: "56px", md: "48px" },
                            height: { xl: "32px", lg: "27px", md: "22px" },
                          }}
                        >
                          <IconButton sx={{ color: "white" }}>
                            <CollectionsIcon
                              sx={{
                                width: { xl: "24px", lg: "20px", md: "16px" },
                                height: { xl: "24px", lg: "20px", md: "16px" },
                              }}
                            />
                            <Typography
                              sx={{
                                marginLeft: { xl: "6px", lg: "4px", md: "2px" },
                              }}
                            >
                              {data.images.length}
                            </Typography>
                          </IconButton>
                        </Box>
                      }
                      actionPosition="right"
                    />
                  </ImageListItem>
                </ImageList>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ paddingLeft: "20px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    {/* <StyledTitled
                      sx={{
                        fontSize: {
                          xl: "36px",
                          lg: "28px",
                          md: "18px",
                          sm: "14px",
                        },
                        marginTop: {
                          xl: "50px",
                          lg: "30px",
                          md: "15px",
                          sm: "10px",
                        },
                      }}
                    >
                      PROGRAM: {data.partner.toUpperCase()}
                    </StyledTitled> */}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSubTitled
                      sx={{
                        fontSize: {
                          xl: "24px",
                          lg: "16px",
                          md: "12px",
                          sm: "8px",
                        },
                        marginTop: {
                          xl: "14px",
                          lg: "8px",
                          md: "4px",
                          sm: "2px",
                        },
                      }}
                    >
                      {data.images.length} images captured | {data.time_stamp}
                    </StyledSubTitled>
                  </Grid>
                  <Divider
                    sx={{ marginTop: { xl: "20px", lg: "12px", md: "7px" } }}
                  />
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ marginTop: { xl: "10px", lg: "6px", md: "4px" } }}
                    >
                      <RadioGroup
                        row
                        onChange={handleChangeTrapRadio}
                        value={trapValue}
                      >
                        <FormControlLabel
                          value={1}
                          sx={{
                            color: trapValue === 1 ? "#2D6A4F" : "#78828C",
                          }}
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2D6A4F",
                                },
                              }}
                            />
                          }
                          label="Trap image"
                        />
                        <FormControlLabel
                          value={0}
                          sx={{
                            color: trapValue === 0 ? "#2D6A4F" : "#78828C",
                          }}
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2D6A4F",
                                },
                              }}
                            />
                          }
                          label="Not a trap image"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Divider
                    sx={{ marginTop: { xl: "20px", lg: "12px", md: "7px" } }}
                  />
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: { xl: "20px", lg: "18px", md: "16px" },
                        color: "#5E6060",
                        marginTop: { xl: "20px", lg: "16px", md: "14px" },
                        marginLeft: { xl: "12px", lg: "10px", md: "8px" },
                        marginBottom: { xl: "20px", lg: "16px", md: "12px" },
                      }}
                    >
                      Pink Bollworm Count
                    </Typography>
                    <AdjustableTextfield
                      index={1}
                      required={true}
                      value={pbwCount}
                      number={true}
                      handleChange={handdleChangePbw}
                      is_disabled={trapValue === 0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: { xl: "20px", lg: "18px", md: "16px" },
                        color: "#5E6060",
                        marginTop: { xl: "20px", lg: "16px", md: "14px" },
                        marginLeft: { xl: "12px", lg: "10px", md: "8px" },
                        marginBottom: { xl: "20px", lg: "16px", md: "12px" },
                      }}
                    >
                      American Bollworm Count
                    </Typography>
                    <AdjustableTextfield
                      index={2}
                      required={true}
                      value={abwCount}
                      number={true}
                      handleChange={handdleChangeAbw}
                      is_disabled={trapValue === 0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: { xl: "20px", lg: "18px", md: "16px" },
                        color: "#5E6060",
                        marginTop: { xl: "20px", lg: "16px", md: "14px" },
                        marginLeft: { xl: "12px", lg: "10px", md: "8px" },
                        marginBottom: { xl: "20px", lg: "16px", md: "12px" },
                      }}
                    >
                      Additional Remarks
                    </Typography>
                    <AdjustableTextfield
                      index={3}
                      value={comment}
                      handleChange={handleChangeComment}
                    />
                  </Grid>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      marginTop: {
                        xl: "36px",
                        lg: "26px",
                        md: "16px",
                        sm: "10px",
                      },
                    }}
                  >
                    <PreviousButtonStyle
                      variant="contained"
                      name="previous"
                      sx={{
                        fontSize: {
                          xl: "16px",
                          lg: "12px",
                          md: "8px",
                          sm: "6px",
                        },
                        height: {
                          xl: "48px",
                          lg: "40px",
                          md: "30px",
                          sm: "32px",
                        },
                        width: {
                          xl: "156px",
                          lg: "130px",
                          md: "110px",
                          sm: "90px",
                        },
                        marginRight: {
                          xl: "36px",
                          lg: "26px",
                          md: "16px",
                          sm: "10px",
                        },
                      }}
                      disabled={backBtnDisable}
                      onClick={() => getPreviousImage()}
                      startIcon={
                        <ArrowBackIosIcon
                          size="large"
                          className={classes.iconStyle}
                        />
                      }
                    >
                      Previous
                    </PreviousButtonStyle>
                    <ButtonStyle
                      sx={{
                        fontSize: {
                          xl: "16px",
                          lg: "12px",
                          md: "8px",
                          sm: "6px",
                        },
                        height: {
                          xl: "48px",
                          lg: "40px",
                          md: "30px",
                          sm: "32px",
                        },
                        width: {
                          xl: "156px",
                          lg: "130px",
                          md: "110px",
                          sm: "90px",
                        },
                      }}
                      variant="contained"
                      name="next/submit"
                      onClick={() => getNextImage()}
                      endIcon={
                        <KeyboardArrowRightIcon
                          size="large"
                          className={classes.iconStyle}
                        />
                      }
                    >
                      {index === totalNumberOfImages - 1
                        ? "Submit"
                        : "Next Image "}
                    </ButtonStyle>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openConfirmModal && (
            <Dialog
              open={openConfirmModal}
              keepMounted
              onClose={handleCloseDialog}
              PaperProps={{
                style: { borderRadius: "20px" },
              }}
            >
              <DialogTitle style={{ textAlign: "center" }}>
                {"Infestation Level Found "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  style={{ textAlign: "center", color: infestationColor }}
                >
                  {smsContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <CancelButtonStyle
                  variant="contained"
                  name="cancel"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </CancelButtonStyle>
                <SendButtonStyle
                  variant="contained"
                  name="add"
                  onClick={() => submitData()}
                >
                  Send Report
                </SendButtonStyle>
              </DialogActions>
            </Dialog>
          )}
          {imageLoader && (
            <Backdrop
              sx={{
                color: "#fff",
                position: "absolute",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={imageLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {showSnackBar && (
            <SnackBars
              open={showSnackBar}
              handleClose={() => setShowSnackBar(false)}
              severity={snackBarType}
              message={showSnackBarMessage}
              autoHideDuration={6000}
              vertical="bottom"
              horizontal="center"
            />
          )}
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
