import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import { getDistrictData, getStatesData, getTalukaData } from "../../features/location/locationAPIs";
export default function LocationDropDown({
  showAddAnotherVillageText = true,
  addVillage = false,
  setVillageNames = () => { },
  villageNames = [],
  setParentId = () => { },
  setActiveStatus = () => { },
  activeStatus = [],
  setSnackBarType = () => { },
  setShowSnackBar = () => { },
  setSnackBarMessage = () => { },
  setCurrentIndex = () => { },
}) {
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluka, setSelectedTaluka] = useState(null);
  const [villageTextBoxError, setVilageTextBoxError] = useState([]);
  const [getStatesResponse, setGetStateResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [getDistrictResponse, setGetDistrictResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [getTalukaResponse, setGetTalukaResponse] = useState([{
    id: "",
    name: "",
  },]);

  const [addAnotherVillageDropDown, setAddAnotherVillageDropDown] = useState(1);

  useEffect(() => {
    if (getStatesResponse[0].id === "") {
      const params = {}
      const fetchData = async () => {
        try {
          const response = await getStatesData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetStateResponse(response.data.data);
          } else {
            setShowSnackBar(true);
            setSnackBarType("error");
            setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setShowSnackBar(true);
          setSnackBarType("error");
          setSnackBarMessage("Not able to fetch state, Please check server");
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    // making active array default as true
    var modifiedActiveStatus = [...activeStatus];
    if (
      modifiedActiveStatus[addAnotherVillageDropDown - 1] !== false &&
      modifiedActiveStatus[addAnotherVillageDropDown - 1] !== true
    ) {
      modifiedActiveStatus[addAnotherVillageDropDown - 1] = true;
      setActiveStatus(modifiedActiveStatus);
    }
    setCurrentIndex(addAnotherVillageDropDown);
  }, [addAnotherVillageDropDown]);

  const handleChangeVillage = (newValue, index) => {
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (newValue !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(newValue))
      newValue = "";
    setParentId(selectedTaluka.id);
    var newErrorArray = [...villageTextBoxError];
    newValue = newValue.replace(/^\s+/g, "");
    if (
      newValue === "" &&
      villageNames[index] !== "undefined" &&
      villageNames[index] !== null
    ) {
      newErrorArray[index] = true;
      setVilageTextBoxError(newErrorArray);
    }
    var newVillageName = [...villageNames];
    newVillageName[index] = newValue;
    setVillageNames(newVillageName);
  };

  const handleActiveStatusChange = (event, index) => {
    var modifiedActiveStatus = [...activeStatus];
    modifiedActiveStatus[index] = event.target.checked;
    setActiveStatus(modifiedActiveStatus);
  };

  const handleChangeDeleteVillage = (index) => {
    setVillageNames((names) => names.filter((_, ind) => ind !== index));
    setActiveStatus((status) => status.filter((_, ind) => ind !== index));
    setAddAnotherVillageDropDown((prevIndex) => prevIndex - 1);
  };

  const handleChangeAddAnotherVillageTextbox = (event) => {
    var newErrorArray = [...villageTextBoxError];
    if (addAnotherVillageDropDown !== villageNames.length) {
      newErrorArray[addAnotherVillageDropDown - 1] = true;
      setVilageTextBoxError(newErrorArray);
    } else if (
      villageNames.length !== 0 &&
      addAnotherVillageDropDown === villageNames.length
    ) {
      setAddAnotherVillageDropDown((prevIndex) => prevIndex + 1);
      setVilageTextBoxError(false);
      newErrorArray[addAnotherVillageDropDown - 1] = false;
      setVilageTextBoxError(newErrorArray);
    }
  };

  useEffect(() => {
    var newErrorArray = [...villageTextBoxError];
    if (
      villageNames.length !== 0 &&
      addAnotherVillageDropDown === villageNames.length
    ) {
      newErrorArray[addAnotherVillageDropDown - 1] = false;
      setVilageTextBoxError(newErrorArray);
    }
  }, [villageNames]);

  useEffect(() => {
    setSelectedDistrict(null);
    setSelectedTaluka(null);
    setVillageNames([]);
    setParentId("");
    if (selectedState != null) {
      const fetchData = async () => {
        try {
          const response = await getDistrictData({
            type: "state",
            location_ids: [selectedState.id],
          })
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetDistrictResponse(response.data.data);
          } else {
            setShowSnackBar(true);
            setSnackBarType("error");
            setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setShowSnackBar(true);
          setSnackBarType("error");
          setSnackBarMessage("Not able to fetch districts, Please check server");
        }
      };
      fetchData();
    }
    // dispatch(
    //   getDistrictData({
    //     type: "state",
    //     location_ids: [selectedState.id],
    //   })
    // );
  }, [selectedState]);

  useEffect(() => {
    setSelectedTaluka(null);
    setVillageNames([]);
    setParentId("");
    if (selectedDistrict != null) {
      const fetchData = async () => {
        try {
          const response = await getTalukaData({
            type: "district",
            location_ids: [selectedDistrict.id],
          })
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetTalukaResponse(response.data.data);
          } else {
            setShowSnackBar(true);
            setSnackBarType("error");
            setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setShowSnackBar(true);
          setSnackBarType("error");
          setSnackBarMessage("Not able to fetch Taluka, Please check server");
        }
      };
      fetchData();
    }
    // dispatch(
    //   getTalukaData({
    //     type: "district",
    //     location_ids: [selectedDistrict.id],
    //   })
    // );
  }, [selectedDistrict]);

  useEffect(() => {
    setVillageNames([]);
    setParentId("");
  }, [selectedTaluka]);

  // useEffect(() => {
  //   if (getStatesResponse[0].id === "")
  //     dispatch(getStatesData());
  // }, []);

  const AddActiveDeleteIcons = (disabled = false, index, is_checked) => {
    return (
      <React.Fragment>
        <FormControlLabel
          disabled={disabled}
          sx={{
            color: "#AAC1B0",
            "&.Mui-checked": { color: "#2D6A4F" }
          }}
          control={
            !is_checked ? (
              <Checkbox checked={false} />
            ) : (
              <Checkbox checked={true} />
            )
          }
          onChange={(e) => handleActiveStatusChange(e, index)}
        />
        {index > 0 && (
          <DeleteRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleChangeDeleteVillage(index)}
          />
        )}
      </React.Fragment>
    );
  };

  const handleAddAnotherVillageTextbox = (index) => {
    return (
      <React.Fragment key={index}>
        {index > 0 && <Grid item xs={12} sm={8}></Grid>}
        <Grid item xs={12} sm={2.5}>
          {index == 0 && (
            <Typography sx={{ marginBottom: 1 }}>Village</Typography>
          )}
          <BasicTextfield
            label="Enter Village Name"
            is_phone={false}
            handleChange={handleChangeVillage}
            is_disabled={selectedTaluka === null}
            index={index}
            error={villageTextBoxError[index]}
            helperText={""}
            required={true}
            value={villageNames[index]}
          />
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={1}
        >
          <Typography>Status</Typography>
          {AddActiveDeleteIcons(
            villageNames.length === 0,
            index,
            activeStatus[index]
          )}
        </Grid> */}
      </React.Fragment>
    );
  };
  return (
    <Grid container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={6} sm={2.5}>
        <Typography sx={{ marginBottom: 1 }}>State</Typography>
        <AutoCompleteDropdown
          width="auto"
          menuList={getStatesResponse}
          selectedOptions={selectedState}
          setSelectedOptions={setSelectedState}
          disable={getStatesResponse[0].id === ""}
          label="Select State"
        />
      </Grid>

      <Grid item xs={12} sm={2.5}>
        <Typography sx={{ marginBottom: 1 }}>District</Typography>
        <AutoCompleteDropdown
          width="auto"
          menuList={getDistrictResponse}
          selectedOptions={selectedDistrict}
          setSelectedOptions={setSelectedDistrict}
          disable={getDistrictResponse[0].id === ""}
          label="Select District"
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
        <AutoCompleteDropdown
          width="auto"
          menuList={getTalukaResponse}
          selectedOptions={selectedTaluka}
          setSelectedOptions={setSelectedTaluka}
          disable={getTalukaResponse[0].id === ""}
          label="Select Taluka"
        />
      </Grid>

      {Array.from({ length: addAnotherVillageDropDown }).map((element, index) =>
        handleAddAnotherVillageTextbox(index)
      )}

      {showAddAnotherVillageText && (
        <>
          <Grid item xs={12} sm={7.5}></Grid>
          <Grid item xs={12} sm={2.5}>
            <Typography
              sx={{ color: "#2D6A4F", cursor: "pointer" }}
              onClick={(e) => handleChangeAddAnotherVillageTextbox(e)}
            >
              + Add another Village
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
