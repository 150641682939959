import React, { useEffect, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textfield: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export default function BasicTextfield({
  label = "",
  fullWidth = false,
  is_phone = false,
  handleChange = () => {},
  is_disabled = false,
  index = 0,
  error = false,
  helperText = "",
  required = false,
  value = "",
  number = false,
  unit = "",
  borderRadius = "1rem",
}) {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
      const timeoutId = setTimeout(() => {
        handleChange(localValue, index);
      }, 1000);
  
      return () => clearTimeout(timeoutId);
   
  }, [localValue]);

  const inputType = number ? "number" : "text";

  const onKeyDown = (e) => {
    if (number && unit === "") {
      ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
    } else if (unit !== "") {
      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    }
  };

  return (
    <TextField
      type={inputType}
      onKeyDown={onKeyDown}
      label={value === "" ? label : undefined}
      key={index}
      disabled={is_disabled}
      required={required}
      error={error}
      helperText={helperText}
      value={localValue}
      variant="outlined"
      className={classes.textfield}
      sx={{
        width: fullWidth ? "100%" : "90%",
        backgroundColor: "#FFFFFF",
        borderRadius: "1rem",
      }}
      inputProps={{
        style: {
          height: "40%",
          borderRadius: borderRadius,
        },
        "aria-label": "Without label",
      }}
      InputProps={{
        startAdornment: is_phone && (
          <InputAdornment position="start">
            <p>+91 | </p>
          </InputAdornment>
        ),
        endAdornment: unit !== "" && (
          <InputAdornment position="end">{unit}</InputAdornment>
        ),
      }}
      onChange={(e) => setLocalValue(e.target.value)}
    />
  );
}
