import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import { useDispatch } from "react-redux";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import FormCard from "../formManagement/formCard";
import {
  editFromData,
  getFormIcon,
} from "../../features/formManagement/formManagementAPIs";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import { getProjectData } from "../../features/projects/projectsAPIs";
import EditFormCard from "./editFormCard";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SUPPORTED_LANG_MAPPINGS } from "../../utils/config";
import { containsOnlyEnglishAlphabets } from "../../utils";

const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  px: 6,
  boxShadow: 24,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflowY: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
  textField: {
    "& .MuiInputBase-input": {
      border: "none", // Remove the border
    },
    "& .MuiInput-underline:before": {
      border: "none", // Remove the underline
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none", // Remove the underline on hover
    },
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function EditFormModal({
  openEditModal,
  handleClose = () => {},
  data = null,
  setFormSnackBarMessage = () => {},
  setFormSnackBarType = () => {},
  setReload = () => {},
  setOpenSuccessBox = () => {},
  setSuccessMessage = () => {},
  setOpenSuccessBoxType = () => {},
  languages = [],
}) {
  const languageSymbols = SUPPORTED_LANG_MAPPINGS
  const { v4: uuidv4 } = require("uuid");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [id, setId] = useState(data ? (data.form.id ? data.form.id : "") : "");
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [formName, setFormName] = useState(
    data ? (data.form.name ? data.form.name : "") : ""
  );
  const [formStatus, setFormStatus] = useState(
    data ? (data.is_active === true ? "Active" : "Inactive") : ""
  );
  const [formIcon, setFormIcon] = useState(data.icon);
  const [formIconList, setFormIconList] = useState([]);
  const [addFormSnackBar, setAddFormSnackBar] = useState(false);
  const [addFormSnackBarMessage, setAddFormSnackBarMessage] = useState("");
  const oldQuestions = data.questions.map((question) => ({ ...question }));
  const [questions, setQuestions] = useState(
    data ? (reverseTransformQuestionsData(data.questions)).map((question) => ({ ...question })) : []
  );
  const [formTranslations, setFormTranslations] = useState(data.translations? data.translations : null);
  const basicError = {
    formName: "",
    formIcon: "",
    projects: "",
  };
  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);

  const formStatusList = ["Active", "Inactive"];
  const [loading, setLoading] = useState(false);

  const [projects, setProjects] = useState(data.project);
  const [projectList, setProjectList] = useState([{ id: "", name: "" }]);
  const [collapseHeader, setCollapseHeader] = useState(false);

  const addEnglishTranslation = () => {
    // Check if formTranslations is not null or undefined and is an array
    if (formTranslations && Array.isArray(formTranslations)) {
      // Check if formTranslations contains a translation for English
      const hasEnglishTranslation = formTranslations.some(item => item.language === 'en');
      
      // If English translation doesn't exist, add it to formTranslations
      if (!hasEnglishTranslation) {
        const newTranslation = { language: 'en', name: formName };
  
        // Update the formTranslations state by adding the new translation
        setFormTranslations(prevTranslations => [...prevTranslations, newTranslation]);
      }
    }
  };
  
  // Call the function to add English translation (if needed) when component mounts
  useEffect(() => {
    addEnglishTranslation();
  }, []);

  const validateFormData = () => {
    //value = value.replace(/^\s+/g, "");
    let formNameError="";
    let formIconError="";
    let formProjectError="";
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s_]{1,}[\.]{0,1}[A-Za-z0-9\s_]{0,}$/;
      if (formName.trim() === "") {
        //setErrors({ ...errors, formName: "Form Name can't be empty" });
        //value = "";
        formNameError = "Form Name can't be empty"
      } 
      if (formIcon === null) {
        //setErrors({...errors, formIcon: "Form Icon can't be empty"});
        formIconError = "Form Category can't be empty"
      }
      if (projects === null) {
        formProjectError = "Project can't be empty"
      }
    setErrors({...errors, formIcon: formIconError, formName: formNameError, projects:formProjectError});
    //setFormName(value);

  }

  const handleChangeFromName = (value) => {
    value = value.replace(/^\s+/g, "");
    const charCheck = containsOnlyEnglishAlphabets(value);
    if (!charCheck) {
      setErrors({
        ...errors,
        formName: "Form name should be in English",
      });
      return 
    }
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s_\u0900-\u097F\u0A80-\u0AFF]{1,}[\.]{0,1}[a-zA-Z0-9\s_\u0900-\u097F\u0A80-\u0AFF]{0,}$/;
    if (value.trim() === "") {
      setErrors({ ...errors, formName: "Form Name can't be empty" });
      value = "";
    } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      setErrors({
        ...errors,
        formName: "Special characters are not allowed in Form Name",
      });
      value = "";
    } else {
      setErrors({ ...errors, formName: "" });
    }
    setFormName(value);
  };

  const handleChangeFormStatus = (event) => {
    setFormStatus(event.target.value);
  };

  const handleChangeFormIcon = (value) => {
    setFormIcon(value);
    setErrors({ ...errors, formIcon: "" });
  }

  const handleChangeProjects = (value) => {
    setProjects(value);
    setErrors({ ...errors, projects: "" });
  }

  useEffect(() => {
    if (projectList[0].id === "") {
      const params = { fields: ["id", "name"] };
      const fetchData = async () => {
        try {
          const response = await getProjectData(params);
          if (
            response != null &&
            response != undefined &&
            response.data !== null &&
            response.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined
          ) {
            setProjectList(response.data.data);
          } else {
          //   setAddFormSnackBarType("error");
          // setAddFormSnackBar(true);
          // setAddFormSnackBarMessage("Something went wrong on sever!")
          setOpenSuccessBox(true);
          setSuccessMessage("Something went wrong on sever!");
          setOpenSuccessBoxType('error');
          }
        } catch (error) {
          // setAddFormSnackBarType("error");
          // setAddFormSnackBar(true);
          // setAddFormSnackBarMessage("Not able to fetch projects, Please check server!");
          setOpenSuccessBox(true);
          setSuccessMessage("Not able to fetch projects, Please check server!");
          setOpenSuccessBoxType('error');
        }
      };
      fetchData();
    }
  }, []);

  const addNewQuestion = () => {
    const newQuestion = Object.fromEntries(
      Object.entries(languageSymbols).map(([key, value]) => [value, ""])
    );
    newQuestion["response_type"] = "audio"
    setQuestions([...questions, newQuestion]);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setFormName("");
    setFormStatus("");
    setProjects("");
    setAddFormSnackBar(false);
    setSnackBarType("success");
    setAddFormSnackBarMessage("");
    setFormSnackBarType("");
    setFormSnackBarMessage("");
    handleClose();
  };

  function transformQuestionsData(currentData) {
    return currentData.map((item) => {
      const uuid = uuidv4();
      const translations = {};
      Object.keys(languageSymbols).forEach((languageKey) => {
        const languageName = languageSymbols[languageKey];
        if (item[languageName]) {
          translations[languageKey] = item[languageName];
        }
      });
      const modelMetadata =
      (item.response_type=== "audio" || item.response_type=="text")
        ? {
            question_type: item.question_type,
            units: item.units,
          //   units: item.question_type=== "Quantity" ?
          //   {
          //     "english":item.Units.split(","),
          // }: [],
        //   values: item.question_type=== "MCQ" ? 
        //   {
        //     "english":Object.entries(item.dict.english).map(([master, slave]) => ({ master, slave }))
        // }: [],}
        // : {};
        values:
        item.question_type === "MCQ"
          ? Object.fromEntries(
              Object.keys(item.dict).map((language) => [
                language,
                Object.entries(item.dict[language]).map(([master, slave]) => ({ master, slave })),
              ])
            )
          : {},
    }
  : {};

    return {
      id: uuid,
      question: item.questions, // Assuming English is always present
      translations,
      response_type: item.response_type.toLowerCase(),
      ...(Object.keys(modelMetadata).length > 0 && { model_metadata: modelMetadata }),
      ...(["dropdown", "radio"].includes(item.response_type.toLowerCase())
      && {
        responses:item.responses,
      }),
    };
  });
  }

  function reverseTransformQuestionsData(formattedData) {
    return formattedData.map((item) => {
      const currentDataItem = {
        response_type: item.response_type,
      };

      // Copy all language translations
      Object.keys(languageSymbols).forEach((languageKey) => {
        const languageName = languageSymbols[languageKey];
        if (item[languageName]) {
          currentDataItem[languageName] = item[languageName];
        }
      });

      if (item.model_metadata) {
        const { question_type, units, values } = item.model_metadata;

        if (question_type) {
          currentDataItem.question_type = question_type;
        }

        if (units) {
          const unitList = Object.fromEntries(
            Object.keys(languageSymbols).map((languageKey) => [
              languageSymbols[languageKey],
              units[languageSymbols[languageKey]] || [],
            ])
          );
          currentDataItem.units = unitList;
        }

        if (values) {
          if (question_type === "MCQ") {
            const languages = Object.values(languageSymbols);
            const dict = {};
            languages.forEach(language => {
              if (values[language]) {
                dict[language] = {};

                values[language].forEach(item => {
                  dict[language][item.master] = item.slave;
                });
              } else {
                dict[language] = {};
              }
            });
            currentDataItem.dict = dict;


            
            const mcqresponses = {};
            languages.forEach((language) => {
              if (values[language]) {
                mcqresponses[language] = values[language].map((item) => item.master);
              }
              else
              {
                mcqresponses[language] = []
              }
            });
            currentDataItem.responses = mcqresponses;
  
          }
        }
      }

      if (item.responses) {
        currentDataItem.responses = item.responses;
      }
      else if(!((item.response_type === "audio" || item.response_type === "text") &&
      item.model_metadata &&
      item.model_metadata.question_type &&
      item.model_metadata.question_type === "MCQ")) {
        currentDataItem.responses = Object.fromEntries(
          Object.keys(languageSymbols).map((languageKey) => [
            languageSymbols[languageKey],
            [],
          ])
        );  
      }

      return currentDataItem;
    });
  }
  
  function checkIfQuestionsUpdated(old_questions, new_questions) {
    if (old_questions.length !== new_questions.length) return true;

    for (let i = 0; i < old_questions.length; i++) {
      if (!objectsAreEqual(old_questions[i], new_questions[i])) {
        return true;
      }
    }
    return false;
  }
  function objectsAreEqual(obj1, obj2) {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);
    return str1 === str2;
  }
  const handleSaveData = async () => {
    // const lastQuestion = questions[questions.length - 1].english.trim() === "";
    
    const isEnglishValidForAllQuestions = await questions.every(
      (question) =>
        question.english !== undefined && question.english.trim() !== ""
    );
    let nonEnglishQuesIndex, nonEnglishUnitIndex, nonEnglishPossibleResponseIndex;
    if (isEnglishValidForAllQuestions) {
      nonEnglishQuesIndex = questions.findIndex(
        (row) => !containsOnlyEnglishAlphabets(row.english)
      );
      nonEnglishQuesIndex = nonEnglishQuesIndex === -1 ? null : nonEnglishQuesIndex
    }
    const isQuestionTypeValid = questions.every((question, index) => {
      return !(
        ["audio", "text"].includes(question.response_type.toLowerCase()) &&
        (!question.question_type || question.question_type.trim() === "")
      );
    });
    
    const isDropdownValid = await questions.every((question, index) => {
      return !(
        (question.response_type === "dropdown") &&
        (!question.responses || question.responses.english.length < 2)
      );
    });
    const isRadioValid = await questions.every((question, index) => {
      return !(
        (question.response_type === "radio") &&
        (!question.responses || question.responses.english.length < 2)
      );
    });
    const isMcqValid = await questions.every((question, index) => {
      return !(
        (
          question.question_type === "MCQ") &&
        (!question.responses || question.responses.english.length < 1)
      );
    });
    if (isDropdownValid || isRadioValid || isMcqValid) {
      nonEnglishPossibleResponseIndex = questions.findIndex(
        (row) => row?.responses?.english && !containsOnlyEnglishAlphabets(row.responses.english)
      );
      nonEnglishPossibleResponseIndex = nonEnglishPossibleResponseIndex === -1 ? null : nonEnglishPossibleResponseIndex
    }
    const isUnitValid = await questions.every((question, index) => {
      return !(
        question.question_type === "Quantity" &&
        (!question.units || question.units.english.length < 1)
      );
    });
    if (isUnitValid) {
      nonEnglishUnitIndex = questions.findIndex(
        (row) => row?.units?.english && !containsOnlyEnglishAlphabets(row.units.english)
      );
      nonEnglishUnitIndex = nonEnglishUnitIndex === -1 ? null : nonEnglishUnitIndex
    }
    // const isDictValid = await questions.every((question, index) => {
    //   if (question.question_type === "MCQ" && question.dict) {
    //     // Check if at least one list in the dictionary is empty
    //     return !(
    //       Object.values(question.dict).some((list) => list.length < 1)
    //     );
    //   }
    //   return true; // For other question types or when dict is not present
    // });
    const isDictValid = await questions.every((question, index) => {
      if (question.question_type === "MCQ" && question.dict) {
        // Check if any list inside the "english" dictionary is empty
        return !Object.entries(question.dict.english).some(([key, value]) => value.length === 0);
      }
      return true; // For other question types or when dict is not present
    });
  
    if (nonEnglishQuesIndex || nonEnglishUnitIndex || nonEnglishPossibleResponseIndex) {
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter ${nonEnglishQuesIndex ? "Question" : nonEnglishUnitIndex ? "Unit" : "Responses"} in English for question ${(nonEnglishQuesIndex || nonEnglishUnitIndex || nonEnglishPossibleResponseIndex) + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isDropdownValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          (question.response_type === "dropdown" ) &&
          (!question.responses || question.responses.english.length < 2)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 2 responses for dropdown in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isQuestionTypeValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          ["audio", "text"].includes(question.response_type.toLowerCase()) &&
          (!question.question_type || question.question_type.trim() === "")
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter a question type for ${questions[invalidQuestionIndex]?.response_type} at question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isRadioValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          (question.response_type === "radio" ) &&
          (!question.responses || question.responses.english.length < 2)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 2 responses for radio in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isMcqValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          (
            question.question_type === "MCQ") &&
          (!question.responses || question.responses.english.length < 1)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 response for MCQ in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isUnitValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          question.question_type === "Quantity" &&
          (!question.units || question.units.english.length < 1)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 unit in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isDictValid) {
      const invalidQuestionIndex = questions.findIndex(
        (question) =>
          question.question_type === "MCQ" &&
          question.dict &&
          Object.entries(question.dict.english).some(([key, value]) => value.length === 0)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 synonym in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    }else if (
      errors.formName === "" &&
      formName !== "" &&
      questions.length !== 0 &&
      formIcon !== null &&
      projects !== null &&
      isEnglishValidForAllQuestions
      
    ) {
      // var project_ids = projects.map((item, index) => {
      //   return item.id;
      // });
      var data = {
        name: formName,
        icon: formIcon.id,
        created_by: "1",
        project: projects.id,
      };
      if (checkIfQuestionsUpdated(oldQuestions, questions)) {
        var formatted_questions = transformQuestionsData(questions);
        data["questions"] = formatted_questions;
      }
      try {
        setLoading(true);
        const response = await editFromData(data, id);
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success');
        setReload(true);
        setLoading(false);
        handleClose();
      } catch (error) {
        setOpenSuccessBox(true);
        setSuccessMessage(error.response.data["message"]);
        setOpenSuccessBoxType('error')
        setLoading(false);
      }
    } else {
      const errorText = !formName ? "Please enter form name" : !containsOnlyEnglishAlphabets(formName) ? "Please enter form name in English" : !formIcon ? "Please select form category" : !projects ? "Please select a project" : "Please fill all the required fields"
      validateFormData();
      setOpenSuccessBox(true);
      setSuccessMessage(errorText);
      setOpenSuccessBoxType('error')
    }
  };

  const handleChangeLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  
    let language = event.target.value;
    const languageMapping = Object.fromEntries(
      Object.entries(languageSymbols).map(([key, value]) => [value, key])
    );
    const languageCode = languageMapping[language.toLowerCase()];
    const translation = formTranslations.find((item) => item.language === languageCode);
    if(translation && translation.name)
      setFormName(translation.name);
  };

  useEffect(async () => {
    try {
      const response = await getFormIcon();
      setFormIconList(response.data["data"]);
    } catch {
      setSnackBarType("error");
      setAddFormSnackBar(true);
      setAddFormSnackBarMessage("Error while getting icon list"); // Use error.response.data here
    }
  }, []);


  return (
    <Modal open={openEditModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 4,
              paddingBottom: 2,
            }}
          >
            <Grid item xs={7}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Edit Form
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end"}}>
              <Box sx={{ display: "flex", alignItems: "start", cursor: "pointer" }} onClick={() => setCollapseHeader(prev => !prev)}>
                <Typography variant="h6" >
                  {collapseHeader ? "Form Details" : "Collapse"}
                </Typography>
                {collapseHeader ? <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }}/> : <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }}/>}
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid
              container
              item
              spacing={1}
              xs={12}
              sx={{
                width: "90%",
                margin: "auto"
              }}
            >
              {!collapseHeader && <><Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Name
                </Typography>
                <BasicTextfield
                  label="Enter Form Name"
                  is_phone={false}
                  handleChange={handleChangeFromName}
                  is_disabled={selectedLanguage!=="english"}
                  index={1}
                  error={errors.formName !== ""}
                  helperText={errors.formName}
                  required={true}
                  value={formName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Status
                </Typography>
                <BasicDropDown
                  text="Select Form Status"
                  value={formStatus}
                  handleChange={handleChangeFormStatus}
                  menuList={formStatusList}
                  error={false}
                  required={true}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Category
                </Typography>
                <AutoCompleteDropdown
                  label="Select Form Category"
                  selectedOptions={formIcon}
                  setSelectedOptions={handleChangeFormIcon}
                  menuList={formIconList}
                  error={errors.formIcon !== ""}
                  required={true}
                  type='icon'
                  errorText={errors.formIcon}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Projects</Typography>
                  <AutoCompleteDropdown
                    width={230}
                    menuList={projectList}
                    // value={projects}
                    selectedOptions={projectList[0].id==="" ? null : projects}
                    onHighlightChange={(value) => {
                      handleChangeProjects(value)
                    }}
                    label="Select Projects"
                    // error={false}
                    required={true}
                    error={errors.projects !== ""}
                    errorText={errors.projects}
                  />
                </Grid></>}
              <Grid item xs={8}>
                <Typography sx={{ fontFamily: "Inter", color: "#444444" }}>
                  Added Questions
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#F5F5F5"
                  p={2}
                  borderRadius={20}
                  width="250px"
                  height="40px"
                  padding="12px 16px"
                >
                  <Typography
                    sx={{ fontFamily: "Inter", color: "#78828C", marginRight: 2 }}
                  >
                    Translations
                  </Typography>
                  <OptionalDropDown
                    text={"Translations"}
                    id={selectedLanguage}
                    handleChange={handleChangeLanguage}
                    menuList={languages}
                    borderRadius="24px"
                    height="100%"
                    width="50%"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sx={{
              width: "90%",
            }}
          >
            {questions != null &&
              questions.map((item, index) => (
                <EditFormCard
                  key={index}
                  index={index}
                  edit={true}
                  question={item}
                  setQuestions={setQuestions}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  autofocus={index==questions.length-1}
                  setOpenSuccessBox={setOpenSuccessBox}
                  setSuccessMessage={setSuccessMessage}
                  setOpenSuccessBoxType={setOpenSuccessBoxType}
                />
              ))}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                marginLeft: 1,
                marginTop: 3,
                width: "80%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  color:
                    questions.length > 0 &&
                    (questions[questions.length - 1].english === undefined ||
                      questions[questions.length - 1].english.trim() === "")
                      ? "#AAAAAA" // Apply disabled style
                      : "#2D6A4F", // Apply normal style
                  cursor:
                    questions.length > 0 &&
                    (questions[questions.length - 1].english === undefined ||
                      questions[questions.length - 1].english.trim() === "")
                      ? "default" // Change cursor to default for disabled link
                      : "pointer", // Use pointer cursor for clickable link
                  "&:hover": {
                    opacity:
                      questions.length > 0 &&
                      (questions[questions.length - 1].english === undefined ||
                        questions[questions.length - 1].english.trim() === "")
                        ? 1 // Keep opacity at 1 for disabled link
                        : 0.7, // Reduce opacity for clickable link on hover
                  },
                }}
                onClick={
                  questions.length > 0 &&
                  (questions[questions.length - 1].english === undefined ||
                    questions[questions.length - 1].english.trim() === "")
                    ? null // Don't execute the click handler for disabled link
                    : addNewQuestion // Execute the click handler for clickable link
                }
              >
                + Click here to add question
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Grid item sx={{ margin: "30px" }}>
              <CancelButtonStyle
                variant="contained"
                name="cancel"
                onClick={handleClose}
              >
                Cancel
              </CancelButtonStyle>
            </Grid>
            <Grid item sx={{ margin: "30px" }}>
              <ButtonStyle
                variant="contained"
                name="add"
                startIcon={<CheckIcon size="large" />}
                onClick={handleSaveData}
              >
                Save
              </ButtonStyle>
            </Grid>
            {addFormSnackBar && (
              <SnackBars
                open={addFormSnackBar}
                handleClose={() => setAddFormSnackBar(false)}
                severity={snackBarType}
                message={addFormSnackBarMessage}
                autoHideDuration={6000}
                vertical="bottom"
                horizontal="center"
                position="absolute"
              />
            )}
          </Grid>
        </ThemeProvider>
        <BackDropLoader loading={loading} />
      </Grid>
    </Modal>
  );
}
