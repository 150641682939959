import React from "react";
import { Box, Checkbox, Divider, Grid, IconButton, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FileCopyIcon from '@mui/icons-material/FileCopy';

const StyledGridPopOver = styled(Grid)({
  display: "flex",
  width: "100px",
  marginLeft: 10,
});

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    background: "#AAC1B0",
    width: 'fit-content',
    padding: '3px 4px',
    borderRadius: "50%",
    '&:hover': {
      background: '#919E94',
    },
  },
  dotIcon: {
    color: "#FFF",
    cursor: "pointer",
    fontSize: "16px"
  },
  editIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
    fontSize: "16px"
  },
  copyIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
    fontSize: "16px"
  },
  deleteIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
    fontSize: "16px"
  },
}));

export default function EditCopyMultipleDelete({
  options,
  data={},
  disabledFields={},
  deleteMultipleUserData,
  setOpenEditModal=()=>{},
  setOpenDeleteModal=()=>{},
  setopenCopyModal=()=>{},
  setCopyData=()=>{},
  setEditData=()=>{},
  setDeleteData=()=>{},
  setDeleteMultipleUserData=()=>{},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = React.useState(false);
  const classes = useStyles();
  
  const handleShowDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setIsDeleteButtonClicked(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const enableMultipleDelete = (event) => {
    setDeleteMultipleUserData(prev => ({
      ...prev,
      isOpen: true,
      users: {
        ...prev.users,
        [data?.id]: {
          ...prev.users[data?.id],
          isSelected: event.target.checked
        }
      }
    }));
  };

  const SingleDeleteComponent = () => {
    return <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingBottom: '1rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Delete this
      </Typography>
      <DeleteRoundedIcon
        className={classes.deleteIcon}
        onClick={() => {
          setOpenDeleteModal(true);
          setDeleteData(data);
          handleCloseDropdown();
        }}
        style={{ pointerEvents: disabledFields?.delete ? 'none' : 'auto', color: disabledFields?.delete && "#AAAAAA", marginRight: "10px" }}
      />
    </Box>
  }

  const InitialDialgContent = () => {
    return <>
    {options?.includes('edit') && <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingBottom: '1rem' }}>
        <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
          Edit
        </Typography>
        <EditRoundedIcon
          className={classes.editIcon}
          onClick={() => {
            if (!disabledFields.edit) {
              setOpenEditModal(true);
              setEditData(data);
              handleCloseDropdown();
            }
          }}
          style={{ pointerEvents: disabledFields?.edit ? 'none' : 'auto', color: disabledFields?.edit && "#AAAAAA" }}
        />
      </Box>
      <Divider />
    </>}
    {options?.includes('copy') && <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingY: '1rem' }}>
        <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
          Copy
        </Typography>
        <FileCopyIcon
          className={classes.copyIcon}
          onClick={() => {
            if (!disabledFields.copy) {
              setopenCopyModal(true);
              setCopyData(data);
              handleCloseDropdown();
            }
          }}
          style={{ pointerEvents: disabledFields?.copy ? 'none' : 'auto', color: disabledFields?.copy && "#AAAAAA" }}
        />
      </Box>
      <Divider />
    </>}
    {/* {options?.includes('singleDelete') && !options?.includes('multiDelete') && <SingleDeleteComponent/>} */}
    {(options?.includes('multiDelete') || options?.includes('singleDelete')) && <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingTop: '1rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Delete
      </Typography>
      <DeleteRoundedIcon
        className={classes.deleteIcon}
        onClick={() => {
          if (options?.includes('multiDelete')) {
            setIsDeleteButtonClicked(true)
          } else {
            setOpenDeleteModal(true);
            setDeleteData(data);
            handleCloseDropdown();
          }
        }}
        style={{ pointerEvents: disabledFields?.delete ? 'none' : 'auto', color: disabledFields?.delete && "#AAAAAA" }}
      />
    </Box>}
    </>
  }

  const CheckboxComponent = () => {
    return <Checkbox
    // disabled
      checked={deleteMultipleUserData?.users[data.id]?.isSelected}
      onChange={enableMultipleDelete}
      sx={{
        color: "#c5d6ca",
        "&.Mui-checked": { color: "#2D6A4F" },
        "&hover": { color: "#2D6A4F" }
      }}
    />
  }

  const DeleteOptionsContent = () => {
    return <>
      <SingleDeleteComponent/>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingTop: '0.5rem' }}>
        <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
          Select more to delete
        </Typography>
        <CheckboxComponent />
      </Box>
    </>
  }

  return (
    <StyledGridPopOver container>
      <Grid item xs={4} />
      <Grid item xs={4} sx={{ position: 'relative' }}>
        {deleteMultipleUserData?.isOpen ? <CheckboxComponent /> : <>
        <Box className={classes.iconWrapper} sx={{ background: anchorEl ? "#919E94" : "#AAC1B0" }} onClick={handleShowDropdown}>
          <MoreHorizIcon className={classes.dotIcon} />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleCloseDropdown}
          sx={{
            "& .MuiPopover-paper": {
              padding: 2.5,
              marginTop: "8px",
              borderRadius: "20px",
              border: "0.5px solid #74C69D"
            },
          }}
        >
          <Box>
            {isDeleteButtonClicked ? <DeleteOptionsContent /> : <InitialDialgContent />}
          </Box>
        </Popover>
        </>}
      </Grid>
      <Grid item xs={4} />
    </StyledGridPopOver>
  );
}
