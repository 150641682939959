import { formAPI } from "../../api's/index";
import { commonApiCall } from "../../utils/index";
var qs = require("qs");

export const getFromData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: formAPI,
    params: params,
  });
  return response;
};

export const postFromData = async (data) => {
  const response = await commonApiCall({
    method: "post",
    url: formAPI,
    data: data,
  });
  return response;
};

export const editFromData = async (data, id) => {
  const response = await commonApiCall({
    method: "put",
    url: formAPI + id,
    data: data,
  });
  return response;
};

export const deleteFormData = async (id) => {
  const response = await commonApiCall({
    method: "delete",
    url: formAPI + id,
  });
  return response;
};

export const getFormIcon = async (id) => {
  const response = await commonApiCall({
    method: "get",
    url: formAPI + "icon",
  });
  return response;
};
