import React, { useState, useEffect,useRef } from "react";
import {
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import { useDispatch, useSelector } from "react-redux";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import FormCard from "./formCard";
import * as XLSX from "xlsx";
import {
  postFromData,
  getFormIcon,
} from "../../features/formManagement/formManagementAPIs";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import sampleQuestionsFile from "../../files/Sample_File.xlsx";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import { getProjectData } from "../../features/projects/projectsAPIs";
import AddFormCard from "./addFormCard";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SUPPORTED_LANG_MAPPINGS } from "../../utils/config";
import { containsOnlyEnglishAlphabets } from "../../utils";

const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  px: 6,
  boxShadow: 24,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflowY: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
  textField: {
    "& .MuiInputBase-input": {
      border: "none", // Remove the border
    },
    "& .MuiInput-underline:before": {
      border: "none", // Remove the underline
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none", // Remove the underline on hover
    },
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function AddFormModal({
  openAddModal,
  isCopy=false,
  dataToBeCopied={},
  handleClose = () => {},
  setFormSnackBarMessage = () => {},
  setFormSnackBarType = () => {},
  setReload = () => {},
  setOpenSuccessBox = () => {},
  setSuccessMessage = () => {},
  setOpenSuccessBoxType = () => {},
  languages=[]
}) {
  const { v4: uuidv4 } = require("uuid");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formName, setFormName] = useState(isCopy ? dataToBeCopied?.form?.name : "");
  const [formStatus, setFormStatus] = useState("");
  const [formIcon, setFormIcon] = useState(isCopy ? dataToBeCopied?.icon : null);
  const [formIconList, setFormIconList] = useState([]);
  const [addFormSnackBar, setAddFormSnackBar] = useState(false);
  const [addFormSnackBarMessage, setAddFormSnackBarMessage] = useState("");
  const [keyToReload, setKeyToReload] = useState(0);
  const [questions, setQuestions] = useState(isCopy ? dataToBeCopied?.questions : []);
  const [fileQuestions, setFileQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const basicError = {
    formName: "",
    formStatus: "",
    formIcon: "",
    projects: "",
  };
  const [addFormSnackBarType, setAddFormSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);
  const formStatusList = ["Active", "Inactive"];
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [projectList, setProjectList] = useState([{ id: "", name: "" }]);
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [uuid, setUuid] = useState(0);
  // const inputRef = useRef(null);
  const [collapseHeader, setCollapseHeader] = React.useState(false);
  const languageSymbols = SUPPORTED_LANG_MAPPINGS

  const handleChangeLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };
  
  const validateFormData = () => {
    let formNameError="";
    let formStatusError="";
    let formIconError="";
    let formProjectError="";
    if (formName.trim() === "") {
      formNameError = "Form Name can't be empty"
    } 
    if (formStatus === "") {
      formStatusError = "Form Status can't be empty"
    }
    if (formIcon === null) {
      formIconError = "Form Category can't be empty"
    }
    if (projects === null) {
      formProjectError = "Project can't be empty"
    }
    setErrors({...errors, formIcon: formIconError, formName: formNameError, formStatus: formStatusError, projects:formProjectError});
  }

  const handleChangeFromName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s_]{1,}[\.]{0,1}[A-Za-z0-9\s_]{0,}$/;
    if (value.trim() === "") {
      setErrors({ ...errors, formName: "Form Name can't be empty" });
      value = "";
    } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      setErrors({
        ...errors,
        formName: "Special characters are not allowed in Form Name",
      });
      value = "";
    } else {
      setErrors({ ...errors, formName: "" });
    }
    setFormName(value);
    if (!containsOnlyEnglishAlphabets(value)) {
      setErrors({
        ...errors,
        formName: "Form name should be in English",
      });
    }
  };

  const handleChangeFormStatus = (event) => {
    setFormStatus(event.target.value);
    setErrors({ ...errors, formStatus: "" });
  };

  const handleChangeFormIcon = (value) => {
    setFormIcon(value);
    setErrors({ ...errors, formIcon: "" });
  }

  const handleChangeProjects = (value) => {
    console.log(value)
    setProjects(value);
    setErrors({ ...errors, projects: "" });
  }


  useEffect(() => {
    if (projectList[0].id === "") {
      const params = { fields: ["id", "name", "end_date"] };
      const fetchData = async () => {
        try {
          const response = await getProjectData(params);
          if (
            response != null &&
            response != undefined &&
            response.data !== null &&
            response.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined
          ) {
            setProjectList(response.data.data);
          } else {
            setAddFormSnackBarType("error");
            setAddFormSnackBar(true);
            setAddFormSnackBarMessage("Something went wrong on sever!")
          }
        } catch (error) {
          setAddFormSnackBarType("error");
          setAddFormSnackBar(true);
          setAddFormSnackBarMessage("Not able to fetch projects, Please check server!");
        }
      };
      fetchData();
    }
  }, []);

  const isValidCsv = (csv) => /^[^,]+(,[^,]+)*$/.test(csv.trim());

  const getUniqueValidValues = (obj, key) => {
    if (!obj || !obj.hasOwnProperty(key) || !key) return [];
    return [...new Set(obj[key].trim().split(',').map(syn => syn.trim()).filter(Boolean))];
  }

  const handleUploadQuestions = async (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;
    setIsLoading(true);
    const reader = new FileReader();
    try {
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[1]];
        const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];
        const actualHeadersMapping = {
          response_type: "response type",
          questions: "questions",
          question_type: "question type",
          Units: "units (only for quantity)",
          possible_responses: "possible responses (only for MCQ, radio, dropdown)",
          synonyms: "synonyms (only for MCQ)"
        }
        const validQuestionTypes = ["MCQ", "Quantity","Date"];
        const responseTypes = ["Audio", "Text", "Image", "Dropdown", "Radio"];
        const isValidHeaders = headers && Object.values(actualHeadersMapping).every((header) =>
          headers.includes(header)
        );
        const sheetJsonData = XLSX.utils.sheet_to_json(sheet);
        let error, idx = 0;
        const sheetLength = sheetJsonData.length;
        const validatedData = []
        while (idx < sheetLength) {
          const row = sheetJsonData[idx];
          row.response_type = row[actualHeadersMapping.response_type];
          row.question_type = row[actualHeadersMapping.question_type];
          row.Units = row[actualHeadersMapping.Units];
          row.possible_responses = row[actualHeadersMapping.possible_responses];
          row.synonyms = row[actualHeadersMapping.synonyms];
          if (error) {
            break;
          }
          if ((!row.response_type || !row.questions?.trim())) {
            error = "Response type and questions are mandatory.";
            break;
          } 
          if (!responseTypes.includes(row.response_type)) {
            error = "Invalid Response Type";
            break;
          }
          if (!containsOnlyEnglishAlphabets(row.response_type) || !containsOnlyEnglishAlphabets(row.questions)) {
            error = `${!containsOnlyEnglishAlphabets(row.response_type) ? 'response_type' : 'questions'} should be in English. Row: ${row.__rowNum__ + 1}`;
            break;
          }
  
          if (["Radio", "Dropdown"].includes(row.response_type)) {
            if (!row.possible_responses?.trim()) {
              error = `For ${row.response_type}, possible_responses should not be empty. Row: ${row.__rowNum__ + 1}`;
              break;
            }
            if (!containsOnlyEnglishAlphabets(row.possible_responses?.trim())) {
              error = `possible_responses should be in English. Row: ${row.__rowNum__ + 1}`;
              break;
            }
            // const possible_responses = [...new Set(row.possible_responses.trim().split(',').map(syn => syn.trim()).filter(Boolean))];
            const possible_responses = getUniqueValidValues(row, 'possible_responses');
            if (possible_responses.length < 2) {
              error = `For ${row.response_type}, possible_responses should have at least 2 unique values. Row: ${row.__rowNum__ + 1}`;
              break;
            }
            row.possible_responses = possible_responses.join(',');
          }
  
          if (["Audio", "Text"].includes(row.response_type)) {
            if (!row.question_type) {
              error = `For ${row.response_type}, question_type should not be empty. Row: ${row.__rowNum__ + 1}`;
              break
            } 
            if (row.question_type === validQuestionTypes[1]) {
              if (!row.Units?.trim()) {
                error = `For question_type Quantity, Units column must not be empty. Row: ${row.__rowNum__ + 1}`;
                break
              }
              if (!containsOnlyEnglishAlphabets(row.Units)) {
                error = `Units should be in English. Row: ${row.__rowNum__ + 1}`;
                break;
              }
              row["Units"] = getUniqueValidValues(row, 'Units').join(',');
              if ('possible_responses' in row) delete row.possible_responses;
              if ('synonyms' in row) delete row.synonyms;
            }
            if (row.question_type === validQuestionTypes[0]) {
              if (!row.possible_responses?.trim()) {
                error = `For question_type MCQ, possible_responses column can't be empty. Row: ${row.__rowNum__ + 1}`;
                break;
              } else {
                const synonym_obj = {};
                let endOfPossibleResponse = false;
                let possible_responses = "";
                while (!endOfPossibleResponse) {
                  const currentRow = sheetJsonData[idx]
                  currentRow.response_type = currentRow[actualHeadersMapping.response_type];
                  currentRow.question_type = currentRow[actualHeadersMapping.question_type];
                  currentRow.Units = currentRow[actualHeadersMapping.Units];
                  currentRow.possible_responses = currentRow[actualHeadersMapping.possible_responses];
                  currentRow.synonyms = currentRow[actualHeadersMapping.synonyms];
                  const nextRow = sheetJsonData[idx + 1];
                  if (!currentRow.possible_responses?.trim()) {
                    error = `For question_type MCQ and questions ${row.questions}, possible_responses column can't be empty. Row: ${currentRow.__rowNum__ + 1}`;
                    break;
                  }
                  if (!currentRow.synonyms?.trim()) {
                    error = `For question_type MCQ, synonyms column can't be empty. Row: ${currentRow.__rowNum__ + 1}`;
                    break;
                  }
                  const currentPossibleResponses = getUniqueValidValues(currentRow, 'possible_responses')[0];
                  if (!containsOnlyEnglishAlphabets(currentPossibleResponses)) {
                    error = `possible_responses should be in English. Row: ${currentRow.__rowNum__ + 1}`;
                    break;
                  }
                  let synonymsArr;
                  try {
                    synonymsArr = getUniqueValidValues(currentRow, 'synonyms');
                    if (!containsOnlyEnglishAlphabets(synonymsArr.join(','))) {
                      error = `synonyms should be in English. Row: ${currentRow.__rowNum__ + 1}`;
                      break;
                    }
                  } catch (e) {
                    console.error("error while trying to make array of synonyms: ", currentRow)
                    error = `Invalid synonyms for question: ${currentRow.questions}. Row: ${currentRow.__rowNum__ + 1}`;
                    break;
                  }
                  synonym_obj[currentPossibleResponses] = synonymsArr;
                  possible_responses = possible_responses + currentPossibleResponses + ",";
                  if (nextRow && !nextRow[actualHeadersMapping.response_type]) {
                    idx++;
                  } else {
                    endOfPossibleResponse = true;
                  }
                }
                row["dict"] = synonym_obj;
                row["possible_responses"] = Array.from(new Set(possible_responses.trim().split(',').filter(Boolean))).join(',');
                if ('Units' in row) delete row.Units;
                if ('synonyms' in row) delete row.synonyms;
              }
            }
          }
          validatedData.push(row)
          idx++;
        }
        if (!error && isValidHeaders) {
          setFile(uploadedFile);
          setIsLoading(false);
          setFileQuestions(validatedData)
        } else {
          setFile(null);
          setFileQuestions([])
          setIsLoading(false);
          setKeyToReload(keyToReload + 1);
          setOpenSuccessBox(true);
          setOpenSuccessBoxType('error')
          setSuccessMessage(!isValidHeaders ? "Invalid header." :  error);
        }
      };
    } catch (error) {
      setFile(null);
      setIsLoading(false);
      setKeyToReload(keyToReload + 1);
      setOpenSuccessBox(true);
      setSuccessMessage("Error reading or parsing the file:", error);
      setOpenSuccessBoxType('error')
    }
    reader.readAsArrayBuffer(uploadedFile);
  };

  const addNewQuestion = () => {
    const newQuestion = {
      response_type: "Audio",
      questions:"",
      id:uuid+1
    };
    setUuid(prevId => prevId + 1)

    setQuestions([...questions,newQuestion]);
  };
  // useEffect(()=>{
  //   try {
  //     if(questions?.length>0 && inputRef?.current) {
  //       console.log(inputRef.current)
        // inputRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  //     }
  //   } catch (error) {
      // inputRef.current = null
  //     console.error("failed to scroll to the last question, ", error)
  //   }
  // },[questions])
  
  const handleClearFile = () => {
    setFile(null);
    setFileQuestions([]);
    setKeyToReload(keyToReload + 1);
  };
  
  useEffect(async () => {
    try {
      const response = await getFormIcon();
      setFormIconList(response.data["data"]);
    } catch {
      setAddFormSnackBarType("error");
      setAddFormSnackBar(true);
      setAddFormSnackBarMessage("Error while getting icon list"); // Use error.response.data here
    }
  }, []);

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setFormName("");
    setFormStatus("");
    setProjects("");
    setAddFormSnackBar(false);
    setAddFormSnackBarType("success");
    setAddFormSnackBarMessage("");
    setFormSnackBarType("");
    setFormSnackBarMessage("");
    handleClose();
  };

  function transformQuestionsData(currentData) {
    return currentData.map((item) => {
      const uuid = uuidv4();
      const translations = {};
      Object.keys(languageSymbols).forEach((languageKey) => {
        const languageName = languageSymbols[languageKey];
        if (item[languageName]) {
          translations[languageKey] = item[languageName];
        }
      });
      const modelMetadata =
        (item.response_type === "Audio" || item.response_type == "Text")
          ? {
            question_type: item.question_type,
            //units: item.Units || "",
            units: item.question_type === "Quantity" ?
              {
                "english": item.Units.split(","),
              } : [],
            values: item.question_type === "MCQ" ?
              {
                "english": Object.entries(item.dict).map(([master, slave]) => ({ master, slave }))
              } : [],
          }
          : {};
      return {
        id: uuid,
        question: item.questions, // Assuming English is always present
        translations,
        response_type: item.response_type.toLowerCase(),
        ...(Object.keys(modelMetadata).length > 0 && { model_metadata: modelMetadata }),
        ...(["dropdown", "radio"].includes(item.response_type.toLowerCase())
          && {
          responses: {
            "english": item.possible_responses.split(",")
          },
        }),
      };
    });
  }
  const handleSaveData = async () => {
    const allQuestions = [...fileQuestions ,...questions]
    const isEnglishValidForAllQuestions = await allQuestions.every(
      (question) =>
        question.questions !== undefined && question.questions.trim() !== ""
    );
    let nonEnglishQuesIndex, nonEnglishUnitIndex, nonEnglishPossibleResponseIndex, nonEnglishSynonymsIndex;
    if (isEnglishValidForAllQuestions) {
      nonEnglishQuesIndex = allQuestions.findIndex(
        (row) => !containsOnlyEnglishAlphabets(row.questions)
      );
      nonEnglishQuesIndex = nonEnglishQuesIndex === -1 ? null : nonEnglishQuesIndex
    }
    const isQuestionTypeValid = allQuestions.every((question, index) => {
      return !(
        ["audio", "text"].includes(question.response_type.toLowerCase()) &&
        (question.question_type === undefined || question.question_type.trim() === "")
      );
    });

    const isDropdownValid = await allQuestions.every((question, index) => {
      return !(
        (question.response_type.toLowerCase() === "dropdown") &&
        (!question.possible_responses || question.possible_responses.split(",").length < 2)
      );
    });
    const isRadioValid = await allQuestions.every((question, index) => {
      return !(
        (question.response_type.toLowerCase() === "radio") &&
        (!question.possible_responses || question.possible_responses.split(",").length < 2)
      );
    });
    const isMcqValid = await allQuestions.every((question, index) => {
      return !(
        question.question_type === "MCQ" &&
        (!question.possible_responses || question.possible_responses.split(",").length < 1)
      );
    });
    if (isDropdownValid || isRadioValid || isMcqValid) {
      nonEnglishPossibleResponseIndex = allQuestions.findIndex(
        (row) => row.possible_responses && !containsOnlyEnglishAlphabets(row.possible_responses)
      );
      nonEnglishPossibleResponseIndex = nonEnglishPossibleResponseIndex === -1 ? null : nonEnglishPossibleResponseIndex
    }
    const isUnitValid = await allQuestions.every((question, index) => {
      return !(
        question.question_type === "Quantity" &&
        (!question.Units || question.Units.split(",").length < 1)
      );
    });
    if (isUnitValid) {
      nonEnglishUnitIndex = allQuestions.findIndex(
        (row) => row.Units && !containsOnlyEnglishAlphabets(row.Units)
      );
      nonEnglishUnitIndex = nonEnglishUnitIndex === -1 ? null : nonEnglishUnitIndex
    }
    const isDictValid = await allQuestions.every((question, index) => {
      if (question.question_type === "MCQ" && question.dict) {
        return !(
          Object.values(question.dict).some((list) => list.length < 1)
        );
      }
      return true;
    });
    if (isDictValid) {
      nonEnglishSynonymsIndex = allQuestions.findIndex(
        (row) => row.question_type === "MCQ" && row.dict && Object.values(row.dict).some(syns => !containsOnlyEnglishAlphabets(syns.join(',')))
      );
      nonEnglishSynonymsIndex = nonEnglishSynonymsIndex === -1 ? null : nonEnglishSynonymsIndex
    }
    if (nonEnglishQuesIndex || nonEnglishUnitIndex || nonEnglishPossibleResponseIndex || nonEnglishSynonymsIndex) {
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter ${nonEnglishQuesIndex ? "Question" : nonEnglishUnitIndex ? "Unit" : nonEnglishPossibleResponseIndex ? "Responses" : "Synonyms"} in English for question ${(nonEnglishQuesIndex || nonEnglishUnitIndex || nonEnglishPossibleResponseIndex || nonEnglishSynonymsIndex) + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isDropdownValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          (question.response_type.toLowerCase() === "dropdown") &&
          (!question.possible_responses || question.possible_responses.split(",").length < 2)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 2 responses for dropdown in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isQuestionTypeValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          ["audio", "text"].includes(question.response_type.toLowerCase()) &&
          (!question.question_type || question.question_type.trim() === "")
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter a question type for ${allQuestions[invalidQuestionIndex]?.response_type} at question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isRadioValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          (question.response_type.toLowerCase() === "radio") &&
          (!question.possible_responses || question.possible_responses.split(",").length < 2)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 2 responses for radio in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isMcqValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          (question.question_type === "MCQ") &&
          (!question.possible_responses || question.possible_responses.split(",").length < 1)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 response for MCQ in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isUnitValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          (question.question_type === "Quantity") &&
          (!question.Units || question.Units.split(",").length < 1)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 unit in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    } else if (!isDictValid) {
      const invalidQuestionIndex = allQuestions.findIndex(
        (question) =>
          question.question_type === "MCQ" &&
          question.dict &&
          Object.values(question.dict).some((list) => list.length < 1)
      );
      setOpenSuccessBox(true);
      setSuccessMessage(
        `Please enter at least 1 synonym in English for question ${invalidQuestionIndex + 1}`
      );
      setOpenSuccessBoxType("error");
    }
    else if (
      errors.formName === "" &&
      formName !== "" &&
      allQuestions.length !== 0 &&
      formIcon !== null &&
      projects !== null &&
      isEnglishValidForAllQuestions
    ) {
      var formatted_questions = transformQuestionsData(allQuestions);
      // var project_ids = projects.map((item, index) => {
      //   return item.id;
      // });
      const data = {
        name: formName,
        icon: formIcon.id,
        created_by: "1",
        questions: formatted_questions,
        project: projects.id,
      };
      try {
        setLoading(true);
        const response = await postFromData(data);
        setReload(true);
        setLoading(false);
        handleClose();
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success');
      } catch (error) {
        setOpenSuccessBox(true);
        setSuccessMessage(error.response.data["message"]);
        setOpenSuccessBoxType('error')
        setLoading(false);
        console.error("API Error:", error.response.data["message"]); // Log the error message
      }
    } else {
      const errorText = !formName ? "Please enter form name" : !containsOnlyEnglishAlphabets(formName) ? "Please enter form name in English" : !formIcon ? "Please select form category" : !projects ? "Please select a project" : "Please fill all the required fields"
      validateFormData();
      setOpenSuccessBox(true);
      setSuccessMessage(errorText);
      setOpenSuccessBoxType('error')
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = `Sample questions File.xlsx`;
    link.href = sampleQuestionsFile;
    link.click();
  };

  const projectOptionDisabledFunc = (option) => {
    const endDate = new Date(option?.end_date);
    endDate.setHours(23, 59, 59, 0);
    const currentDate = new Date();
    return endDate <= currentDate;
  }

  return (
    <Modal open={openAddModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 4,
              paddingBottom: 1
            }}
          >
            <Grid item xs={7} md={8} lg={9}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                {isCopy ? dataToBeCopied?.form?.formHeading : "Add Form"}
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} lg={2} sx={{ display: "flex", justifyContent: "end"}}>
              <Box sx={{ display: "flex", alignItems: "start", cursor: "pointer" }} onClick={() => setCollapseHeader(prev => !prev)}>
                <Typography variant="h6" >
                  {collapseHeader ? "Form Details" : "Collapse"}
                </Typography>
                {collapseHeader ? <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }}/> : <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }}/>}
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid
              // ref={inputRef}
              container
              item
              spacing={1}
              xs={12}
              sx={{
                width: "90%",
              }}
            >
              {!collapseHeader && <><Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Name
                </Typography>
                <BasicTextfield
                  label="Enter Form Name"
                  is_phone={false}
                  handleChange={handleChangeFromName}
                  is_disabled={false}
                  index={1}
                  error={errors.formName !== ""}
                  helperText={errors.formName}
                  required={true}
                  value={formName}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Status
                </Typography>
                <BasicDropDown
                  text="Select Form Status"
                  value={formStatus}
                  handleChange={handleChangeFormStatus}
                  menuList={formStatusList}
                  error={errors.formStatus !== ""}
                  required={true}
                  errorText={errors.formStatus}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
                >
                  Form Category
                </Typography>
                <AutoCompleteDropdown
                  label="Select Form Category"
                  selectedOptions={formIcon}
                  setSelectedOptions={handleChangeFormIcon}
                  menuList={formIconList}
                  error={errors.formIcon !== ""}
                  required={true}
                  type="icon"
                  errorText={errors.formIcon}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Typography sx={{ marginBottom: 1 }}>Projects</Typography>
                  <AutoCompleteDropdown
                    width={230}
                    menuList={projectList}
                    // value={projects}
                    selectedOptions={projects}
                    onHighlightChange={(value) => {
                      handleChangeProjects(value)
                    }}
                    optionDisabledFunc={projectOptionDisabledFunc}
                    label="Select Projects"
                    // error={false}
                    required={true}
                    error={errors.projects !== ""}
                    errorText={errors.projects}
                  />
                </Grid></>}
              <Grid item xs={8}>
                <Typography sx={{ fontFamily: "Inter", color: "#444444" }}>
                  Add Questions
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            // ref={inputRef}
            container
            item
            spacing={2}
            xs={12}
            sx={{
              width: "90%",
            }}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              key={keyToReload}
              sx={{
                padding: 2,
                marginLeft: 2,
                marginTop: 1,
                borderRadius: "1rem",
                width: "80%",
                backgroundColor: "#F5F5F5",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item sx={{ width: "60%" }}>
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleUploadQuestions}
                />
                <label htmlFor="raised-button-file">
                  <Typography
                    sx={{
                      textAlign: "right",
                      color: "#2D6A4F",
                      cursor: "pointer",
                      "&:hover": { opacity: 0.7 },
                    }}
                  >
                    {!isLoading &&
                      (file ? (
                        <>
                          {file.name}{" "}
                          <IconButton
                            onClick={handleClearFile}
                            size="small"
                            sx={{ marginLeft: "5px", padding: 0 }}
                          >
                            <CloseIcon fontSize="small" /> {/* CloseIcon */}
                          </IconButton>
                        </>
                      ) : (
                        "+ Click here to upload questions"
                      ))}
                  </Typography>
                </label>
              </Grid>
              <Grid
                item
                sx={{
                  width: "40%",
                  textAlign: "right",
                }}
              >
                <IconButton
                  sx={{ padding: 0, cursor: "default", cursor: "pointer" }}
                  onClick={handleDownload}
                >
                  <GetAppRoundedIcon
                    sx={{ fontSize: 18, marginRight: "2px" }}
                  />
                </IconButton>
                <Typography
                  onClick={handleDownload}
                  sx={{
                    color: "#2D6A4F",
                    cursor: "pointer",
                    "&:hover": { opacity: 0.7 },
                    display: "inline",
                  }}
                >
                  Sample
                </Typography>
              </Grid>
            </Grid>
            {fileQuestions != null &&
              fileQuestions.map((item, index) => (
                <AddFormCard
                  key={index}
                  index={index}
                  // serialNumber={index + questions.length}
                  question={item}
                  setQuestions={setFileQuestions}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  setOpenSuccessBox={setOpenSuccessBox}
                  setSuccessMessage={setSuccessMessage}
                  setOpenSuccessBoxType={setOpenSuccessBoxType}
                />
              ))}
            {questions != null &&
              questions.map((item, index) => (
                <AddFormCard
                  key={item.id}
                  index={index}
                  question={item}
                  setQuestions={setQuestions}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  autofocus={index==questions.length-1}
                  serialNumber={index + fileQuestions.length}
                  setOpenSuccessBox={setOpenSuccessBox}
                  setSuccessMessage={setSuccessMessage}
                  setOpenSuccessBoxType={setOpenSuccessBoxType}
                />
              ))}
          </Grid>
          <Grid
              item
              xs={12}
              sm={12}
              sx={{
                marginLeft: 1,
                marginTop: 3,
                width: "80%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  color:
                    questions.length > 0 &&
                    (questions[0].questions === undefined ||
                      questions[0].questions.trim() === "")
                      ? "#AAAAAA" // Apply disabled style
                      : "#2D6A4F", // Apply normal style
                  cursor:
                    questions.length > 0 &&
                    (questions[0].questions === undefined ||
                      questions[0].questions.trim() === "")
                      ? "default" // Change cursor to default for disabled link
                      : "pointer", // Use pointer cursor for clickable link
                  "&:hover": {
                    opacity:
                      questions.length > 0 &&
                      (questions[0].questions === undefined ||
                        questions[0].questions.trim() === "")
                        ? 1 // Keep opacity at 1 for disabled link
                        : 0.7, // Reduce opacity for clickable link on hover
                  },
                }}
                onClick={
                  questions.length > 0 &&
                  (questions[0].questions === undefined ||
                    questions[0].questions.trim() === "")
                    ? null // Don't execute the click handler for disabled link
                    : addNewQuestion // Execute the click handler for clickable link
                }
              >
                + Click here to add question
              </Typography>
            </Grid>

          <Grid
            container
            item
            sm={12}
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex", // Add display:flex to create a flex container
            }}
          >
            <Grid item sx={{ margin: "30px" }}>
              <CancelButtonStyle
                variant="contained"
                name="cancel"
                onClick={handleClose}
              >
                Cancel
              </CancelButtonStyle>
            </Grid>
            <Grid item sx={{ margin: "30px" }}>
              <ButtonStyle
                variant="contained"
                name="add"
                startIcon={<CheckIcon size="large" />}
                onClick={handleSaveData}
              >
                Save
              </ButtonStyle>
            </Grid>
            {addFormSnackBar && (
              <SnackBars
                open={addFormSnackBar}
                handleClose={() => setAddFormSnackBar(false)}
                severity={addFormSnackBarType}
                message={addFormSnackBarMessage}
                autoHideDuration={6000}
                vertical="bottom"
                horizontal="center"
                position="absolute"
              />
            )}
          </Grid>

          <BackDropLoader loading={loading} />
          <BackDropLoader loading={isLoading} />
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
