import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Structures from "../../apiResonseStructres/loginModule";

import { loginAPI, forgotPasswordAPI } from "../../api's/index";

const initialState = {
  loginData: Structures.GET_LOGIN_DETAIL_API_RESPONSE,
  loginDataLoading: false,
  loginDataIsSuccess: false,
  loginDataErrorMessage: Structures.LOGIN_ERROR_RESPONSE,
  forgotPasswordApiResponse: Structures.FORGOT_PASSWORD_API_RESPONSE,
  forgotPasswordLoading: false,
  forgotPasswordIsSuccess: false,
  forgotPasswordErrorMessage: Structures.ERROR_MESSAGE_API_RESPONSE,
  loggedInUser: Structures.GET_LOGIN_DETAIL_API_RESPONSE,
};

export const login = createAsyncThunk(
  "accounts/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(loginAPI, {
        params: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "accounts/forgot_password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "post",
        url: forgotPasswordAPI,
        params: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout(state) {
      Object.assign(state.loginData, Structures.GET_LOGIN_DETAIL_API_RESPONSE);
      Object.assign(state.loginDataLoading, false);
      Object.assign(state.loginDataIsSuccess, false);
      if (state.loginDataErrorMessage)
        Object.assign(
          state.loginDataErrorMessage,
          Structures.LOGIN_ERROR_RESPONSE
        );    
    },
    resetForgotPassword(state) {
      Object.assign(
        state.forgotPasswordApiResponse,
        Structures.FORGOT_PASSWORD_API_RESPONSE
      );
      Object.assign(state.forgotPasswordLoading, false);
      Object.assign(state.forgotPasswordIsSuccess, false);
      Object.assign(
        state.forgotPasswordErrorMessage,
        Structures.ERROR_MESSAGE_API_RESPONSE
      );
    },
    resetLogin(state) {
      Object.assign(
        state.loginData,
        Structures.GET_LOGIN_DETAIL_API_RESPONSE
      );
      Object.assign(state.loginDataLoading, false);
      Object.assign(state.loginDataIsSuccess, false);
      Object.assign(
        state.loginDataErrorMessage,
        Structures.LOGIN_ERROR_RESPONSE
      );
    },
    getLoggedInUser(state, action) {
      Object.assign(state.loggedInUser, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loginData = payload;
        state.loginDataLoading = false;
        state.loginDataIsSuccess = true;
      })
      .addCase(login.pending, (state, { payload }) => {
        state.loginDataLoading = true;
        state.loginDataIsSuccess = false;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loginDataErrorMessage = payload;
        state.loginDataLoading = false;
        state.loginDataIsSuccess = false;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.forgotPasswordApiResponse = payload;
        state.forgotPasswordLoading = false;
        state.forgotPasswordIsSuccess = true;
      })
      .addCase(forgotPassword.pending, (state, { payload }) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordIsSuccess = false;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.forgotPasswordErrorMessage = payload;
        state.forgotPasswordLoading = false;
        state.forgotPasswordIsSuccess = false;
      });
  },
});

export const {
  logout,
  resetForgotPassword,
  getLoggedInUser,
  resetLogin
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
