import { partnerDetailsAPI } from "../../api's/index";
import { commonApiCall } from "../../utils/index";
var qs = require("qs");

export const getPartnerData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: partnerDetailsAPI,
    params: params,
  });
  return response;
};

export const getPartnerLocation = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: partnerDetailsAPI + "location",
    params: params,
  });
  return response;
};

export const postPartnerData = async (data) => {
  const response = await commonApiCall({
    method: "post",
    url: partnerDetailsAPI,
    data: data,
  });
  return response;
};

export const editPartnerData = async (data, id) => {
  const response = await commonApiCall({
    method: "put",
    url: partnerDetailsAPI + id,
    data: data,
  });
  return response;
};

export const deletePartnerData = async (id) => {
  const response = await commonApiCall({
    method: "delete",
    url: partnerDetailsAPI + id,
  });
  return response;
};
