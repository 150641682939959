import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  getStatesData,
  getDistrictData,
  getTalukaData,
} from "../../features/location/locationAPIs";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import AutoCompleteMultiDropdown from "../reusableComponents/AutoCompleteMultiDropDown";

const StyledGrid = styled(Grid)({
  display: "flex",
  width: 800,
});

const ClearBtn = styled(Button)({
  backgroundColor: "#FFFFFF",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});
const ApplyBtn = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: 20,
  marginBottom: 20,
  marginTop: 30,
  width: "80%",
  height: "60%",
  borderColor: "1px solid #2D6A4F",
});


const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "1.8",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

export default function FilterPopOver({
  handleClose = () => { },
  openFilterPopOver = false,
  setApplyFilter = () => { },
  setClearFilter = () => { },
  selectedStatesOptions = [],
  setSelectedStatesOptions = () => { },
  selectedDistrictOptions = [],
  setSelectedDistrictOptions = () => { },
  selectedTalukaOptions = [],
  setSelectedTalukaOptions = () => { },
  getStatesResponse
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const open = Boolean(openFilterPopOver);
  const id = open ? "filter-popover" : undefined;
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  // const [getStatesResponse, setGetStateResponse] = useState([{
  //   id: "",
  //   name: "",
  // },]);
  const [getDistrictResponse, setGetDistrictResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [getTalukaResponse, setGetTalukaResponse] = useState([{
    id: "",
    name: "",
  },]);
  // console.log('taluka', selectedTalukaOptions)
  // useEffect(() => {
  //   if(getStatesResponse[0].id==="")
  //     dispatch(getStatesData());
  // }, []);

  useEffect(() => {
    if (selectedStatesOptions.length > 0) {
      var selectedStateIds = [];
      selectedStatesOptions.forEach((stateObj) => {
        selectedStateIds.push(stateObj.id);
      });
      const fetchData = async () => {
        try {
          const response = await getDistrictData({
            type: "state",
            location_ids: selectedStateIds,
          })
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetDistrictResponse(response.data.data);
          } else {
            // setShowSnackBar(true);
            // setSnackBarType("error");
            // setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          // setShowSnackBar(true);
          // setSnackBarType("error");
          // setSnackBarMessage("Not able to fetch districts, Please check server");
        }
      };
      fetchData();
      // dispatch(
      //   getDistrictData({
      //     type: "state",
      //     location_ids: selectedStateIds,
      //   })
      // );
    } else {
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
    }
  }, [selectedStatesOptions]);

  useEffect(() => {

    if (selectedDistrictOptions.length > 0 && getDistrictResponse[0].id !== "") {
      var tempDistrictsObj = [...selectedDistrictOptions];
      tempDistrictsObj.forEach((tempDistrictObj) => {
        var districtPresent = getDistrictResponse.find(
          (obj) => obj.id === tempDistrictObj.id
        );
        if (!districtPresent) {
          // console.log("yaha aauya",selectedDistrictOptions,districtPresent,getDistrictResponse)
          setSelectedDistrictOptions((districtsObj) =>
            districtsObj.filter(
              (district) => district.id !== tempDistrictObj.id
            )
          );
        }
      });
    }
    setDistrictList(getDistrictResponse);

  }, [getDistrictResponse]);


  const getTalukasDetails = async () => {
    if (selectedDistrictOptions.length > 0) {
      var selectedDistrictIds = [];
      selectedDistrictOptions.forEach((stateObj) => {
        selectedDistrictIds.push(stateObj.id);
      });
      try {
        const response = await getTalukaData({
          type: "district",
          location_ids: selectedDistrictIds,
        })
        if (response != null && response != undefined &&
          response.data !== null && response.data !== undefined &&
          response.data.data !== null && response.data.data !== undefined
        ) {
          setGetTalukaResponse(response.data.data);
        } else {
          // setShowSnackBar(true);
          // setSnackBarType("error");
          // setSnackBarMessage("Something went wrong on sever!");
        }
      } catch (error) {
        console.log(error);
        // setShowSnackBar(true);
        // setSnackBarType("error");
        // setSnackBarMessage("Not able to fetch Taluka, Please check server");
      }
      // dispatch(
      //   getTalukaData({
      //     type: "district",
      //     location_ids: selectedDistrictIds,
      //   })
      // );
    } else {
      setSelectedTalukaOptions([]);
    }

  }

  useEffect(() => {

    getTalukasDetails()

  }, [selectedDistrictOptions]);

  useEffect(() => {
    if (selectedTalukaOptions.length > 0 && getTalukaResponse[0].id !== "") {
      var tempTalukasObj = [...selectedTalukaOptions];
      tempTalukasObj.forEach((tempTalukaObj) => {
        var talukaPresent = getTalukaResponse.find(
          (obj) => obj.id === tempTalukaObj.id
        );
        if (!talukaPresent) {
          setSelectedTalukaOptions((talukaObj) =>
            talukaObj.filter((taluka) => taluka.id !== tempTalukaObj.id)
          );
        }
      });
    }
    setTalukaList(getTalukaResponse);
  }, [getTalukaResponse]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openFilterPopOver}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          padding: 2.5,
          borderRadius: "1rem",
        },
      }}
      onClose={handleClose}
    >
      <ThemeProvider theme={basicTheme}>
        <StyledGrid container>
          <Grid item xs={11}>
            <Typography sx={{ fontSize: "20px", color: "#999DA4" }}>
              Filter By
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ color: "#444444", margin: 1 }}>
              State
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={getStatesResponse}
              selectedOptions={selectedStatesOptions}
              setSelectedOptions={setSelectedStatesOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              District
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={districtList}
              selectedOptions={selectedDistrictOptions}
              setSelectedOptions={setSelectedDistrictOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ fontSixe: "18px", color: "#444444", margin: 1 }}>
              Taluka
            </Typography>
            <AutoCompleteMultiDropdown
              menuList={talukaList}
              selectedOptions={selectedTalukaOptions}
              setSelectedOptions={setSelectedTalukaOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <ClearBtn
              variant="contained"
              name="Clear Filter"
              onClick={() => setClearFilter(true)}
            >
              Clear All
            </ClearBtn>
          </Grid>

          <Grid item xs={3}>
            <ApplyBtn
              variant="contained"
              name="Apply Filter"
              onClick={() => setApplyFilter(true)}
            >
              Apply
            </ApplyBtn>
          </Grid>
        </StyledGrid>
      </ThemeProvider>
    </Popover>
  );
}
