import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBars from "../reusableComponents/SnackBar";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import AutoCompleteMultiDropdown from "./AutoCompleteMultiDropdown";
import { getFromData } from "../../features/formManagement/formManagementAPIs";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { postProjectData } from "../../features/projects/projectsAPIs";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import { getPartnerData } from "../../features/partner/partnerAPIs";

const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  paddingLeft: 5,
  paddingTop: 2,
};

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
  textField: {
    "& .MuiInputBase-input": {
      border: "none", // Remove the border
    },
    "& .MuiInput-underline:before": {
      border: "none", // Remove the underline
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none", // Remove the underline on hover
    },
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "1rem",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function AddProjectModal({
  openAddModal,
  handleClose = () => { },
  setProjectSnackBarMessage = () => { },
  setProjectSnackBarType = () => { },
  setReload = () => { },
  setOpenSuccessBox = () => {},
  setSuccessMessage=()=>{},
  setOpenSuccessBoxType = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState("");
  const [projectStatus, setProjectStatus] = useState("");
  const [formIds, setFormIds] = useState([]);
  const [formIdList, setFormIdList] = useState([]);
  const [addPartnerSnackBar, setAddPartnerSnackBar] = useState(false);
  const [addPartnerSnackBarMessage, setAddPartnerSnackBarMessage] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [formattedStartDate, setFormattedStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [formattedEndDate, setFormattedEndDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );

  const basicError = {
    projectName: "",
    projectStatus: "",
    projectForms: "",
    // formIds: [],
    farmerPartner: "",
  };
  const [snackBarType, setSnackBarType] = useState("success");
  const [errors, setErrors] = useState(basicError);

  const projectStatusList = ["Active", "Inactive"];
  const [loading, setLoading] = useState(false);

  const [partnerNamesData, setPartnerNamesData] = useState([]);
  const [farmerPartner, setFarmerPartner] = useState(null);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const [partnerDataResponse, setPartnerDataResponse] = useState([]);

  const validateProjectFormData = () => {
    let projectNameError="";
    let projectStatusError="";
    let projectFormsError="";
    let farmerPartnerError="";
    if (projectName.trim() === "") {
      projectNameError = "Project Name can't be empty"
    } 
    // if (projectStatus === "") {
    //   projectStatusError = "Project Status can't be empty"
    // }

    if (farmerPartner === null) {
      farmerPartnerError = "Partner name can't be empty"
    }
  
    // if (formIds.length === 0) {
    //   projectFormsError = "Project Forms can't be empty"
    // }
    setErrors({...errors, projectName: projectNameError, projectForms:projectFormsError, farmerPartner:farmerPartnerError});
  }

  const handleChangeProjectName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
    /^[a-zA-Z0-9\s_]{1,}[\.]{0,1}[A-Za-z0-9\s_]{0,}$/;
    if (value.trim() === "") {
      setErrors({ ...errors, projectName: "Project Name can't be empty" });
      value = "";
    } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      setErrors({ ...errors, projectName: "Special characters are not allowed in Project Name" });
      value = "";
    } else {
      setErrors({ ...errors, projectName: "" });
    }
    setProjectName(value);
  };

  const handleChangeProjectStatus = (event) => {
    setProjectStatus(event.target.value);
    setErrors({ ...errors, projectStatus: "" });
  };

  const handleChangeProjectFrom = (value) => {
    setFormIds(value);
    setErrors({ ...errors, projectForms: "" });
  }

  const handleChangeFarmerPartner = (value) => {
    setFarmerPartner(value);
    setErrors({ ...errors, farmerPartner: "" });
  }

  const onStartDateChange = (newValue) => {
    var formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormattedStartDate(formattedDate);
    setStartDate(newValue);
    setEndDate(newValue);
  };
  const onEndDateChange = (newValue) => {
    var formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormattedEndDate(formattedDate);
    setEndDate(newValue);
  };

  useEffect(async () => {
    try {
      const response = await getFromData({fields:["id", "name"]})
      setFormIdList(response.data['data'])
    }
    catch {
      setSnackBarType("error");
      setAddPartnerSnackBar(true);
      setAddPartnerSnackBarMessage('Error while getting form list'); // Use error.response.data here
    }
  }, [])

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setProjectName("");
    setProjectStatus("");
    // setFormIds([]);
    setAddPartnerSnackBar(false);
    setSnackBarType("success");
    setAddPartnerSnackBarMessage("");
    setProjectSnackBarType("")
    setProjectSnackBarMessage("");
    handleClose();
  };

  const handleSaveData = async () => {
    if (
      errors.projectName === "" &&
      projectName !== "" &&
      farmerPartner !== null 
      // formIds.length > 0
    ) {
      // var forms = formIds.map((item, index) => {return item.id})
      const data = {
        name: projectName,
        // forms: forms,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        created_by: "1",
        partner:farmerPartner.id
      };
      try {
        setLoading(true);
        const response = await postProjectData(data);
        setReload(true);
        setLoading(false);
        handleClose();
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success')
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message && typeof error.response.data.message === 'string') {
          setSuccessMessage(error.response.data.message);
        } else {
          setSuccessMessage("An unknown error occurred."); // or provide a default message
        }
        setLoading(false);
        setOpenSuccessBox(true);
        setOpenSuccessBoxType('error');
        
      }
    } else {
      validateProjectFormData();
      setOpenSuccessBox(true);
      setSuccessMessage("Please fill all the required fields");
      setOpenSuccessBoxType('error')
      
      
    }
  };

  const getPartnerList = async () => {
    setLoading(true);
    try {
      const response = await getPartnerData({limit:100000,offset:0});
      if (loggedInUser.details.partner !== null) {
        const partnerId = loggedInUser.details.partner.id;

        // Filter the partner data from response.data["data"]
        const filteredData = response.data["data"].filter(
          (item) => item.id === partnerId
        );

        // Now filteredData contains the partner data if it exists
        setPartnerDataResponse(filteredData);
        const partnerNamesList = filteredData.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setPartnerNamesData(partnerNamesList);
      } else {
        setPartnerDataResponse(response.data["data"]);
        const partnerNamesList = response.data["data"].map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setPartnerNamesData(partnerNamesList);
      }
      setLoading(false);
    } catch (error) {
      setSnackBarType("error");
      setAddPartnerSnackBar(true);
      setAddPartnerSnackBarMessage('Error while getting partner list');
    }
  };

  return (
    <Modal open={openAddModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid item xs={11} marginY={1.5}>
            <Typography
              variant="h4"
              sx={{ color: "#444444", fontWeight: "500" }}
            >
              Add Project
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CloseRoundedIcon
              className={classes.closeBtnStyle}
              onClick={handleCloseByClearIcon}
            />
          </Grid>
          <Grid container item spacing={2} xs={12} sx={{ width: "90%" }}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
              >
                Project Name
              </Typography>
              <BasicTextfield
                label="Enter Project Name"
                is_phone={false}
                handleChange={handleChangeProjectName}
                is_disabled={false}
                index={1}
                error={errors.projectName !== ""}
                helperText={errors.projectName}
                required={true}
                value={projectName}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography
                sx={{ marginBottom: 1, fontFamily: "Inter", color: "#444444" }}
              >
                Project Status
              </Typography>
              <BasicDropDown
                text="Select Project Status"
                value={projectStatus}
                handleChange={handleChangeProjectStatus}
                menuList={projectStatusList}
                error={errors.projectStatus !== ""}
                required={true}
                errorText={errors.projectStatus}
              /> */}
            {/* </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography sx={{ marginBottom: 1 }}>Project Start Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  // minDate={dayjs()}
                  onChange={onStartDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                      sx={{
                        marginBottom: 1,
                        borderRadius: "1rem",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "1rem", // Change the border radius of the outline
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "1rem", // Change the border radius on hover
                        },
                        width: "90%",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ marginBottom: 1 }}>Project End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  minDate={startDate}
                  onChange={onEndDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                      sx={{
                        marginBottom: 1,
                        borderRadius: "1rem",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "1rem", // Change the border radius of the outline
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "1rem", // Change the border radius on hover
                        },
                        width: "90%",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography sx={{ marginBottom: 1 }}>Forms</Typography>
              <AutoCompleteMultiDropdown
                width={230}
                menuList={formIdList}
                selectedOptions={formIds}
                onHighlightChange={(value) => { handleChangeProjectFrom(value) }}
                label="Select Forms"
                // disable={disable || getForms.length === 0}
                required={true}
                error={errors.projectForms !== ""}
                errorText={errors.projectForms}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  marginBottom: 1,
                }}
              >
                Partner Name
              </Typography>
              <AutoCompleteDropdown
                width="auto"
                menuList={partnerNamesData}
                selectedOptions={farmerPartner}
                setSelectedOptions={handleChangeFarmerPartner}
                label="Select Partner"
                required={true}
                onOpen={getPartnerList}
                loading={loading}
                error={errors.farmerPartner !== ""}
                errorText={errors.farmerPartner}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              item
              sm={12}
              spacing={0}
              sx={{
                direction: "row",
                justifyContent: "center",
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              <Grid
                item
                xs={12}
                sm={2.5}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <CancelButtonStyle
                  variant="contained"
                  name="cancel"
                  onClick={handleClose}
                >
                  Cancel
                </CancelButtonStyle>
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <ButtonStyle
                  variant="contained"
                  name="add"
                  startIcon={<CheckIcon size="large" />}
                  onClick={handleSaveData}
                >
                  Save
                </ButtonStyle>
              </Grid>
            </Grid>
            <div style={{ willChange: "transform" }}>
              {addPartnerSnackBar && (
                <SnackBars
                  open={addPartnerSnackBar}
                  handleClose={() => setAddPartnerSnackBar(false)}
                  severity={snackBarType}
                  message={addPartnerSnackBarMessage}
                  autoHideDuration={6000}
                  vertical="bottom"
                  horizontal="center"
                />
              )}
            </div>
          </Grid>
          <BackDropLoader loading={loading} />
          <BackDropLoader loading={isLoading} />
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
