import { configureStore } from '@reduxjs/toolkit'
import locationSlice from '../features/location/locationSlice';
import rolesAndPermissionSlice  from '../features/rolesAndPermissions/rolesAndPermissionsSlice';
import appBarSlice from '../features/appbar/AppBarSlice';
// import partnerSlice from '../features/partner/partnerSlice';
import authenticationSlice from '../features/Authentication/AuthenticationSlice';
import appUserSlice from '../features/appUsers/appUserSlice';
import supervisorAccessSlice from '../features/supervisorAccess/supervisorAccessSlice';
import reviewImagesSlice from '../features/reviewImages/reviewImagesSlice';

export const store = configureStore({
  reducer: {
    locationSliceReducer: locationSlice,
    appBarSliceReducer: appBarSlice,
    rolesAndPermissionSliceReducer: rolesAndPermissionSlice,
    // partnerSliceReducer: partnerSlice,
    authenticationSliceReducer : authenticationSlice,
    usersSliceReducer: appUserSlice,
    supervisorSliceReducer: supervisorAccessSlice,
    reviewImagesSliceReducer:reviewImagesSlice
  },
})
