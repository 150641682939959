import React, { useState } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SimCardDownload as SimCardDownloadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const ExportButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "100px",
  maxWidth: "150px",
  height: "48px",
  border: "1px solid #2D6A4F",
  borderColor: "#2D6A4F",
  "&:hover": {
    backgroundColor: "white",
    color: "#2D6A4F",
  },
});
const buttonStyle = {
  position: "absolute",
  top: "146px",
  left: "1352px",
  width: "32px",
  height: "32px",
  borderRadius: "12px",
  border: "1px solid #000",
  backgroundColor: "#FFF",
  padding: "14px 16px", 
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
};

const SelectedExportButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "#FFFFFF",
  textTransform: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "150px",
  maxWidth: "200px",
  height: "48px",
  "&:hover": {
    backgroundColor: "#2D6A4F",
    color: "#FFFFFF",
  },
});

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    cursor: "pointer",
  },
  menuPaper: {
    borderRadius: "16px", // Set border radius
  },
}));

const ExportButtonMenu = ({
  setExportData,
  setExportType,
  selectedExport,
  handleCancelSelectExport,
  handleExport
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExportAll = () => {
    setExportData(true);
    setExportType("all");
    handleCloseMenu();
    handleExport()
  };

  const handleExportSelect = () => {
    setExportData(true);
    setExportType("select");
    handleCloseMenu();
  };

  return (
    <>
      {selectedExport.length > 0 ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SelectedExportButtonStyle
            variant="contained"
            name="Download"
            startIcon={<SimCardDownloadIcon className={classes.iconStyle} />}
            onClick={()=>handleExport('select')}
          >
            Export Selected
          </SelectedExportButtonStyle>
          <Box
            sx={{
              ml: 1,
              width: "32px",
              height: "32px",
              borderRadius: "12px",
              border: "1px solid #E6E6E6",
              backgroundColor: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleCancelSelectExport}
          >
            <CloseIcon />
          </Box>
        </Box>
      ) : (
        <>
          <ExportButtonStyle
            variant="contained"
            name="Download"
            startIcon={<SimCardDownloadIcon className={classes.iconStyle} />}
            onClick={handleOpenMenu}
            // sx={{width:{xl:"200px",lg:"130px",md:"100px",sm:"80px"},}}
          >
            Export
          </ExportButtonStyle>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
              style: { borderRadius: "16px", width: "140px" },
            }}
          >
            <MenuItem
              sx={{ color: "#2D6A4F", fontWeight: 500, fontSize: "16px" }}
              onClick={handleExportAll}
            >
              Export All
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: "#2D6A4F", fontWeight: 500, fontSize: "16px" }}
              onClick={handleExportSelect}
            >
              Select
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default ExportButtonMenu;
