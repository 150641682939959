import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { capitaliseFirstLetter } from "../../utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function OptionalDropDown({
  text = "",
  id = "",
  handleChange = () => {},
  menuList = [],
  disabled = false,
  required = false,
  onOpen = () => {},
  bgColor = true,
  borderRadius= 2,
  height="",
  width="100%"
}) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: borderRadius,
        fontFamily:"Inter",
        // width: 200,
        display:
          menuList.length > 0 &&
          menuList !== undefined &&
          menuList !== null &&
          ((menuList[0].name !== undefined && menuList[0].name === "") ||
            (menuList[0].id !== undefined && menuList[0].id === "")) &&
          "none",
      },
    },
  };
  const hasSelectedOption = id !== "";
  return (
    <FormControl
    fullWidth
    variant="outlined"
    required={required}
    sx={{
      // marginBottom: 1,
      fontFamily: 'Inter',
      width: width,
      backgroundColor: '#F5F5F5',
      height: height ? height : undefined,
      alignItems:"center",
      color: "#2D6A4F"
    }}
  >
    <InputLabel
      htmlFor="outlined-select"
      sx={{
        color: hasSelectedOption ? '#5E6060' : undefined,
      }}
    >
      {hasSelectedOption ? '' : text}
    </InputLabel>
    <Select
      value={id}
      label={hasSelectedOption ? undefined : text}
      onChange={handleChange}
      onOpen={onOpen}
      displayEmpty
      sx={{
        borderRadius: borderRadius,
        height: height && height,
        fontFamily: 'Inter',
        fontSize: '18px',
        boxShadow: "none",
        color: "#2D6A4F",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
      }}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'Without label' }}
      disabled={disabled}
    >
      {menuList !== null &&
        menuList.map((menu) => (
          <MenuItem
            key={menu.id || menu.name || menu}
            value={menu.id || menu.name || menu}
            sx={{
              color: '#5E6060',
              fontFamily: 'Inter',
              fontSize: '18px',
            }}
          >
            {capitaliseFirstLetter(menu.name) || capitaliseFirstLetter(menu)}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
  
  );
}
