import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getExportedData,
} from "../../features/partner/partnerSlice";
import { getEachPartnerData } from "../../utils";
import AddPartnerModal from "../../components/partner/addPartnerModal";
import { CSVLink } from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/partner/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import EditPartnerModal from "../../components/partner/editPartnerModal";
import StateWiseLocationModal from "../../components/location/statewiseLocationModal";
import DeletePartner from "../../components/partner/deletePartnerModal";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';
import { getPartnerData } from "./partnerAPIs";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "name",
    label: "Partner",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "spoc",
    label: "SPOC",
    type: "user",
    sort: false,
  },
  // {
  //   key: "projects",
  //   label: "Projects",
  //   type: "projects",
  // },
//   {
//     key: "is_active",
//     label: "Status",
//     type: "boolean",
//     sort: true,
//   },
  // {
  //   key: "location",
  //   label: "State(Villages)",
  //   type: "location",
  //   sort: false,
  // },
  {
    key: "created_at",
    label: "Date Added",
    type: "date",
    sort: true,
  },
];

export default function Partner({ }) {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [partnerSnackBarType, setPartnerSnackBarType] = useState("success");
  const [partnerSnackBarMessage, setPartnerSnackBarMessage] = useState("");
  const [showPartnerSnackBar, setPartnerShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditModalWithLocation, setOpenEditModalWithLocation] = useState(false);
  const [editData, setEditData] = useState({});
  const [editDataWithLocation, setEditDataWithLocation] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [openLocationSubModal, setOpenLocationSubModal] = React.useState(false);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [partnerDataResponse, setPartnerDataResponse] = useState([]);
  const [totalPartnerData, setTotalPartnerData] = useState(-1);
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const ADMIN_PARTNER = process.env.REACT_APP_ADMIN_PARTNER
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [openSuccessBoxType,setOpenSuccessBoxType] = useState("success");
  const [successMessage, setSuccessMessage] = useState("");
  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  // useEffect(()=>{
  //   if(loggedInUser.details.partner!==null){
  //     setFilters((oldArray) => [
  //       ...oldArray,
  //       { type: "name", value: [loggedInUser.details.partner.name] },
  //     ])
  //   }
  // },[])

  useEffect(() => {
    if (clearFilter === true) {
      setSelectedStates([]);
      setSelectedProjects([]);
      setPage(0);
      setOffset(0);
      setApiCall(true);
    }
  }, [clearFilter]);

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    applyFilter,
  ]);

  useEffect(() => {
    if (editData.id !== undefined && editData.id.toString() === ADMIN_PARTNER) {
      setOpenEditModal(false);
    } else if (openEditModal) {
      setLoading(true);
      getEachPartnerData(editData.id).then((data) => {
        setLoading(false);
        try {
          setEditDataWithLocation(data.data[0]);
          setOpenEditModalWithLocation(true);
          setOpenEditModal(false);
          
        } catch (error) {
          // Handle the case where data.data[0] is undefined
          setPartnerShowSnackBar(true);
          setPartnerSnackBarMessage("Something went wrong on Partner server side ");
          setPartnerSnackBarType("error");
          setOpenEditModal(false);
        }
      });
    }
  }, [openEditModal]);


  useEffect(() => {
    if (apiCall || reload) {
      const params = {
        search: searchBarData,
        offset: offset,
        limit: rowsPerPage,
        sort_key: sortBy,
        sort_by: sortingType,
      };
      if (selectedStates.length !== 0) {
        params['state_ids'] = []
        selectedStates.forEach((location) => {
          params['state_ids'].push(location.id);
        });
      }
      if (selectedProjects.length !== 0) {
        params['project_ids'] = []
        selectedProjects.forEach((project) => {
          params['project_ids'].push(project.id);
        });
      }

      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await getPartnerData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setTotalPartnerData(response.data.count)
            setPartnerDataResponse(response.data.data);
          } else {
            setPartnerSnackBarMessage("Something went wrong on sever!");
            setPartnerSnackBarType("error");
            setPartnerShowSnackBar(true);
          }
        } catch (error) {
          setPartnerSnackBarType("error");
          setPartnerSnackBarMessage(error.message);
          setPartnerShowSnackBar(true);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };

      fetchData();
    }
  }, [ apiCall, reload]);

  useEffect(() => {
    if (partnerSnackBarMessage !== "" && partnerSnackBarMessage !== undefined)
      setPartnerShowSnackBar(true);
  }, [partnerSnackBarMessage]);

  useEffect(() => {
    if (exportData) {
      setLoading(true)
      dispatch(
        getExportedData({
          sort_by: sortingType,
          sort_type: sortBy,
          search: searchBarData,
          filters: JSON.stringify(filters),
          offset: offset,
          limit: rowsPerPage,
        })
      ).then((res) => {
        setLoading(false)
        setExportData(false);
        if (res.error !== undefined) {
          setPartnerSnackBarMessage("Something went wrong!")
          setPartnerSnackBarType("error")
          setPartnerShowSnackBar(true)
        }
        else {
          setExportedFileName("partner_data" + ".csv");
          csvLink.current.link.click();
        }
      });
    }
  }, [exportData]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Partner"
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Partner Name"
            loading={loading}
            showFilter={loggedInUser.role.toLowerCase() == "admin"}
            addButton={loggedInUser.role.toLowerCase() == "admin"}
            filterSelected={selectedStates.length !== 0 || selectedProjects.length !== 0}
          />
          {openFilterPopOver && (
            <PartnerFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              setFilters={setFilters}
              selectedStates={selectedStates}
              setSelectedStates={setSelectedStates}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen ? (
            <StackTable
              key={partnerDataResponse}
              headCells={initialHeadCells}
              tableResponse={partnerDataResponse}
              totalCount={totalPartnerData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOpenEditModal={setOpenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              setOpenLocationSubModal={setOpenLocationSubModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (!loading && (
            <DesktopTable
              key={partnerDataResponse}
              headCells={initialHeadCells}
              tableResponse={partnerDataResponse}
              totalCount={totalPartnerData}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setOpenEditModal={setOpenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              setOpenLocationSubModal={setOpenLocationSubModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          )
          )}
        </Grid>
        {openEditModalWithLocation && (
          <EditPartnerModal
            openModal={openEditModalWithLocation}
            handleClose={() => setOpenEditModalWithLocation(false)}
            data={editDataWithLocation}
            setEditData={setEditDataWithLocation}
            setPartnerSnackBarMessage={setPartnerSnackBarMessage}
            setPartnerSnackBarType={setPartnerSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openAddModal && (
          <AddPartnerModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setPartnerSnackBarMessage={setPartnerSnackBarMessage}
            setPartnerSnackBarType={setPartnerSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openSuccessBox && 
        <SuccessErrorDialogBox open={openSuccessBox}
        handleClose={() => setOpenSuccessBox(false)}
        text={successMessage}
        type={openSuccessBoxType}
        />
        }
        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this Partner"
            text="Projects or Users or Supervisors are mapped with this partner.Please unmap them and then
            try deleting again!"
          />
        )}

        {openDeleteModal && (
          <DeletePartner
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setSnackBarMessage={setPartnerSnackBarMessage}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        {/* <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        /> */}
        {showPartnerSnackBar && (
          <SnackBars
            open={showPartnerSnackBar}
            handleClose={() => {setPartnerShowSnackBar(false);setPartnerSnackBarMessage("");}}
            severity={partnerSnackBarType}
            message={partnerSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {openLocationSubModal && (
          <StateWiseLocationModal
            openModal={openLocationSubModal}
            handleClose={() => setOpenLocationSubModal(false)}
            data={editData.state_wise_location}
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
