import {
  locationDetailsAPI,
  filteredLocationDetailsAPI,
  statesApi,
  districtsApi,
  talukasApi,
  addLocationAPI,
  hierarchyApi,
} from "../../api's/index";
import axios from "axios";
import { getHeaders, commonApiCall } from "../../utils/index";
var qs = require("qs");

export const getLocationData = async (params, filter = false) => {
  const response = await commonApiCall({
    method: "get",
    url: filter ? filteredLocationDetailsAPI : locationDetailsAPI,
    params: params,
  });
  return response;
};

export const getStatesData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: statesApi,
    params: params,
  });
  return response;
};

export const getDistrictData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: hierarchyApi,
    params: params,
  });
  return response;
};

export const getTalukaData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: hierarchyApi,
    params: params,
  });
  return response;
};

export const getVillagesData = async (params) => {
  const response = await commonApiCall({
    method: "get",
    url: hierarchyApi,
    params: params,
  });
  return response;
};

export const addLocationData = async (data) => {
  const response = await commonApiCall({
    method: "post",
    url: addLocationAPI,
    data: data,
  });
  return response;
};

export const editLocation = async (data) => {
  const response = await commonApiCall({
    method: "put",
    url: addLocationAPI,
    data: data,
  });
  return response;
};


export const deleteLocation = async (data) => {
  const response = await commonApiCall({
    method: "delete",
    url: addLocationAPI,
    data: data,
  });
  return response;
};
