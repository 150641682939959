import React, { useState, useEffect } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import _without from "lodash/without";
import AutoCompleteMultiDropdown from "./reusableComponents/AutoCompleteMultiDropDown";
import AutoCompleteDropdown from "./reusableComponents/AutoCompleteDropDown";
import { getDistrictData, getTalukaData, getVillagesData } from "../features/location/locationAPIs";
export default function LocationDropDown({
  index = 0,
  location,
  handleChangeLocation,
  handleChangeDeleteLocation,
  getStatesResponse = [],
  disable = false,
  addAllVillage = false,
  setSnackBar=false,
  setSnackBarType="error",
  setSnackBarMessage=()=>{}
}) {
  const [getDistrictResponse, setGetDistrictResponse] = useState([]);
  const [getTalukaResponse, setGetTalukaResponse] = useState([]);
  const [getVillageResponse, setGetVillageResponse] = useState([]);

  useEffect(() => {
    if (location[index].state !== null) {
      setGetDistrictResponse([]);
      setGetTalukaResponse([]);
      setGetVillageResponse([]);
      getDistrictData({ "location_ids": location[index].state.id, "type": 'state' }).then((data) =>
        setGetDistrictResponse(data.data.data)
      );
    }
  }, [location[index].state]);

  useEffect(() => {
    if (location[index].district !== null) {
      setGetTalukaResponse([]);
      setGetVillageResponse([]);
      getTalukaData({ "location_ids": location[index].district.id, "type": "district" }).then((data) =>
        setGetTalukaResponse(data.data.data)
      );
    }
  }, [location[index].district]);

  useEffect(() => {
    if (location[index].taluka !== null) {
      setGetVillageResponse([]);
      getVillagesData({ "location_ids": location[index].taluka.id, "type": 'taluka' }).then((data) => {
        if (addAllVillage) {
          var newVillageList = data.data.data
          if(newVillageList.length){
            newVillageList.unshift({ id: "all", name: "All" });
            setGetVillageResponse(newVillageList)
          }
          else{
            setSnackBar(true);
            setSnackBarType("error");
            setSnackBarMessage("No Villages Found for given Taluka");
          }
          
          // filter out villages which have been already added, need to be discusses more cases.
          // const filteredVillages = newVillageList.filter(village => {
          //   return !location.some(location => location.villages.some(v => v.id === village.id && v.name === village.name));
          // });
          // setGetVillageResponse(filteredVillages);
        }
        else {
          setGetVillageResponse(data.data.data)
        }
      }
      );
    }
  }, [location[index].taluka]);

  return (
    <Grid
      item
      xs={11.25}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "1rem",
        margin: 1,
        marginLeft: 2,
      }}
    >
      <Grid container spacing={1}>
        <Grid container item>
          <Grid item xs={12} sm={index !== 0 && !disable ? 4 : 4}>
            <Typography sx={{ marginBottom: 1 }}>State</Typography>
            <AutoCompleteDropdown
              width="auto"
              menuList={getStatesResponse}
              selectedOptions={getStatesResponse[0].id === "" ? null : location[index].state}
              disable={disable || getStatesResponse[0].id === ""}
              label="Select State"
              onHighlightChange={handleChangeLocation}
              index={index}
              type="state"
            />
          </Grid>
          <Grid item xs={12} sm={index !== 0 && !disable ? 4 : 4}>
            <Typography sx={{ marginBottom: 1 }}>District</Typography>
            <AutoCompleteDropdown
              width="auto"
              menuList={getDistrictResponse}
              selectedOptions={getDistrictResponse.length === 0 ? null : location[index].district}
              disable={disable || getDistrictResponse.length === 0}
              label="Select District"
              onHighlightChange={handleChangeLocation}
              index={index}
              type="district"
            />
          </Grid>
          <Grid item xs={12} sm={index !== 0 && !disable ? 4 : 4}>
            <Typography sx={{ marginBottom: 1 }}>Taluka</Typography>
            <AutoCompleteDropdown
              width="auto"
              menuList={getTalukaResponse}
              selectedOptions={getTalukaResponse.length === 0 ? null : location[index].taluka}
              disable={disable || getTalukaResponse.length === 0}
              label="Select Taluka"
              onHighlightChange={handleChangeLocation}
              index={index}
              type="taluka"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={index !== 0 && !disable ? 11 : 12} lg={index !== 0 && !disable ? 11 : 12}>
          <Typography sx={{ marginBottom: 1 }}>Village</Typography>
          <AutoCompleteMultiDropdown
            width={index !== 0 && !disable ? "99%" : "97%"}
            menuList={getVillageResponse}
            selectedOptions={getVillageResponse.length === 0 ? [] : location[index].villages}
            onHighlightChange={handleChangeLocation}
            index={index}
            type="village"
            label="Select Villages"
            disable={disable || getVillageResponse.length === 0}
            addAll={addAllVillage}
            editAll={getVillageResponse.length === location[index].villages.length}
          />
        </Grid>
        <Grid item xs={12} sm={0.75} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
          {index > 0 && !disable && (
            <DeleteRoundedIcon
              sx={{ color: "#ACADAD", cursor: 'pointer', '&:hover': { opacity: 0.7 }, marginTop: "20px" }}
              onClick={() => handleChangeDeleteLocation(index)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
