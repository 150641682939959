import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import BasicTextfield from "../reusableComponents/BasicTextfield";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#78828C",
  fontSize: "18px",
};

const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "20px",
  fontSize: "18px",
  fontWeight: "500",
  minWidth: "120px",
  maxWidth: "130px",
  marginLeft: 20,
  "&:hover": {
    backgroundColor: "#2D6A4F",
    color: "white",
  },
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "14px",
  fontSize: "18px",
  fontWeight: "500",
  marginRight: 20,
  padding: "10px 12px",
  "&:hover": {
    backgroundColor: "white",
    color: "#2D6A4F",
  },
});

const AddResponseDialog = ({ open, onClose,setMenuList,menuList,language }) => {
  const [responseText, setResponseText] = useState("");

//   const handleSave = () => {
//     const currentResponses = menuList;
//     const updatedResponses = [...currentResponses, responseText];
//     setMenuList((prevMenuList) => ({
//         ...prevMenuList,
//         [language]: updatedResponses,
//       }));
//     onClose();
//   };

  const handleSave = () => {
    setMenuList((prevMenuList) => [...prevMenuList, responseText]);
    onClose();
  };

  const handleChangeResponse = (value) => {
    setResponseText(value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          border: "1.5px solid #74C69D",
          borderRadius: "16px",
          width: "330px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "#ACADAD",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        Add Response
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: "#5E6060",
            fontSize: "16px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: 2,
          }}
        >
          Response
        </Typography>
        <BasicTextfield
          fullWidth={true}
          label="Enter response here"
          handleChange={handleChangeResponse}
          required={true}
          value={responseText}
        />
      </DialogContent>
      <DialogActions sx={{ ...Centerstyle, marginBottom: 2 }}>
        <CancelButtonStyle variant="text" onClick={onClose}>
          Close
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ marginLeft: 5 }}
          onClick={handleSave}
          autoFocus
        >
          Save
        </DeleteButtonStyle>
      </DialogActions>
    </Dialog>
  );
};

export default AddResponseDialog;
