import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getStatesData } from "../../features/location/locationSlice";
import { getPartnerLocation } from "../../features/partner/partnerAPIs";
import {
  getDistrictList,
  getTalukaList,
  getVillageList,
} from "../../utils/index";
export default function LocationDropDown({
  partnerLocations = [],
  selectedStateOptions = null,
  setSelectedStateOptions = () => {},
  selectedDistrictOptions = null,
  setSelectedDistrictOptions = () => {},
  selectedTalukaOptions = null,
  setSelectedTalukaOptions = () => {},
  selectedVillageOptions = null,
  setSelectedVillageOptions = () => {},
  isEw = false,
  handleChangeLocation = () => {},
  farmerPartner = null,
}) {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const fillPartnerLocation = async ({ id, type }) => {
    setLoading(true);
    try {
      const response = await getPartnerLocation({
        id: id,
        location_type: type,
      });
      setLoading(false);
      return response.data["data"];
    } catch {
      // setAddSupervisorSnackBarType("error");
      // setAddSupervisorSnackBar(true);
      // setAddSupervisorSnackBarMessage("Error while getting partner location"); // Use error.response.data here
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    if (farmerPartner !== null) {
      dispatch(getStatesData());
      fillPartnerLocation({ id: farmerPartner.id, type: "state" }).then(
        (partnerStates) => {
          if (partnerStates !== null) {
            if(loggedInUser.details.partner !== null && (loggedInUser.role!=="Admin" && loggedInUser.role!=="Partner Head")){
              const myStateId = loggedInUser.details.partner.location_data[0]?.state.id;
              const filteredStates = partnerStates.filter((state) =>
                state.id === myStateId
              );
              setStateList(filteredStates);
            }
            else{
              setStateList(partnerStates);
            }
            setDistrictList([]);
            setTalukaList([]);
            setVillageList([]);
          }
        }
      );
      if (selectedDistrictOptions !== null) {
        getFilteredDistricts();
      }
      if(selectedDistrictOptions !== null){
        getFilteredTalukas();
      }
    }
  }, [farmerPartner]);

  useEffect(() => {
    if (
      partnerLocations.village_ids !== undefined &&
      partnerLocations.village_ids.length > 0 &&
      selectedTalukaOptions !== null
    ) {
      getFilteredVillages();
    }
  }, [partnerLocations]);


  const getFilteredDistricts = () => {
    // to do, also filter out based on what particular distrcit belongs to and only show that districts only for district head , extra filtering 
    const getDistrictListPromise = getDistrictList(selectedStateOptions.id);
    const fillPartnerLocationPromise = fillPartnerLocation({
      id: farmerPartner.id,
      type: "district",
    });

    // Use Promise.all to wait for both promises to resolve
    Promise.all([getDistrictListPromise, fillPartnerLocationPromise])
      .then((results) => {
        const [data, partnerDistricts] = results; // Destructure the results
        const commonDistricts = data.data.filter((district) => {
          return partnerDistricts.some(
            (partnerDistrict) => partnerDistrict.id === district.id
          );
        });

        setDistrictList(commonDistricts);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getFilteredTalukas = () => {
    const getTalukaListPromise = getTalukaList(selectedDistrictOptions.id);
    const fillPartnerLocationPromise = fillPartnerLocation({
      id: farmerPartner.id,
      type: "taluka",
    });

    // Use Promise.all to wait for both promises to resolve
    Promise.all([getTalukaListPromise, fillPartnerLocationPromise])
      .then((results) => {
        const [data, partnerTalukas] = results; // Destructure the results
        const commonTalukas = data.data.filter((taluka) => {
          return partnerTalukas.some(
            (partnerTaluka) => partnerTaluka.id === taluka.id
          );
        });
        setTalukaList(commonTalukas);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getFilteredVillages = () => {
    const getVillageListPromise = getVillageList(selectedTalukaOptions.id);
    const fillPartnerLocationPromise = fillPartnerLocation({
      id: farmerPartner.id,
      type: "village",
    });

    // Use Promise.all to wait for both promises to resolve
    Promise.all([getVillageListPromise, fillPartnerLocationPromise])
      .then((results) => {
        const [data, partnerVillages] = results; // Destructure the results
        const commonVillages = data.data.filter((village) => {
          return partnerVillages.some(
            (partnerVillage) => partnerVillage.id === village.id
          );
        });
        setVillageList(commonVillages);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onHighlightStateChange = () => {
    setSelectedDistrictOptions(null);
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setDistrictList([]);
    setTalukaList([]);
    setVillageList([]);
    handleChangeLocation();
  };
  const onHighlightDistrictChange = () => {
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setTalukaList([]);
    setVillageList([]);
  };
  const onHighlightTalukaChange = () => {
    setSelectedVillageOptions(null);
    setVillageList([]);
  };
  useEffect(() => {
    if (selectedStateOptions !== null) {
      getFilteredDistricts();
    }
  }, [selectedStateOptions]);
  useEffect(() => {
    if (selectedDistrictOptions !== null) {
      getFilteredTalukas();
    }
  }, [selectedDistrictOptions]);
  useEffect(() => {
    if (selectedTalukaOptions !== null) {
      getFilteredVillages();
    }
  }, [selectedTalukaOptions]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: 1,
        borderColor: "#D3D3D3",
        borderRadius: "10px",
        margin: 2,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: stateList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            State
          </Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={stateList}
            selectedOptions={selectedStateOptions}
            setSelectedOptions={setSelectedStateOptions}
            // disable={stateList.length === 0}
            label="Select State"
            onHighlightChange={onHighlightStateChange}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              marginBottom: 1,
              marginLeft: 2,
              color: districtList.length === 0 ? "#ACADAD" : "#444444",
            }}
          >
            District
          </Typography>
          <AutoCompleteDropdown
            width="auto"
            menuList={districtList}
            selectedOptions={selectedDistrictOptions}
            setSelectedOptions={setSelectedDistrictOptions}
            disable={districtList.length === 0}
            label="Select District"
            onHighlightChange={onHighlightDistrictChange}
            required={true}
          />
        </Grid>
        {!isEw && (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  marginBottom: 1,
                  marginLeft: 2,
                  color: talukaList.length === 0 ? "#ACADAD" : "#444444",
                }}
              >
                Taluka
              </Typography>
              <AutoCompleteDropdown
                width="auto"
                menuList={talukaList}
                selectedOptions={selectedTalukaOptions}
                setSelectedOptions={setSelectedTalukaOptions}
                disable={talukaList.length === 0}
                label="Select Taluka"
                onHighlightChange={onHighlightTalukaChange}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  marginBottom: 1,
                  marginLeft: 2,
                  color: villageList.length === 0 ? "#ACADAD" : "#444444",
                }}
              >
                Village
              </Typography>
              <AutoCompleteDropdown
                width="auto"
                menuList={villageList}
                selectedOptions={selectedVillageOptions}
                setSelectedOptions={setSelectedVillageOptions}
                disable={villageList.length === 0}
                label="Select Village"
                required={true}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
