import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

const Centerstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const closeIconStyle = {
  position: "absolute",
  right: 1,
  cursor: "pointer",
  color: (theme) => theme.palette.grey[600],
};

const DeleteButtonStyle = styled(Button)({
  backgroundColor: "#EB5451",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "150px",
  maxWidth: "180px",
  marginLeft: 20,
  padding: "10px 12px",
  "&:hover": {
    backgroundColor: "#EB5451",
    color: "white",
  },
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "14px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "150px",
  maxWidth: "180px",
  marginRight: 20,
  border: "1px solid var(--Primary-Green, #2D6A4F)",
  padding: "10px 12px",
  "&:hover": {
    backgroundColor: "white",
    color: "#2D6A4F",
  },
});

export default function RolesConfirmationBox({
  handleClose = () => {},
  open,
  setSaveAlert,
  setDiscardAlert,
  setSnackBarMessage = () => {},
}) {
  const dispatch = useDispatch();
  const handleClick = () => {
    setSaveAlert(true);
    handleClose();
    // setSnackBarMessage("Changes saved successfully");
  };
  const handleDiscard = () => {
    setDiscardAlert(true);
    handleClose();
    // setSnackBarMessage("Changes discarded successfully");
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <HighlightOffRoundedIcon sx={closeIconStyle} onClick={handleDiscard} />
      <DialogTitle
        sx={{
          marginTop: 2,
          color: "var(--Royal-Black, #4F5555)",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        There are unsaved change. If you leave this. you’ll lose your changes.
      </DialogTitle>
      <DialogActions sx={{ ...Centerstyle, marginBottom: 2 }}>
        <CancelButtonStyle
          variant="contained"
          onClick={handleDiscard}
          sx={{ marginRight: 5 }}
        >
          Discard Changes
        </CancelButtonStyle>
        <DeleteButtonStyle
          variant="contained"
          sx={{ marginLeft: 5 }}
          onClick={handleClick}
          autoFocus
        >
          Save Changes
        </DeleteButtonStyle>
        {/* <Button
          color="primary"
          variant="contained"
          onClick={handleDiscard}
          sx={{ marginRight: 5 }}
        >
          Discard Changes
        </Button>
        <Button
          color="secondary"
          variant="contained"
          sx={{ background: "#EB5451", marginLeft: 5 }}
          onClick={handleClick}
        >
          Save Changes
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
