import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import logo from "../../styles/images/icons/logo.png";
import ImageIcon from "@mui/icons-material/Image";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ArticleIcon from "@mui/icons-material/Article";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import InsertChartOutlinedSharpIcon from "@mui/icons-material/InsertChartOutlinedSharp";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { increaseDrawerWidth, decreaseDrawerWidth } from "./AppBarSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Authentication/AuthenticationSlice";
import BasicDialog from "../../components/helpers/BasicDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import Icon from "@mui/material/Icon";
import { ReactComponent as WiaiLogo } from "../../styles/wiai-logo.svg";
import PersonIcon from "@mui/icons-material/Person";
import { getUsersPermission } from "../../features/appbar/AppBarSlice";
import { useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpDialog from "../../components/appMenuBar/HelpDialog";
import { resetUserPermission } from "./AppBarSlice";
import { capitaliseFirstLetter } from "../../utils";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  logo: {
    zoom: "12%",
    [theme.breakpoints.down("sm")]: {
      zoom: "5%",
    },
  },
  btnStyle: {
    padding: 0,
    // color: "#929dad",
    fontSize: "12px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "xx-small",
    },
    textAlign: "right",
    justifyContent: "flex-end",
  },
  mainBox: {
    flex: "1",
    backgroundColor: "#F5F5F5",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const GridItemEnd = styled(Grid)({
  flex: "1",
  justifyContent: "flex-end",
  justify: "flex-end",
  alignItems: "right",
  display: "flex",
  marginRight: 20,
});

const GridItemStart = styled(Grid)({
  flex: "1",
  justifyContent: "centre",
  justify: "centre",
  alignItems: "centre",
  display: "flex",
  // marginRight: 20,
  marginLeft: "20px",
});

const ListItemEnd = styled(Grid)({
  flex: "1",
  justifyContent: "flex-end",
  justify: "flex-end",
  alignItems: "right",
  display: "flex",
});

const ButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#49BB86",
  textTransform: "none",
  borderRadius: "20px",
  borderColor: "#49BB86",
  fontWeight: "600",
  width: "100px",
  // height: "48px",
  marginRight: "10px",
});

export default function AppMenuBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const icons = [
    // <StackedLineChartIcon />,
    // <InsertChartOutlinedSharpIcon />,
    // <SettingsOverscanOutlinedIcon />,
    <AdminPanelSettingsIcon />,
    <ImageIcon />,
    // <SupervisorAccountIcon />,
    // <ArticleIcon />,
  ];

  var adminSubMenuDict = {
    "Location Module": "Location",
    "Submitted Forms Module": "Submitted Forms",
    "Partner Onboarding Module": "Partners",
    "Supervisor Onboarding Module": "Supervisors",
    "User Onboarding Module": "App Users",
    "Form Module": "Form Management",
    "Project Module" :"Projects",
    "Roles And Permission Module": "Roles and Permissions",
  };

  var moduleOrder = [
    "Partners",
    "Projects",
    "Form Management",
    "Supervisors",
    "App Users",
    "Location",
    "Submitted Forms",
    "Roles and Permissions",

  ];

  const pageurl = {
    Dashboard: "/dashboard",
    "Infestation Status": "/infestation_status",
    "Farm Monitoring": "/farm_monitoring",
    "Review Images": "/review_images",
    "Admin Panel": "/app_users",
    Reports: "/reports",
    "Roles and Permissions": "/roles_permission",
    "Location": "/location",
    "Submitted Forms" :"/submitted_forms",
    "Partners": "/partner",
    "Projects" : "/projects",
    "Form Management": "/form_management",
    "Advisory Management": "/advisory_management",
    Supervisors: "/supervisor_access",
    "App Users": "/app_users",
    "State Head": "/supervisor_access/state_head",
    "District Head": "/supervisor_access/district_head",
    "Taluka Head": "/supervisor_access/taluka_head",
    Government: "/supervisor_access/government",
    "Partner Farmer": "/app_users/paf",
    "Public Farmer": "/app_users/puf",
    "Extension Worker": "/app_users/ew",
    Pesticide: "/advisory_management/pesticide",
    "Partner Pesticide": "/advisory_management/partner_pesticide",
  };

  const ROLES = {
    "Partner Head":"partner_head" ,
    "State Head":"state_head",
    "District Head":"district_head" ,
    "Taluka Head":"taluka_head" ,
    "Admin": "admin"
  }

  const [currentPage, setCurrentPage] = React.useState("");
  const classes = useStyles();
  const theme = useTheme();
  const drawerOpenKey = "drawerOpen";
  const defaultOpen = localStorage.getItem(drawerOpenKey) === "true";
  const [open, setOpen] = React.useState(defaultOpen);
  const subDrawerOpenKey = "subDrawerOpen";
  const subDefaultOpen = localStorage.getItem(subDrawerOpenKey) === "true";
  const [openAdminPanel, setOpenAdminPanel] = React.useState(subDefaultOpen);
  const [openSupervisorPanel, setOpenSupervisorPanel] = React.useState(false);
  const [openAdvisoryPanel, setOpenAdvisoryPanel] = React.useState(false);
  const [openAppUserPanel, setOpenAppUserPanel] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState("");
  const [subTabSelected, setSubTabSelected] = React.useState(currentPage);
  const [nestedSubTabSelected, setNestedSubTabSelected] = React.useState("");
  const [displayTab, setDisplayTab] = React.useState(currentPage);
  const [openSignoutDialog, setOpenSignoutDialog] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [adminSubMenu, setAdminSubmenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const userPermission = useSelector(
    (state) => state.appBarSliceReducer.userPermissionData.data
  );

  const [headers, setHeaders] = useState([
      "Admin Panel",
  ])

  const advisorySubMenu = ["Pesticide", "Partner Pesticide"];

  const [supervisorSubMenu, setSupervisorSubMenu] = useState([]);

  const [appUserSubMenu, setAppUserSubmenu] =  useState([
    "Partner Farmer",
    "Extension Worker",
  ])

  const subAdminMenuUrl = [
    "/location",
    "/partner",
    "/projects",
    "/advisory_management",
    "/supervisor_access",
    "/app_users",
    "/roles_permission",
    "/form_management",
    "submitted_forms",
  ];

  const subSupervisorMenuUrl = [
    "/supervisor_access/state_head",
    "/supervisor_access/district_head",
    "/supervisor_access/taluka_head",
    "/supervisor_access/government",
  ];

  const subAppUserMenuUrl = [
    "/app_users/paf",
    "/app_users/puf",
    "/app_users/ew",
  ];

  useEffect(() => {
    var currentLocationPage = Object.keys(pageurl).find(
      (key) => pageurl[key] === pathname
    );
    setCurrentPage(currentLocationPage);
    setTabSelected(currentLocationPage);
    setDisplayTab(currentLocationPage);
    setSubTabSelected(currentLocationPage);
    setNestedSubTabSelected(currentLocationPage);
    if (subAdminMenuUrl.includes(pathname)) {
      setTabSelected("Admin Panel");
    }
    if (currentLocationPage === "Admin Panel") {
      setDisplayTab("App Users");
      setSubTabSelected("App Users");
    }
    if (subSupervisorMenuUrl.includes(pathname)) {
      setTabSelected("Admin Panel");
      setSubTabSelected("Supervisors");
      setDisplayTab("Supervisors");
    }
    if (subAppUserMenuUrl.includes(pathname)) {
      setTabSelected("Admin Panel");
      setSubTabSelected("App Users");
      setDisplayTab("App Users");
    }
  }, [navigate]);

  const handleClickOpenLogoutDialog = () => {
    setOpenSignoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenSignoutDialog(false);
  };

  useEffect(() => {
    
    if (Object.keys(userPermission).length !== 0) {
      var submenu = [];
    
      // Iterate over the moduleOrder array to maintain the order
      for (let module of moduleOrder) {
        for (let per in userPermission) {
          if (adminSubMenuDict[per] === module) {
            submenu.push(adminSubMenuDict[per]);
            break; // Break the loop once a match is found
          }
        }
      }
    
      var userPermissionCopy = [...submenu];
      setAdminSubmenu(submenu);
      window.localStorage.setItem("userPermission", JSON.stringify(userPermissionCopy));
    }
  }, [userPermission]);

  useEffect(() => {
    setOpen(true);
    setOpenAdminPanel(true);
    if (Object.keys(userPermission).length === 0) {
      setLoading(true);
      dispatch(
        getUsersPermission({
          filters: JSON.stringify([
            { type: "role", value: [ROLES[loggedInUser.role]] },
            { type: "permission", value: ["view"] },
          ]),
        })
      ).then(() => setLoading(false));
    }

    if(loggedInUser.role === "State Head" || loggedInUser.role === "Admin" || loggedInUser.role === "Partner Head"){
      supervisorSubMenu.push("State Head", "District Head", "Taluka Head")
    }
    else if(loggedInUser.role === "District Head")
    {
      supervisorSubMenu.push("District Head", "Taluka Head")
    }
    else if(loggedInUser.role === "Taluka Head")
    {
      supervisorSubMenu.push("Taluka Head")
    }

    // if(loggedInUser.role==="Admin")
    //     {
    //       const updatedHeaders = [...headers]
    //       updatedHeaders.push("Review Images");
    //       setHeaders(updatedHeaders)
    //       const updatedSupervisorSubMenu = [...supervisorSubMenu]
    //       updatedSupervisorSubMenu.push("Government");
    //       setSupervisorSubMenu(updatedSupervisorSubMenu)
    //       const updatedappUserSubmenu = [...appUserSubMenu]
    //       updatedappUserSubmenu.push("Public Farmer")
    //       setAppUserSubmenu(updatedappUserSubmenu)

    //     }
  }, []);

  useEffect(() => {
    localStorage.setItem(drawerOpenKey, open);
    if (open) dispatch(increaseDrawerWidth());
    else dispatch(decreaseDrawerWidth());
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenAdminPanel(false);
  };

  useEffect(() => {
    localStorage.setItem(subDrawerOpenKey, openAdminPanel);
  }, [openAdminPanel]);

  const handleClickAdminPanel = () => {
    setOpenAdminPanel(!openAdminPanel);
  };

  const handleClickSupervisorPanel = () => {
    setOpenSupervisorPanel(!openSupervisorPanel);
  };

  const handleClickAppUserPanel = () => {
    setOpenAppUserPanel(!openAppUserPanel);
  };

  const handleClickAdvisoryPanel = () => {
    setOpenAdvisoryPanel(!openAdvisoryPanel);
  };

  const handleRouting = (text) => {
    if (text !== "Admin Panel") {
      navigate(pageurl[text]);
    }
  };

  const handleLogout = () => {
    window.localStorage.removeItem("loggedInUser");
    window.localStorage.removeItem("userPermission");
    dispatch(logout());
    dispatch(resetUserPermission())
    navigate("/", true);
  };

  const renderCollapseAdminSubMenu = (openAdminPanel, open, menu) => {
    return (
      <Collapse in={openAdminPanel && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menu.map((text, index) => {
            const displayText = text === "Partners" && loggedInUser.details.partner 
              ? capitaliseFirstLetter(loggedInUser.details.partner.name)
              : text;
            
            return (
              <div style={{ display: "block", marginTop: 1 }} key={text}>
                <ListItemButton
                  sx={{ pl: 4 }}
                  style={{
                    color: subTabSelected === text ? "#2D6A4F" : "#4A4A4A",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    marginLeft: 40,
                    textDecoration: subTabSelected === text && "underline",
                  }}
                  key={index}
                  onClick={() => {
                    if (text === "Supervisors") handleClickSupervisorPanel();
                    else if (text === "App Users") handleClickAppUserPanel();
                    else if (text === "Advisory Management") handleClickAdvisoryPanel();
                    else {
                      setOpenSupervisorPanel(false);
                      setOpenAppUserPanel(false);
                      setOpenAdvisoryPanel(false);
                      handleRouting(text);
                    }
                  }}
                >
                  <ListItemText primary={displayText} sx={{ opacity: open ? 1 : 0 }} />
                  {text === "Supervisors" && open && openAdminPanel ? (
                    openSupervisorPanel ? <ExpandMore /> : <ExpandLess />
                  ) : (
                    ""
                  )}
                  {text === "Advisory Management" && open && openAdminPanel ? (
                    openAdvisoryPanel ? <ExpandMore /> : <ExpandLess />
                  ) : (
                    ""
                  )}
                  {text === "App Users" && open && openAdminPanel ? (
                    openAppUserPanel ? <ExpandMore /> : <ExpandLess />
                  ) : (
                    ""
                  )}
                </ListItemButton>
                {text === "Supervisors" &&
                  renderCollapseSubMenu(openSupervisorPanel, open, supervisorSubMenu)}
                {text === "Advisory Management" &&
                  renderCollapseSubMenu(openAdvisoryPanel, open, advisorySubMenu)}
                {text === "App Users" &&
                  renderCollapseSubMenu(openAppUserPanel, open, appUserSubMenu)}
              </div>
            );
          })}
        </List>
      </Collapse>
    );
  };

  const renderCollapseSubMenu = (openSubPanel, open, menu) => {
    return (
      <Collapse in={openSubPanel && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menu.map((text, index) => (
            <ListItemButton
              sx={{ pl: 4 }}
              style={{
                color: nestedSubTabSelected === text ? "#74C69D" : "#5E6060",
                background: nestedSubTabSelected === text && "#F0F8F1",
                borderRadius: nestedSubTabSelected === text && 30,
                fontSize: "12px",
                marginLeft: 15,
                fontFamily: "Inter Medium",
                border: nestedSubTabSelected === text && "1px solid #74C69D",
              }}
              key={index}
              onClick={() => handleRouting(text, true)}
            >
              <ListItemText
                primary={text}
                sx={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "flex-start",
                  fontSize: "16px",
                  marginLeft: 5,
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    );
  };

  return (
    <Box className={classes.mainBox} id="app-bar-box">
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: -2,
              ...(open && { display: "none" }),
            }}
          >
            <Icon
              sx={{
                height: "48px",
                width: "48px",
              }}
            >
              <WiaiLogo />
            </Icon>
          </IconButton>
          <div>
            <Typography
              sx={{
                fontFamily: "Inter",
                // fontSize: !isSmallScreen ? "32px" : "10px",
                fontSize:{xl:"32px",lg:"26px",md:"20px",xs:"12px"},
                color: "#081C15",
              }}
            >
              {displayTab}
            </Typography>
          </div>
          <GridItemEnd>
            {!isSmallScreen && (
              <div>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: "400",
                    marginTop: 2,
                    color: "#4A4A4A",
                    marginRight: 1,
                  }}
                >
                  {loggedInUser.email}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#4A4A4A",
                    marginRight: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  ({loggedInUser.role})
                </Typography>
              </div>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClickOpenLogoutDialog}
              color="inherit"
            >
              <PersonIcon fontSize="large" />
            </IconButton>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {openSignoutDialog && (
              <BasicDialog
                open={openSignoutDialog}
                handleClose={handleCloseLogoutDialog}
                heading="Sign out"
                text="Are you sure you would like to sign out?"
                saveButtonText="Yes, Sign Out"
                handleLogout={handleLogout}
              />
            )}
          </GridItemEnd>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <GridItemStart item xs={12} md={3}>
            <img src={logo} alt="logo" className={classes.logo} />
          </GridItemStart>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />

        <List>
          {headers.map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block", marginTop: 3 }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                style={{
                  color: tabSelected === text ? "#2D6A4F" : "#5E6060",
                  background: tabSelected === text && "#F0F8F1",
                  borderRadius: tabSelected === text && 16,
                  margin: tabSelected === text && 5,
                  fontSize: "16px",
                  fontFamily: "Inter Medium",
                  border: tabSelected === text && "1px solid #74C69D",
                }}
                onClick={() => {
                  if (text === "Admin Panel") handleClickAdminPanel();
                  else setOpenAdminPanel(false);
                  handleRouting(text);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "flex-start",
                    fontSize: "16px",
                  }}
                >
                  {icons[index]}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                {text === "Admin Panel" && open ? (
                  openAdminPanel ? (
                    <ExpandMore />
                  ) : (
                    <ExpandLess />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
              {text === "Admin Panel" &&
                renderCollapseAdminSubMenu(openAdminPanel, open, adminSubMenu)}
            </ListItem>
          ))}
        </List>
        <List style={{ marginTop: `auto` }}>
          <ListItem>
            {open === false && (
              <HelpOutlineIcon
                sx={{
                  color: "#49BB86",
                  flex: "1",
                  justifyContent: "flex-end",
                  justify: "flex-end",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  setOpenHelp(true);
                }}
              />
            )}
          </ListItem>
          <ListItem>
            {open === false ? (
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>
            ) : (
              <ListItemEnd>
                <ButtonStyle
                  variant="outlined"
                  endIcon={<HelpOutlineIcon size="large" />}
                  onClick={() => {
                    setOpenHelp(true);
                  }}
                  sx={{ "&.MuiButton-root:hover": { borderColor: "#49BB86" } }}
                >
                  Help
                </ButtonStyle>
                {/* <IconButton onClick={handleDrawerClose}  sx={{ "&.MuiIconButton-root:hover": {bgColor:"transparent" } }} >
                  <Typography>Collapse</Typography>
                  <ChevronLeftIcon />
                </IconButton> */}
              </ListItemEnd>
            )}
          </ListItem>
        </List>
        {openHelp && (
          <HelpDialog
            handleClose={() => setOpenHelp(false)}
            open={openHelp}
            heading={"Need help?"}
            text={"Write to us at agri-support@wadhwaniai.org for queries."}
          />
        )}
      </Drawer>
    </Box>
  );
}
