import {rolesAndPermissionsAPI} from "../../api's/index";
import axios from "axios";
import { getHeaders, commonApiCall } from "../../utils";
var qs = require("qs");


export const getRolesAndPermissionData = async (params) => {
    const response = await commonApiCall({
        method: "get",
        url: rolesAndPermissionsAPI,
        params: params,
      });
      return response;
}

export const editRolesAndPermissionData = async (data) => {
    const response = await commonApiCall({
        method: "put",
        url: rolesAndPermissionsAPI,
        data: data,
      });
      return response;
}
